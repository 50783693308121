import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useAuth } from "../auth/GuruAuth";
import { Config } from "../../App";

export default function GuruAuthTest() {
  const [greeting, setGreeting] = useState();
  const { isAuthenticated, getToken } = useAuth();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        return;
      }
      const token = await getToken();
      try {
        const response = await fetch(`${Config.apiEndpoint}/auth-test`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const text = await response.text();
        setGreeting(text);
      } catch {
        setGreeting("API authorization failed.");
      }
    })();
  }, [isAuthenticated]);

  const CenteredBox = ({ children }) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      {children}
    </Box>
  );

  if (isAuthenticated && !greeting) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }

  return (
    <CenteredBox>
      <Paper>
        <Typography variant="h2" color="textPrimary">
          {greeting || "Click log in to test authn + authz"}
        </Typography>
      </Paper>
    </CenteredBox>
  );
}
