import React, { useState, useEffect, useMemo } from "react";
import { Config } from "../../App";
import makeStyles from "@mui/styles/makeStyles";
import {
  Alert,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";

import ActivityFilter from "./ActivityFilter";
import ReviewCarousel from "./ReviewCarousel";
import Loading from "../Loading";

import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { useAuth } from "../auth/GuruAuth";
import ScaleAiLogo from "./logos/scaleai.png";
import FormguruLogo from "./logos/formguru.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

import AnnotationTaskModal from "./AnnotationTaskModal";
import AnnotationTaskViewer from "./AnnotationTaskViewer";

const useStyles = makeStyles((theme) => ({
  unselected: {
    padding: theme.spacing(0.2),
  },
  selected: {
    padding: theme.spacing(0.2),
    "box-shadow": "inset 0px 0px 0px 2px aqua",
  },
  content: {
    marginTop: "96px", // leave enough room for sticky header
    padding: theme.spacing(1),
  },
  commandBar: {
    position: "fixed",
    top: 60,
    width: "100%",
    maxHeight: "96px",
    padding: theme.spacing(1),
    // align right
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const Thumbnail = ({
  src,
  id,
  onClick,
  provider,
  hasBeenAnnotated,
  isSelected,
}) => {
  const classes = useStyles();

  const providerToIconUri = (provider) => {
    if (provider === "scale.com") {
      return ScaleAiLogo;
    } else if (provider && provider.startsWith("guru")) {
      return FormguruLogo;
    }
  };

  return (
    <Paper
      className={isSelected ? classes.selected : classes.unselected}
      onClick={() => onClick(id)}
      sx={{ position: "relative" }}
    >
      <img src={src} alt={id} />
      <div style={{ position: "absolute", top: 8, right: 8 }}>
        {hasBeenAnnotated ? (
          <CheckCircleIcon style={{ color: "#32CD32" }} />
        ) : (
          <PendingActionsIcon style={{ color: "#FFFF99" }} />
        )}
      </div>
      {providerToIconUri(provider) ? (
        <div
          style={{
            borderRadius: 24,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            height: 24,
            width: 24,
            position: "absolute",
            bottom: 8,
            left: 2,
          }}
        >
          <img
            src={providerToIconUri(provider)}
            alt={provider}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              verticalAlign: "middle",
            }}
          />
        </div>
      ) : (
        <Typography
          sx={{ fontSize: 12, position: "absolute", bottom: 8, left: 2 }}
        >
          {provider}
        </Typography>
      )}
    </Paper>
  );
};

const CreateBatchDialog = ({
  taskIds,
  isOpen,
  onClose,
  onBatchCreated,
  onError,
  getToken,
}) => {
  const [existingBatches, setExistingBatches] = useState([]);
  const [batchName, setBatchName] = useState();
  const [validationError, setValidationError] = useState();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(
    () =>
      (async () => {
        const apiClient = axios.create({
          baseURL: Config.annotationServerEndpoint,
        });
        const token = await getToken();
        const requestArgs = {
          params: { limit: 128 },
          headers: { Authorization: `Bearer ${token}` },
        };
        const response = await apiClient.get("/batch", requestArgs);
        // TODO: have backend validate that existing batch is in state pending
        setExistingBatches(
          response.data["batches"]
            .filter(({ status }) => status.toLowerCase().trim() === "pending")
            .map(({ id, external_id }) => ({
              id,
              external_id,
            }))
        );
      })(),
    []
  );

  const getExistingBatch = (batchName) => {
    return existingBatches.find(({ external_id }) => external_id === batchName);
  };

  const isExistingBatch = (batchName) => {
    return Boolean(getExistingBatch(batchName));
  };

  const validateAndSetBatchName = (batchName) => {
    if (batchName.trim().length !== batchName.length) {
      setValidationError(
        "Batch name should not have leading or trailing whitespace."
      );
    } else if (batchName.length < 4) {
      setValidationError("Batch name must be > 4 characters.");
    } else if (batchName.length > 128) {
      setValidationError("Batch name must be < 128 characters.");
    } else {
      setValidationError(null);
      setBatchName(batchName);
    }
  };

  const addTasksToBatch = async (batchName) => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      headers: { Authorization: `Bearer ${token}` },
    };

    setIsSaving(true);
    var response;
    try {
      if (isExistingBatch(batchName)) {
        const { id } = getExistingBatch(batchName);
        response = await apiClient.patch(
          `/batch/${id}`,
          { tasks: taskIds },
          requestArgs
        );
      } else {
        response = await apiClient.post(
          "/batch",
          { tasks: taskIds, batch_name: batchName },
          requestArgs
        );
      }
    } catch (error) {
      onError();
      return;
    } finally {
      setIsSaving(false);
    }
    if ([200, 201].includes(response.status)) {
      onBatchCreated(response.data);
    } else {
      onError();
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Create Batch</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will create a pending batch of {taskIds.length} via the Scale
            API. You will need to finalize the batch from the Pose Batches page.
          </DialogContentText>
          <Autocomplete
            freeSolo
            id="batch-name"
            onInputChange={(event, value) => validateAndSetBatchName(value)}
            options={existingBatches.map(({ external_id }) => external_id)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(validationError)}
                helperText={validationError || "Batch name"}
                label="Batch Name"
              />
            )}
          />
        </DialogContent>
        {isSaving ? (
          <CircularProgress sx={{ margin: "16px" }} />
        ) : (
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              disabled={!Boolean(batchName)}
              onClick={() => addTasksToBatch(batchName)}
            >
              {isExistingBatch(batchName) ? "Update batch" : "Create batch"}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

const usePaginatedTaskFetch = (uri) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextId, setNextId] = useState(null);
  const { getToken } = useAuth();

  const fetchNextPage = async (fromBeginning = false) => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      params: { limit: 128 },
      headers: { Authorization: `Bearer ${token}` },
    };
    if (nextId && !fromBeginning) {
      requestArgs.params.next = nextId;
    }
    var response;
    try {
      response = await apiClient.get(uri, requestArgs);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      throw error;
    }
    setIsLoading(false);
    setNextId(response.data.next || null);
    return response.data;
  };

  return {
    fetchNextPage,
    isLoading,
    error,
    hasMore: Boolean(nextId),
  };
};

export const TaskGrid = ({
  taskFilter,
  TaskComponent,
  lastLoadedAt,
  onLoadedTasks,
  tasksUri,
}) => {
  const [tasks, setTasks] = useState({});

  const { fetchNextPage, isLoading, error, hasMore } =
    usePaginatedTaskFetch(tasksUri);

  const sortedTasks = Object.values(tasks)
    .sort((a, b) => a.id >= b.id)
    .reverse();
  const sortedFilteredTasks = sortedTasks.filter(taskFilter);

  const forceReloadProp = lastLoadedAt ? [lastLoadedAt] : [];
  useEffect(() => {
    const fromBeginning = true;
    fetchMoreTasks(fromBeginning);
  }, forceReloadProp);

  const fetchMoreTasks = async (fromBeginning = false) => {
    const data = await fetchNextPage(fromBeginning);
    var _tasks;
    setTasks((tasks) => {
      const existing = fromBeginning ? {} : { ...tasks };
      const result = data.tasks.reduce((acc, task) => {
        acc[task.id] = {
          src: task.thumbnail_uri,
          thumbnail: task.thumbnail_uri,
          id: task.id,
          activity: task.activity,
          provider: task.provider,
          hasBeenAnnotated: task.num_boxes > 0 && task.num_keypoints > 0,
        };
        return acc;
      }, existing);
      _tasks = Object.values(result);
      return result;
    });
    if (onLoadedTasks) {
      onLoadedTasks(_tasks);
    }
  };

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  } else if (isLoading) {
    return <Loading message="Loading tasks..." />;
  }
  return (
    <InfiniteScroll
      dataLength={sortedTasks.length}
      next={fetchMoreTasks}
      hasMore={hasMore}
      loader={
        <div>
          <Typography variant="h6">Loading...</Typography>
          <LinearProgress />
        </div>
      }
      endMessage={
        <Typography variant="helper">No more tasks to load</Typography>
      }
    >
      <Grid container spacing={1}>
        {sortedFilteredTasks.map((task) => {
          return (
            <Grid item key={`task-${task.id}`}>
              <TaskComponent {...task} />
            </Grid>
          );
        })}
      </Grid>
    </InfiniteScroll>
  );
};

export default function AnnotationTasks() {
  const classes = useStyles();

  const [uniqueActivities, setUniqueActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false);
  const [batchCreationResult, setBatchCreationResult] = useState(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState(null);
  const [deletedTaskIds, setDeletedTaskIds] = useState([]);
  const [lastLoadedAt, setLastLoadedAt] = useState(null);
  const [curTaskId, setCurTaskId] = useState(null);
  const [isReviewing, setIsReviewing] = useState(false);
  const [tasks, setTasks] = useState(null);
  const { getToken } = useAuth();

  useEffect(() => {
    if (batchCreationResult && batchCreationResult.didSucceed) {
      setLastLoadedAt(new Date());
    }
  }, [batchCreationResult]);

  const activityFilter = useMemo(() => {
    return selectedActivities.length === 0
      ? () => true
      : ({ activity }) => selectedActivities.includes(activity);
  }, [selectedActivities]);

  const deletedFilter = useMemo(() => {
    return deletedTaskIds.length === 0
      ? () => true
      : ({ id }) => !deletedTaskIds.includes(id);
  }, [deletedTaskIds]);

  const combinedFilter = (x) => activityFilter(x) && deletedFilter(x);

  const onToggleTaskSelection = (taskId) => {
    setSelectedTaskIds((selectedTaskIds) => {
      const existing = selectedTaskIds || [];
      if (existing.includes(taskId)) {
        return existing.filter((id) => id !== taskId);
      }
      return [...existing, taskId];
    });
  };

  const ToggleableThumbnail = ({
    id,
    provider,
    hasBeenAnnotated,
    ...props
  }) => {
    const onClick = () => {
      if (isMultiSelectActive) {
        onToggleTaskSelection(id);
      } else {
        setCurTaskId(id);
      }
    };
    return (
      <Thumbnail
        id={id}
        isSelected={selectedTaskIds && selectedTaskIds.includes(id)}
        provider={provider}
        hasBeenAnnotated={hasBeenAnnotated}
        onClick={onClick}
        onDoubleClick={() => {
          setCurTaskId(id);
        }}
        {...props}
      />
    );
  };

  const deleteTask = async (id) => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await apiClient.delete(`task/${id}`, requestArgs);
    if (response.status !== 204) {
      throw new Error(`Failed to delete task ${id}`);
    }
    return id;
  };

  const deleteSelected = async () => {
    const deletedIds = await Promise.all(selectedTaskIds.map(deleteTask));
    setDeletedTaskIds((deletedTaskIds) => [...deletedTaskIds, ...deletedIds]);
    setSelectedTaskIds([]);
  };

  const isMultiSelectActive = selectedTaskIds !== null;

  return (
    <>
      <CreateBatchDialog
        taskIds={selectedTaskIds || []}
        isOpen={isCreateBatchModalOpen}
        onClose={() => setIsCreateBatchModalOpen(false)}
        onError={() => {
          setBatchCreationResult({ didSucceed: false });
          setIsCreateBatchModalOpen(false);
        }}
        onBatchCreated={(batch) => {
          setBatchCreationResult({ didSucceed: true, batch });
          setIsCreateBatchModalOpen(false);
          setSelectedTaskIds([]);
        }}
        getToken={getToken}
      />
      <Paper className={classes.commandBar}>
        <ActivityFilter
          activities={uniqueActivities}
          onFilterChange={setSelectedActivities}
        />
        <Button onClick={() => setIsReviewing(true)}>Begin Review</Button>
        {isReviewing && (
          <ReviewCarousel
            isActive={isReviewing}
            onClose={() => setIsReviewing(false)}
            items={tasks
              .sort(({ id: id1 }, { id: id2 }) => id2 - id1)
              .filter(({ hasBeenAnnotated }) => !hasBeenAnnotated)
              .map((t) => ({ ...t, taskId: t.id }))}
            render={AnnotationTaskViewer}
          />
        )}
        {isMultiSelectActive ? (
          <>
            <Button
              disabled={
                selectedTaskIds === null || selectedTaskIds.length === 0
              }
              onClick={deleteSelected}
              variant="contained"
              color="error"
            >
              Delete {selectedTaskIds.length} tasks
            </Button>
            <Button
              disabled={
                selectedTaskIds === null || selectedTaskIds.length === 0
              }
              onClick={() => setIsCreateBatchModalOpen(true)}
              variant="contained"
            >
              Add {selectedTaskIds.length} tasks to batch
            </Button>
            <Button onClick={() => setSelectedTaskIds(null)}>Cancel</Button>
          </>
        ) : (
          <Button onClick={() => setSelectedTaskIds([])}>
            Add to batch...
          </Button>
        )}
      </Paper>

      <div className={classes.content}>
        {batchCreationResult && (
          <Alert
            severity={batchCreationResult.didSucceed ? "success" : "error"}
          >
            {batchCreationResult.didSucceed
              ? `Saved batch ${batchCreationResult.batch.id} with external_id="${batchCreationResult.batch.external_id}"`
              : "Failed to create batch! Please check console and backend logs"}
          </Alert>
        )}
        <TaskGrid
          TaskComponent={ToggleableThumbnail}
          taskFilter={combinedFilter}
          lastLoadedAt={lastLoadedAt}
          tasksUri="tasks"
          onLoadedTasks={(tasks) => {
            setTasks(tasks);
            setUniqueActivities((existing) => {
              const uniq = new Set([
                ...existing,
                ...tasks.map(({ activity }) => activity),
              ]);
              return Array.from(uniq);
            });
          }}
        />
        {curTaskId && (
          <AnnotationTaskModal
            taskId={curTaskId}
            onClose={() => setCurTaskId(null)}
          />
        )}
      </div>
    </>
  );
}
