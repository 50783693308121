import React, { useRef } from "react";
import { useAuthSession } from "./auth/FormguruAuth";
import { useAuth } from "./auth/GuruAuth";
import axios from "axios";
import { Container, LinearProgress, Link } from "@mui/material";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";
import "../App.css";
import { useHistory } from "react-router-dom";
import { useEventLogger, useUserPropertyLogger } from "./Analytics";
import Honeybadger from "@honeybadger-io/js";
import { Config } from "../App";

const useAppropriateAuth = (isUsingAuth0) => {
  const [getCurrentUser, getSession] = useAuthSession();
  const { getToken } = useAuth();

  if (isUsingAuth0) {
    return async () => {
      const token = await getToken();
      return { Authorization: `Bearer ${token}` };
    };
  }

  return async () => {
    const user = await getCurrentUser();
    if (user) {
      const session = await getSession();
      return { Authorization: session.accessToken.jwtToken };
    }
  };
};

export default function WorkoutVideoUploader({
  buttonText,
  onUploadStarted,
  onUploadFinished = null,
  source = null,
  isUsingAuth0 = false,
  ...buttonProps
}) {
  const history = useHistory();

  const redirectToViewer = (videoId, { size, name, type }) => {
    history.push({
      pathname: `/video/${videoId}`,
      search: `?szBytes=${size}`,
      state: { isNewUpload: true },
    });
  };
  onUploadFinished = onUploadFinished || redirectToViewer;

  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgressPercentage, setUploadProgressPercentage] =
    React.useState(0);
  const [lastUploadFailure, setLastUploadFailure] = React.useState(null);

  const getAuthHeader = useAppropriateAuth(isUsingAuth0);
  const logEvent = useEventLogger();
  const { incrementUserProperty } = useUserPropertyLogger();
  const MIN_FILE_SIZE_MB = 0.5;
  const MAX_FILE_SIZE_MB = 64;

  // Failure reasons
  const UNKNOWN_REASON = "UNKNOWN";
  const FILE_SIZE_OUT_OF_RANGE = `Sorry, your video must be between ${MIN_FILE_SIZE_MB} MB AND ${MAX_FILE_SIZE_MB} MB`;
  const UNSUPPORTED_VIDEO_FORMAT =
    "Sorry, your video must be in either .mp4 or .mov format";
  const TOO_MANY_FILES = "Sorry, you can only upload one file at a time";

  const onUploadFailure = (videoFile, reason) => {
    logEvent("video_upload_failed", {
      reason: reason,
      extension: videoFile.type,
      sz_bytes: videoFile.size,
    });
    setLastUploadFailure(reason);
    setIsUploading(false);
  };

  const alertForFailure = () => {
    if (lastUploadFailure === UNKNOWN_REASON) {
      return (
        <Alert severity="error">
          Uh-oh, something broke. <Link href="/contact">Click here</Link> to let
          us know.
        </Alert>
      );
    }
    return <Alert severity="error">{lastUploadFailure}</Alert>;
  };

  const inferenceApiClient = axios.create({
    baseURL: Config.apiEndpoint,
  });

  const bytesToMB = (num_bytes) => {
    return num_bytes / (1024 * 1024);
  };

  const uploadVideo = async (selectedVideos) => {
    if (selectedVideos.length > 1) {
      onUploadFailure(selectedVideos[0], TOO_MANY_FILES);
      return;
    }

    const selectedVideo = selectedVideos[0];
    logEvent("video_upload_initiated", {
      extension: selectedVideo.type,
      sz_bytes: selectedVideo.size,
    });
    incrementUserProperty("lifetime_upload_count", 1);

    if (
      bytesToMB(selectedVideo.size) > MAX_FILE_SIZE_MB ||
      bytesToMB(selectedVideo.size) < MIN_FILE_SIZE_MB
    ) {
      onUploadFailure(selectedVideo, FILE_SIZE_OUT_OF_RANGE);
      return;
    }
    const supportedFormats = new Set(["video/mp4", "video/quicktime"]);
    if (!supportedFormats.has(selectedVideo.type)) {
      onUploadFailure(selectedVideo, UNSUPPORTED_VIDEO_FORMAT);
      return;
    }

    setIsUploading(true);
    if (onUploadStarted) {
      onUploadStarted();
    }

    let postVideoResponse;
    try {
      const authHeader = await getAuthHeader();
      postVideoResponse = await inferenceApiClient.post(
        "/videos",
        {
          filename: selectedVideo.name,
          size: selectedVideo.size,
          source: source,
        },
        { headers: authHeader }
      );
    } catch (error) {
      onUploadFailure(selectedVideo, UNKNOWN_REASON);
      Honeybadger.notify(error);
      return;
    }
    const {
      data: { id: videoId, url: signedS3URL, fields: signedS3Fields },
    } = postVideoResponse;

    const formData = new FormData();
    Object.keys(signedS3Fields).forEach((key) => {
      formData.append(key, signedS3Fields[key]);
    });
    formData.append("file", selectedVideo);

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        setUploadProgressPercentage(percentCompleted);
      },
    };

    try {
      await axios.post(signedS3URL, formData, options);
    } catch (error) {
      onUploadFailure(selectedVideo, UNKNOWN_REASON);
      Honeybadger.notify(error);
      return;
    }

    setLastUploadFailure(null);
    setIsUploading(false);

    onUploadFinished(videoId, selectedVideo);
  };

  const render = () => {
    if (isUploading) {
      return (
        <Container maxWidth="sm">
          <h1>Uploading your video...</h1>
          <LinearProgress
            value={uploadProgressPercentage}
            variant="determinate"
            color="secondary"
          />
        </Container>
      );
    } else {
      return (
        <React.Fragment>
          {lastUploadFailure && alertForFailure()}
          <input
            accept="video/*"
            ref={fileInputRef}
            type="file"
            id="raised-button-file"
            style={{ display: "none" }}
            multiple={false}
            onChange={(e) => uploadVideo(e.target.files)}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              size="large"
              {...buttonProps}
            >
              {buttonText ? buttonText : "Upload a Video for Free"}
            </Button>
          </label>
        </React.Fragment>
      );
    }
  };

  return render();
}
