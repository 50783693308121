import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Link, Typography } from "@mui/material";
import WorkoutVideosFeed from "./WorkoutVideosFeed";
import { usePageViewTracker } from "../Analytics";
import FeedFailedToLoad from "./FeedFailedToLoad";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 2),
  },
}));

const PublicFeed = () => {
  usePageViewTracker("feed");
  const classes = useStyles();
  const [uploads, setUploads] = useState(null);
  const [uploadsCursor, setUploadsCursor] = useState(null);
  const [didFailToLoad, setDidFailToLoad] = useState(false);

  useEffect(() => {
    fetchMoreUploads();
  }, []);

  const placeholderVideo = {
    video_id: "7989493b-78c9-4448-9823-f251efea08f6",
    uploaded_at: new Date("09 June 2021 14:48 UTC").toISOString(),
  };

  const fetchMoreUploads = async () => {
    const response = {
      data: {
        uploads: Array(5).fill(placeholderVideo),
      },
    };

    const nextPage = response.data.uploads;
    setUploads((oldVal) => {
      const existing = oldVal ? [...oldVal] : [];
      existing.push(...nextPage);
      return existing;
    });
    const nextToken = uploads && uploads.length < 12;
    setUploadsCursor(nextToken || null);
  };

  const noVideosFound = (
    <React.Fragment>
      <Typography component="h2" variant="h4">
        No videos found
      </Typography>
    </React.Fragment>
  );

  const getUploads = () => {
    if (uploads && uploads.length === 0) {
      return noVideosFound;
    } else {
      return (
        <WorkoutVideosFeed
          uploads={uploads}
          fetchMoreUploads={fetchMoreUploads}
          uploadsCursor={uploadsCursor}
        />
      );
    }
  };

  return (
    <Container className={classes.container}>
      {didFailToLoad ? FeedFailedToLoad() : getUploads()}
    </Container>
  );
};

export default PublicFeed;
