export function linearScale(a, b) {
  let range = b - a;
  console.assert(a < b);
  return (arr) => {
    let _min = Math.min(...arr);
    let domain = Math.max(...arr) - _min;
    if (domain == 0) {
      // if the domain is empty then this transformation isn't well-defined
      // we'll handle it by arbitrarily mapping the input to the end
      // of the interval
      return arr.map((_) => b);
    } else {
      let scale = range / domain;
      return arr.map((x) => _min + (x - _min) * scale);
    }
  };
}

export function sum(xs) {
  return xs.reduce((acc, c) => acc + c, 0);
}

export function mean(xs) {
  return sum(xs) / xs.length;
}

// https://stackoverflow.com/a/53660837/895769
export function median(xs) {
  const sorted = xs.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

export function stdDev(xs) {
  let avg = mean(xs);
  let squareDiffs = xs.map((x) => Math.pow(x - avg, 2));
  return Math.sqrt(mean(squareDiffs));
}

export function distance2D(p1, p2) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

export function clamp(x, lo, hi) {
  return Math.min(Math.max(x, lo), hi);
}
