import { useAuth } from "../auth/GuruAuth";
import makeStyles from "@mui/styles/makeStyles";
import {
  AppBar,
  Button,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.primary.light,
  },
  contrast: {
    color: theme.palette.primary.contrastText,
  },
  // css selector for <link> elements that are children of the toolbar class
  toolbarLink: {
    color: theme.palette.primary.contrastText,
    "text-decoration": "none",
  },
}));

export default function OpsPortalAppBar() {
  const classes = useStyles();
  const { user, logout, isAuthenticated, isLoading } = useAuth();
  if (isLoading || !isAuthenticated) {
    return (
      <AppBar>
        <Toolbar>Loading...</Toolbar>
      </AppBar>
    );
  }

  const links = {
    "Recent Uploads": "/",
    "Pose Tasks": "/tasks",
    "Pose Batches": "/batches",
    "Pose Datasets": "/datasets",
    Scraping: "/scraping",
    "Cadoo Dashboard": "/dashboards/cadoo",
  };
  return (
    <Toolbar className={classes.toolbar}>
      <Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
        {Object.entries(links).map(([title, href]) => (
          <Link href={href} key={title} className={classes.toolbarLink}>
            {title}
          </Link>
        ))}
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography sx={{ fontSize: ".8rem" }}>{user.email}</Typography>
        <Button
          onClick={logout}
          className={classes.contrast}
          variant="outlined"
        >
          Log out
        </Button>
      </Stack>
    </Toolbar>
  );
}
