import Button from "@mui/material/Button";
import React from "react";
import { useHistory } from "react-router-dom";
import { useEventLogger } from "../Analytics";

export default function SeeDemoButton() {
  const history = useHistory();
  const logEvent = useEventLogger();
  const demoVideoId = "c96fa975-fd9e-4912-8f60-1db5a6d829d1";

  const seeDemo = () => {
    history.push(`/video/${demoVideoId}?isDemo=true`);
    logEvent("user_clicked_see_demo");
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      component="span"
      size="large"
      onClick={(e) => seeDemo()}
    >
      See a demo
    </Button>
  );
}
