import { useEffect, useState } from "react";
import { useAuth } from "./auth/GuruAuth";
import CenteredBox from "./CenteredBox";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Config } from "../App";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

const LinkInNewWindow = ({ href, children }) => (
  <Link href={href} rel="noopener noreferrer" target="_blank">
    {children}
  </Link>
);

const TermsAndConditionsModal = ({ isOpen, onAccept }) => {
  const [hasClickedAccept, setHasClickedAccept] = useState(false);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Welcome to Guru!</DialogTitle>
      <Box sx={{ m: 2 }}>
        <Typography sx={{ paddingBottom: 4 }}>
          By continuing you agree to the{" "}
          <LinkInNewWindow href="/terms">Terms and Conditions</LinkInNewWindow>{" "}
          and <LinkInNewWindow href="/privacy">Privacy Policy</LinkInNewWindow>.
        </Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          alignItems="center"
        >
          {hasClickedAccept && <CircularProgress size={24} />}
          <FormGroup>
            <FormControlLabel
              label="I agree"
              control={
                <Checkbox
                  checked={hasClickedAccept}
                  onChange={() => {
                    setHasClickedAccept(true);
                    onAccept();
                  }}
                />
              }
            />
          </FormGroup>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default function AthleteSignup() {
  const { isAuthenticated, isLoading, login, getToken } = useAuth();
  const location = useLocation();
  const apiClient = axios.create({
    baseURL: Config.apiEndpoint,
  });
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isTokenValidationDone, setIsTokenValidationDone] = useState(false);

  const [isShowingTerms, setIsShowingTerms] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    validateToken(token);
  }, [location]);

  useEffect(() => {
    if (isLoading || !isTokenValid) {
      return;
    }

    const token = new URLSearchParams(location.search).get("token");
    if (isAuthenticated) {
      setIsShowingTerms(true);
    } else {
      login({
        action: "signup",
        appState: { returnTo: `/invite?token=${token}` },
      });
    }
  }, [isLoading, isAuthenticated, isTokenValid, location]);

  const validateToken = async (token) => {
    if (!token) {
      setIsTokenValidationDone(true);
      return;
    }

    try {
      await apiClient.head(`/invite/${token}`, {
        headers: {
          Accept: "application/json",
        },
      });
      setIsTokenValid(true);
    } finally {
      setIsTokenValidationDone(true);
    }
  };

  const onUserSignedUp = async (token) => {
    const authToken = await getToken();
    const data = null;
    await apiClient.post(`/invite/${token}/accept`, data, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
  };

  if (isLoading) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  } else if (isTokenValidationDone && !isTokenValid) {
    return (
      <CenteredBox>
        <Typography variant="h2">Uh-oh.</Typography>
        <Typography variant="body">
          We couldn't find that invite link.{" "}
          <Link href="/contact">Contact us</Link> for help.
        </Typography>
      </CenteredBox>
    );
  } else if (isShowingTerms) {
    return (
      <TermsAndConditionsModal
        isOpen={isShowingTerms}
        onAccept={() => {
          const token = new URLSearchParams(location.search).get("token");
          onUserSignedUp(token).then(() => {
            setIsShowingTerms(false);
            history.replace({
              pathname: "/",
              search: null,
              state: { isNewUser: true },
            });
          });
        }}
      />
    );
  } else {
    return (
      <CenteredBox>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="h2" component="span">
            Redirecting...
          </Typography>
        </Stack>
      </CenteredBox>
    );
  }
}
