import React from "react";
import { Link, Typography } from "@mui/material";

const FeedFailedToLoad = () => {
  return (
    <React.Fragment>
      <Typography component="h2" variant="h4">
        Something went wrong
      </Typography>
      <Typography variant="body2" paragraph>
        We weren't able to load videos. Please{" "}
        <Link href="/contact"> let us know</Link> if the problem persists.
      </Typography>
    </React.Fragment>
  );
};

export default FeedFailedToLoad;
