import { drawCircle, drawLine, drawText, drawRect } from "./Draw.js";
import { createButton } from "./CanvasButton.js";

const createLinearScale = ({ x1, y1, x2, y2 }) => {
  const rise = y2 - y1;
  const run = x2 - x1;
  let m;
  if (rise === 0) {
    m = 0;
  } else if (run === 0) {
    m = undefined;
  } else {
    m = rise / run;
  }
  const b = y1 - m * x1;
  return (x) => m * x + b;
};

const typicalScreenWidth = 250;
const scaleFont = (targetFontSize) =>
  createLinearScale({
    x1: 0,
    y1: 0,
    x2: typicalScreenWidth,
    y2: targetFontSize,
  });

const CanvasPlaybackControls = (
  { isPlaying, durationSec, playbackRate },
  ctx,
  reps,
  { onUserSkippedSetup, onPlaybackStateChanged, onSeeked },
  uiState
) => {
  const isVideoLoading = durationSec === null || durationSec === undefined;
  if (isVideoLoading) {
    return;
  }

  const canvasWidth = ctx.canvas.width;
  const canvasHeight = ctx.canvas.height;

  const typicalCanvasHeight = 550; // on mobile
  const fontSizeForTypicalCanvas = 18;
  const fontSize =
    (canvasHeight / typicalCanvasHeight) * fontSizeForTypicalCanvas;

  const isMouseOver = (btn) => {
    const mouseX = uiState.mousePosition.x;
    const mouseY = uiState.mousePosition.y;
    return btn.isMouseOver(mouseX, mouseY);
  };
  const interceptClick = (btn) => {
    if (uiState.isClicked && isMouseOver(btn)) {
      uiState.isClicked = false;
      return true;
    }
    return false;
  };

  const progressBar = () => {
    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    // draw background rectangle for contrast
    const progressCircleRadius = 0.015;
    const padding = progressCircleRadius + 0.01;

    const x1 = 0.05;
    const y1 = 0.9;
    const w = 0.9;
    const h = 0.02;

    const absBtnX = canvasWidth * x1;
    const absBtnY = canvasHeight * y1;
    const absWidth = canvasWidth * w;
    const absHeight = canvasHeight * h;

    const xToTime = (clickX) => {
      const frac = (clickX - absBtnX) / absWidth;
      return frac * durationSec;
    };
    const timeToX = (t) => {
      const frac = t / durationSec;
      return absBtnX + frac * absWidth;
    };

    // draw a semi-transparent dark background, for contrast
    drawRect(
      ctx,
      { x: (x1 - padding) * canvasWidth, y: (y1 - padding) * canvasHeight },
      { x: (x1 + w + padding) * canvasWidth, y: canvasHeight * 0.99 },
      { color: "black", alpha: 0.4 }
    );

    // draw progress bar
    const btn = createButton({
      canvas: ctx.canvas,
      x: x1,
      y: y1,
      width: w,
      height: h,
    });
    if (interceptClick(btn)) {
      const targetTimeSec = xToTime(uiState.mousePosition.x);
      onSeeked(targetTimeSec * 1000);
    }

    drawLine(
      ctx,
      { position: { x: absBtnX, y: absBtnY + absHeight / 2 } },
      { position: { x: absBtnX + absWidth, y: absBtnY + absHeight / 2 } },
      { color: "white", width: 4 }
    );
    if (reps) {
      reps.forEach((rep) => {
        const xPos = timeToX(rep.startTimestampMs / 1000);
        drawLine(
          ctx,
          { position: { x: xPos, y: absBtnY } },
          { position: { x: xPos, y: absBtnY + absHeight } },
          { color: "red", alpha: 0.75, width: 3 }
        );
      });
    }
    drawCircle(
      ctx,
      timeToX(uiState.t / 1000),
      absBtnY + absHeight / 2,
      progressCircleRadius * canvasWidth,
      { color: "white", alpha: 1.0 }
    );
    return btn;
  };

  const targetFontSize = 14;
  const skipSetupFontScaler = (x) => Math.min(20, scaleFont(targetFontSize)(x));

  const skipSetupButton = () => {
    if (!reps || reps.length === 0) {
      return;
    }
    const currentTimeMs = uiState.t;
    const firstRepMs = reps[0].startTimestampMs;
    const timeUntilFirstRep = firstRepMs - currentTimeMs;
    const minSkipDuration = 1500;
    if (timeUntilFirstRep <= minSkipDuration) {
      return;
    }

    const btn = createButton({
      canvas: ctx.canvas,
      x: 0.05,
      y: 0.8,
      width: -1,
      height: -1,
    });
    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    const absX = canvasWidth * btn.x;
    const absY = canvasHeight * btn.y;

    const fontSize = skipSetupFontScaler(canvasWidth);
    const { width, height } = drawText(
      ctx,
      "Skip setup ►",
      absX,
      absY,
      canvasWidth,
      {
        fontSize: fontSize * 0.8,
        background: "white",
        alpha: 0.1,
        textAlpha: 1.0,
        borderColor: "white",
        borderWidth: 1,
        color: "white",
        padding: 8,
      }
    );
    btn.width = width / canvasWidth;
    btn.height = height / canvasHeight;

    if (interceptClick(btn)) {
      onSeeked(reps[0].startTimestampMs);
      onPlaybackStateChanged({ playbackRate, isPlaying: true });
      if (onUserSkippedSetup) {
        onUserSkippedSetup();
      }
    } else if (isMouseOver(btn)) {
      drawRect(
        ctx,
        { x: absX, y: absY },
        { x: absX + width, y: absY + height },
        { alpha: 0.4, color: "white" }
      );
    }
  };

  const drawPlayIcon = (x1, y1, w, h) => {
    ctx.fillStyle = "white";
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1, y1 + h);
    ctx.lineTo(x1 + w, y1 + h / 2);
    ctx.fill();
  };

  const drawPauseIcon = (x1, y1, w, h) => {
    const thickness = w / 3;
    drawLine(
      ctx,
      { position: { x: x1, y: y1 } },
      { position: { x: x1, y: y1 + h } },
      { color: "white", width: thickness }
    );
    drawLine(
      ctx,
      { position: { x: x1 + 2 * thickness, y: y1 } },
      { position: { x: x1 + 2 * thickness, y: y1 + h } },
      { color: "white", width: thickness }
    );
  };

  const playButton = (progressBar) => {
    const progressBarBottom = progressBar.y + progressBar.height;
    const y = progressBarBottom + 0.25 * (1 - progressBarBottom);

    const btn = createButton({
      canvas: ctx.canvas,
      x: progressBar.x + 0.05,
      y: y,
      width: 0.03,
      height: 0.03,
    });

    const drawIconFn = isPlaying ? drawPauseIcon : drawPlayIcon;
    drawIconFn(
      btn.x * canvasWidth,
      btn.y * canvasHeight,
      btn.width * canvasWidth,
      btn.height * canvasHeight
    );

    if (interceptClick(btn)) {
      onPlaybackStateChanged({ playbackRate, isPlaying: !isPlaying });
    }

    // draw time
    const fmtTime = (seconds) => {
      const minutes = Math.floor(seconds / 60)
        .toString()
        .padStart(1, "0");
      const sec = Math.floor(seconds % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${sec}`;
    };

    const timestampY = btn.y * canvasHeight;
    drawText(
      ctx,
      `${fmtTime(uiState.t / 1000)} / ${fmtTime(durationSec)}`,
      (btn.x + btn.width + 0.02) * canvasWidth,
      timestampY,
      canvasWidth,
      { background: null, fontSize: fontSize * 0.8 }
    );

    return btn;
  };

  const playbackSpeedButton = (playButton) => {
    const btn = createButton({
      canvas: ctx.canvas,
      x: 0.8,
      y: playButton.y,
      width: -1,
      height: playButton.height,
      speedIndex: 0,
    });

    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    const fmtSpeed = (x) => (x === 1 ? "1.0" : x);
    const { width, height } = drawText(
      ctx,
      `${fmtSpeed(playbackRate)}x`,
      btn.x * canvasWidth,
      btn.y * canvasHeight,
      canvasWidth,
      {
        fontSize: fontSize * 0.75,
        background: "white",
        borderColor: "white",
        alpha: 0.1,
        textAlpha: 1.0,
        color: "white",
      }
    );
    btn.width = width / canvasWidth;
    btn.height = height / canvasHeight;
    if (btn.isMouseOver(uiState.mousePosition.x, uiState.mousePosition.y)) {
      drawRect(
        ctx,
        { x: btn.x * canvasWidth, y: btn.y * canvasHeight },
        { x: width + btn.x * canvasWidth, y: height + btn.y * canvasHeight },
        { alpha: 0.4, color: "white" }
      );
    }

    if (interceptClick(btn)) {
      let newRate = playbackRate / 2;
      if (newRate < 0.25) {
        newRate = 1;
      }
      onPlaybackStateChanged({ playbackRate: newRate, isPlaying });
    }
  };

  const draw = () => {
    const progressBarBtn = progressBar();
    skipSetupButton();
    const playButtonBtn = playButton(progressBarBtn);
    playbackSpeedButton(playButtonBtn);
  };

  draw();
};

export default CanvasPlaybackControls;
