const style = {
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "20px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%",
};

const phantom = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};

// https://stackoverflow.com/a/40568544/895769
export default function StickyFooter({ children }) {
  return (
    <div>
      <div style={phantom} />
      <div style={style}>{children}</div>
    </div>
  );
}
