import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { usePageViewTracker } from "../Analytics";
import Copyright from "./Copyright";
import WorkoutVideoUploader from "../WorkoutVideoUploader";
import CookieBanner from "./CookieBanner";
import SeeDemoButton from "./SeeDemoButton";
import { ArrowDownwardOutlined } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import upload_to_formguru_img from "./upload.png";
import feedback_on_every_rep_img from "./feedback_on_every_rep.png";
import LatestFeaturesLink from "./LatestFeaturesLink";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    // TODO: Homepage image
    backgroundImage: "",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function MobileHomepage() {
  const classes = useStyles();
  usePageViewTracker("Home");

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12}>
          <Box className={classes.paper}>
            <Box mt={3} mb={6} sx={{ textAlign: "center" }}>
              <Typography variant="h1">Stop lifting with bad form</Typography>
            </Box>
            <Box>
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                🏋️ Take a video of your lift
              </Typography>
              <br />
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                📱 Upload it to Formguru
              </Typography>
              <br />
              <Typography variant="h6" style={{ fontWeight: 300 }}>
                🎥 Get instant video analysis
              </Typography>
              <br />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box ml={3}>
            <WorkoutVideoUploader
              buttonSize="small"
              buttonText="Upload Video"
              source="formguru.fitness"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box ml={3}>
            <SeeDemoButton />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={3}>
            <LatestFeaturesLink />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.paper}>
            <Typography variant="subtitle1" style={{ fontWeight: 300 }}>
              Learn More
            </Typography>
            <Avatar className={classes.avatar}>
              <ArrowDownwardOutlined />
            </Avatar>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CookieBanner />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <img
          src={upload_to_formguru_img}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <img
          src={feedback_on_every_rep_img}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </div>
  );
}
