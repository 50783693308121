import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import makeStyles from "@mui/styles/makeStyles";
import { drawCircle, drawLine, drawRect, drawText } from "../Draw";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import {
  useEditableKeypoints,
  useTaskResults,
} from "./keypoint_task/KeypointTaskHook";
import { useClickAndDragHandler } from "./keypoint_task/ClickHandler";
import { inferKeypoints } from "./InBrowserPoseEstimation";
import { GURU_KEYPOINTS } from "./Constants";
import { KeypointsSelector } from "./KeypointsSelector";

const useStyles = makeStyles((theme) => ({
  canvas: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

const BboxesPanel = ({ bboxes, onEdit }) => {
  const BboxInfo = ({ id, label: initialLabel }) => {
    const [label, setLabel] = useState(initialLabel);
    const CLASSES = ["person", "platesCenter"];

    const onDelete = () => {
      const updated = { ...bboxes };
      delete updated[id];
      onEdit(updated);
    };

    const handleChange = useCallback(
      (e) => {
        const newLabel = e.target.value;
        if (label === newLabel) {
          return;
        }
        setLabel(newLabel);
        onEdit({
          ...bboxes,
          [id]: {
            ...bboxes[id],
            label: newLabel,
          },
        });
      },
      [label]
    );

    return (
      <>
        <Card sx={{ minWidth: 150, maxWidth: 300 }}>
          <CardContent>
            <Typography variant="h5" component="span">
              {id}: {label}
            </Typography>
            <IconButton aria-label="delete" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            <FormControl fullWidth>
              <InputLabel>Class</InputLabel>
              <Select
                id={`bbox-class-${id}`}
                value={label}
                label="class"
                onChange={handleChange}
              >
                {CLASSES.map((c) => (
                  <MenuItem value={c}>{c}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <Stack>
      {Object.entries(bboxes)
        .filter(([id, bbox]) => id !== "<pending>")
        .map(([id, bbox]) => (
          <BboxInfo key={id} id={id} {...bbox} />
        ))}
    </Stack>
  );
};

const ImgCanvas = forwardRef(({ src, onLoad }, ref) => {
  const classes = useStyles();
  const [displayDim, setDisplayDim] = useState(null);
  const imgRef = useRef();

  useEffect(() => {
    const onResize = () => doResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const doResize = useCallback(() => {
    const imgEl = imgRef.current;
    setDisplayDim({
      width: imgEl.width,
      height: imgEl.height,
    });
  }, [imgRef]);

  const handleLoad = (e) => {
    if (onLoad) {
      onLoad(e);
    }
    doResize();
  };

  return (
    <div
      id="img-canvas"
      style={{
        position: "relative",
        height: "70vh",
        width: "auto",
      }}
    >
      <img
        crossOrigin="anonymous"
        className={classes.image}
        ref={imgRef}
        src={src}
        onLoad={handleLoad}
      />
      {displayDim ? (
        <canvas
          className={classes.canvas}
          ref={ref}
          height={displayDim.height}
          width={displayDim.width}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
});

export default function AnnotationTaskViewer({ taskId }) {
  const { id: taskIdFromUrl } = useParams();
  const id = taskId || taskIdFromUrl;

  const {
    imgSrc,
    externalUri,
    savedKeypoints,
    savedBboxes,
    isLoading,
    isSaving,
    didSave,
    error: fetchError,
    save,
  } = useTaskResults(id);

  const {
    isEditable: areKeypointsEditable,
    isDirty: areKeypointsDirty,
    bboxes,
    updateBboxes,
    keypoints,
    updateKeypoints,
  } = useEditableKeypoints({ savedKeypoints, savedBboxes });

  const [img, setImg] = useState(null);
  const [imgDim, setImgDim] = useState(null);

  const [error, setError] = useState();
  const [isShowLabels, setIsShowLabels] = useState(false);
  const [isDrawingBbox, setIsDrawingBbox] = useState(false);
  const [selectedKeypoint, setSelectedKeypoint] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);

  useEffect(() => {
    if (fetchError) {
      setError(fetchError);
    }
  }, [fetchError]);

  const onMouseDown = useCallback(
    (domEl, { x: mouseX, y: mouseY }) => {
      if (!areKeypointsEditable) {
        return;
      }
      mouseX /= domEl.width;
      mouseY /= domEl.height;
      const clickedKeypoint = Object.entries(keypoints).find(
        ([label, { x, y }]) => {
          const TOLERANCE = 0.01;
          return (
            Math.abs(x - mouseX) <= TOLERANCE &&
            Math.abs(y - mouseY) <= TOLERANCE
          );
        }
      );
      if (clickedKeypoint) {
        const [label, _] = clickedKeypoint;
        setSelectedKeypoint(label);
      } else {
        setSelectedKeypoint(null);
      }
      console.log(keypoints);
    },
    [keypoints, areKeypointsEditable]
  );

  const onDrag = useCallback(
    (domEl, { x: x1, y: y1 }, { x: x2, y: y2 }) => {
      y1 = y1 / domEl.height;
      y2 = y2 / domEl.height;
      x1 = x1 / domEl.width;
      x2 = x2 / domEl.width;
      if (isDrawingBbox) {
        updateBboxes((bboxes) => {
          const result = { ...bboxes };
          result["<pending>"] = {
            top: Math.min(y1, y2),
            left: Math.min(x1, x2),
            width: Math.abs(x2 - x1),
            height: Math.abs(y2 - y1),
          };
          return result;
        });
      } else if (selectedKeypoint !== null) {
        updateKeypoints((keypoints) => {
          return {
            ...keypoints,
            [selectedKeypoint]: {
              x: x2,
              y: y2,
            },
          };
        });
      }
    },
    [imgDim, keypoints, selectedKeypoint, isDrawingBbox]
  );

  const onDragFinished = useCallback(
    (domEl, { x: x1, y: y1 }, { x: x2, y: y2 }) => {
      y1 = y1 / domEl.height;
      y2 = y2 / domEl.height;
      x1 = x1 / domEl.width;
      x2 = x2 / domEl.width;
      if (isDrawingBbox) {
        updateBboxes((bboxes) => {
          const result = { ...bboxes };
          delete result["<pending>"];
          const id = Math.max(0, ...Object.keys(result)) + 1;
          result[id] = {
            top: Math.min(y1, y2),
            left: Math.min(x1, x2),
            width: Math.abs(x2 - x1),
            height: Math.abs(y2 - y1),
            label: "person",
          };
          const personBboxes = Object.values(result).filter(
            (bbox) => bbox["label"] === "person"
          );
          if (!areKeypointsDirty && personBboxes.length === 1) {
            prepopulateKeypoints(img, personBboxes[0]);
          }
          return result;
        });
      }
    },
    [img, areKeypointsDirty, imgDim, selectedKeypoint, isDrawingBbox]
  );

  const onMouseMove = useCallback(
    (_el, { x, y }) => {
      if (isDrawingBbox) {
        setMousePosition({ x, y });
      }
    },
    [isDrawingBbox]
  );

  const [canvasDomEl, setCanvasDomEl] = useClickAndDragHandler({
    onMouseMove,
    onMouseDown,
    onDrag,
    onDragFinished,
  });

  useEffect(() => {
    const ctx = canvasDomEl && canvasDomEl.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      if (keypoints) {
        drawKeypoints(ctx, keypoints, isShowLabels, selectedKeypoint);
      }
      if (bboxes) {
        drawBboxes(ctx, bboxes);
      }
      if (isDrawingBbox && mousePosition) {
        const { x, y } = mousePosition;
        drawCrossHairs(ctx, x, y);
      }
    }
  }, [
    canvasDomEl,
    keypoints,
    bboxes,
    isDrawingBbox,
    isShowLabels,
    mousePosition,
    imgDim,
    selectedKeypoint,
  ]);

  const saveAnnotations = useCallback(() => {
    save(bboxes, keypoints);
  }, [keypoints, bboxes]);

  const handleKeydown = useCallback(
    (e) => {
      const KEYS = {
        KeyL: () => setIsShowLabels((currentVal) => !currentVal),
        KeyB: () => setIsDrawingBbox((v) => !Boolean(v)),
        KeyS: () => areKeypointsEditable && saveAnnotations(),
        Escape: () => setIsDrawingBbox(false),
        Backspace: () => {
          if (selectedKeypoint) {
            updateKeypoints((keypoints) => {
              const result = { ...keypoints };
              delete result[selectedKeypoint];
              return result;
            });
          }
        },
      };

      if (e.code in KEYS) {
        const fn = KEYS[e.code];
        fn(e);
      }
    },
    [selectedKeypoint, saveAnnotations, areKeypointsEditable]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  const drawBboxes = (ctx, bboxes) => {
    if (!bboxes) {
      return;
    }
    Object.entries(bboxes).forEach(
      ([id, { top, left, width, height, label }]) => {
        const x1 = ctx.canvas.width * left;
        const y1 = ctx.canvas.height * top;
        const x2 = x1 + ctx.canvas.width * width;
        const y2 = y1 + ctx.canvas.height * height;
        const color = { person: "red", platesCenter: "blue" }[label] || "white";
        drawRect(
          ctx,
          { x: x1, y: y1 },
          { x: x2, y: y2 },
          { borderColor: color, borderWidth: 1, alpha: 1.0 }
        );
        if (id !== "<pending>") {
          drawText(ctx, `${id}`, x1, y1 - 18, 300, {
            fontSize: 10,
            background: color,
          });
        }
      }
    );
  };

  const drawKeypoints = (
    ctx,
    keypoints,
    isShowLabels,
    selectedKeypoint = null
  ) => {
    if (!keypoints) {
      return;
    }

    const jointPairs = ["left", "right"].reduce((acc, direction) => {
      const pairsThisSide = Object.fromEntries(
        [
          ["foot_index", "ankle"],
          ["foot_index", "heel"],
          ["heel", "ankle"],
          ["ankle", "knee"],
          ["knee", "hip"],
          ["hip", "shoulder"],
          ["shoulder", "elbow"],
          ["elbow", "wrist"],
        ].map(([a, b]) => [`${direction}_${a}`, `${direction}_${b}`])
      );
      return { ...acc, ...pairsThisSide };
    }, {});
    Object.entries(jointPairs).forEach(([a, b]) => {
      const joint1 = keypoints[a];
      const joint2 = keypoints[b];
      if (joint1 && joint2) {
        drawLine(
          ctx,
          {
            position: {
              x: joint1.x * ctx.canvas.width,
              y: joint1.y * ctx.canvas.height,
            },
          },
          {
            position: {
              x: joint2.x * ctx.canvas.width,
              y: joint2.y * ctx.canvas.height,
            },
          },
          {
            color: a.startsWith("left") ? "blue" : "red",
            alpha: 0.25,
            width: 2,
          }
        );
      }
    });

    Object.entries(keypoints).forEach(([label, { x, y }]) => {
      if (label === selectedKeypoint) {
        drawCircle(ctx, ctx.canvas.width * x, ctx.canvas.height * y, 1, {
          color: label.startsWith("left") ? "blue" : "red",
          alpha: 1.0,
        });
        drawCircle(ctx, ctx.canvas.width * x, ctx.canvas.height * y, 7, {
          isFilled: false,
          color: label.startsWith("left") ? "blue" : "red",
          alpha: 0.5,
        });
      } else {
        drawCircle(ctx, ctx.canvas.width * x, ctx.canvas.height * y, 3, {
          color: label.startsWith("left") ? "blue" : "red",
        });
      }

      if (isShowLabels) {
        drawText(ctx, label, ctx.canvas.width * x, ctx.canvas.height * y, 100, {
          fontSize: 10,
          alpha: 0.25,
          background: "black",
          textAlpha: 1.0,
        });
      }
    });
  };

  const drawCrossHairs = (ctx, x, y) => {
    const lineOpts = {
      color: "red",
      alpha: 0.5,
      width: 1,
      isDashed: true,
    };
    // draw cross-hairs
    drawLine(
      ctx,
      { position: { x, y: 0 } },
      { position: { x, y: ctx.canvas.height } },
      lineOpts
    );
    drawLine(
      ctx,
      { position: { x: 0, y } },
      { position: { x: ctx.canvas.width, y } },
      lineOpts
    );
    // display coords in lower right corner
    drawText(
      ctx,
      `(${Math.round(x)},${Math.round(y)})`,
      ctx.canvas.width - 90,
      ctx.canvas.height - 30,
      90,
      {
        background: "black",
        alpha: 0.3,
        textAlpha: 1.0,
        fontSize: 14,
      }
    );
  };

  const KeyReferencePopover = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const isOpen = Boolean(anchorEl);
    const id = isOpen ? "key-reference-popover" : undefined;

    return (
      <>
        <Tooltip title="Show Keybindings">
          <IconButton
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <KeyboardIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={isOpen}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <h3>Key Reference:</h3>
          <ul>
            <li>
              <strong>b</strong> - Create a bounding box. Drag to draw.
            </li>
            <li>
              <strong>backspace</strong> - Delete selected label.
            </li>
            <li>
              <strong>esc</strong> - Stop drawing bounding box.
            </li>
            <li>
              <strong>l</strong> - Show/hide labels.
            </li>
            <li>
              <strong>s</strong> - Save annotations.
            </li>
          </ul>
        </Popover>
      </>
    );
  };

  const renderSaveButton = () => {
    if (didSave) {
      return <Alert severity="success">Saved</Alert>;
    } else if (isSaving) {
      return <Loading />;
    }
    return (
      <Button
        onClick={saveAnnotations}
        disabled={!(areKeypointsEditable && areKeypointsDirty)}
      >
        Save
      </Button>
    );
  };

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  } else if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Grid container justifyContent="space-between" padding={1}>
        <>
          {externalUri && (
            <Grid item xs={12}>
              <a href={externalUri}>View task in Scale UI</a>
            </Grid>
          )}
          <Grid item xs={12}>
            <a href={`/tasks/${id}`}>View task</a>
            <KeyReferencePopover />
          </Grid>
        </>
        <Grid item xs={12}>
          {areKeypointsEditable ? (
            <>
              <Typography component="span" fontWeight={"medium"}>
                Label:{" "}
              </Typography>
              <Typography component="span" fontWeight={"light"}>
                {selectedKeypoint || "—"}
              </Typography>
            </>
          ) : (
            <>
              <Tooltip title="TODO: support PATCH on existing annotations">
                <IconButton>
                  <LockIcon />
                </IconButton>
              </Tooltip>
              <Typography component="span" variant="body2">
                Keypoints are locked
              </Typography>
            </>
          )}
        </Grid>
        <Grid container wrap="nowrap">
          <Grid item xs={11}>
            <ImgCanvas
              src={imgSrc}
              onLoad={async (e) => {
                const {
                  target: { offsetHeight: height, offsetWidth: width },
                } = e;
                setImgDim({ width, height });
                setImg(e.target);
              }}
              onMouseMove={onMouseMove}
              ref={setCanvasDomEl}
            />
          </Grid>

          <Grid item xs={1}>
            {Object.keys(bboxes).filter((id) => id !== "<pending>").length >
              0 && (
              <Grid container direction="column">
                <Grid item>
                  <BboxesPanel
                    bboxes={bboxes}
                    onEdit={(bboxes) => updateBboxes((_) => bboxes)}
                  />
                </Grid>

                <Grid item>{renderSaveButton()}</Grid>
              </Grid>
            )}
          </Grid>

          <Grid item id="keypoints-sidebar">
            <KeypointsSelector
              containerId={"keypoints-sidebar"}
              keypoints={keypoints}
              onToggle={(label) => {
                updateKeypoints((keypoints) => {
                  const result = { ...keypoints };
                  if (label in keypoints) {
                    delete result[label];
                  } else {
                    result[label] = {
                      score: 1.0,
                      x: 0.1,
                      y: 0.1,
                    };
                  }
                  return result;
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  async function prepopulateKeypoints(img, personBbox = null) {
    const crop = personBbox
      ? {
          top: personBbox.top,
          left: personBbox.left,
          height: personBbox.height,
          width: personBbox.width,
        }
      : null;
    const inferredKeypoints = await inferKeypoints(img, crop);
    const keypoints = {};
    GURU_KEYPOINTS.filter((label) => label in inferredKeypoints).forEach(
      (label) => {
        const { x, y, score } = inferredKeypoints[label];
        keypoints[label] = {
          x: x / img.width,
          y: y / img.height,
          score,
        };
      }
    );
    updateKeypoints((_) => keypoints);
  }
}
