export const GURU_KEYPOINTS = [
  "nose",
  ...[
    "hip",
    "knee",
    "ankle",
    "heel",
    "foot_index",
    "shoulder",
    "elbow",
    "wrist",
  ].flatMap((joint) => [`left_${joint}`, `right_${joint}`]),
];
