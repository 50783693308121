import {
  useAuth0,
  withAuthenticationRequired,
  Auth0Provider,
} from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { Route } from "react-router-dom";
import Loading from "../Loading";
import CenteredBox from "../CenteredBox";
import { Config } from "../../App";

const NullAuth = {
  isAuthEnabled: false,
  isLoading: false,
  isAuthenticated: false,
  user: null,
  getToken: () => null,
  logout: () => null,
  login: () => null,
};

export const useAuth = () => {
  // If we call useAuth0() directly it will raise an exception when the
  // Auth0Provider isn't configured (and it isn't for formguru.fitness)

  // This indirection is a workaround for React's prohibition on conditional
  // hooks
  const auth0Hook = Config.isUsingAuth0 ? useAuth0 : () => null;
  const auth0State = auth0Hook();

  if (auth0State !== null) {
    const {
      getAccessTokenSilently,
      isAuthenticated,
      isLoading,
      logout,
      loginWithRedirect,
      user,
    } = auth0State;

    const getToken = async () => {
      const token = await getAccessTokenSilently();
      return token;
    };

    return {
      isAuthEnabled: true,
      isLoading,
      isAuthenticated,
      user,
      getToken,
      logout,
      login: loginWithRedirect,
    };
  }

  return NullAuth;
};

export const ProtectedRoute = ({ component, ...args }) => {
  const { error } = useAuth0();

  if (error) {
    return (
      <CenteredBox>
        <Typography variant="h1">Access Denied</Typography>
        <Typography variant="body">Details: {error.message}</Typography>
      </CenteredBox>
    );
  }

  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loading message="Redirecting..." />,
      })}
      {...args}
    />
  );
};

export const Auth0ProviderWithHistory = ({
  history,
  children,
  auth0Config,
}) => {
  const onRedirectCallback = (appState) => {
    console.debug(`Redirecting... returnTo=${appState && appState.returnTo}`);
    if (appState && appState.returnTo) {
      history.replace(appState.returnTo);
      history.go();
    } else {
      history.push(window.location.pathname);
    }
  };

  return (
    <Auth0Provider
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      {...auth0Config}
    >
      {children}
    </Auth0Provider>
  );
};
