import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Input, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { StyledMenu } from "./StyledMenu";
import { StyledMenuItem } from "./StyledMenuItem";

const useStyles = makeStyles((theme) => ({
  popover: {
    "& div, span": {
      padding: theme.spacing(0.5),
    },
  },
}));

export default function VideoPermalink({ videoId }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasClickedCopy, setHasClickedCopy] = React.useState(false);

  const getUri = () => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    return `${protocol}//${host}/video/${videoId}`;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const copyLink = async () => {
    await navigator.clipboard.writeText(getUri());
    setHasClickedCopy(true);
    handleMenuClose();
  };

  return (
    <div>
      <Button
        color="primary"
        fullWidth
        type="submit"
        variant="contained"
        onClick={handleMenuClick}
        aria-label="menu"
        startIcon={<MobileScreenShareIcon />}
      >
        Share
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <StyledMenuItem onClick={copyLink}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="Copy Link" />
        </StyledMenuItem>
      </StyledMenu>
      <span hidden={!hasClickedCopy}> Copied to clipboard!</span>
    </div>
  );
}
