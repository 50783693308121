import { distance2D, linearScale, mean, stdDev, sum } from "./Math.js";

export function isFacingRight(jointToPoints) {
  function meanXPos(jointName) {
    const arr = jointToPoints[jointName]
      .map((p) => p.position.x)
      .filter((x) => x >= 0);
    return mean(arr);
  }

  const isDetected = (joint) => {
    return joint in jointToPoints && jointToPoints[joint].length > 0;
  };

  const rightSideDetected = ["rightKnee", "rightAnkle"].every(isDetected);
  const leftSideDetected = ["leftKnee", "leftAnkle"].every(isDetected);
  if (rightSideDetected !== leftSideDetected) {
    return rightSideDetected;
  } else {
    const kneesMeanXPos = mean(
      ["leftKnee", "rightKnee"].map((joint) => meanXPos(joint))
    );
    const anklesMeanXPos = mean(
      ["leftAnkle", "rightAnkle"].map((joint) => meanXPos(joint))
    );
    return kneesMeanXPos >= anklesMeanXPos;
  }
}

export function estimateToesXCoord(ankleXCoord, isFacingRight) {
  let hardcodedFootWidthPx = 120 * (isFacingRight ? 1 : -1);
  return ankleXCoord + hardcodedFootWidthPx / 2;
}

export function isInSquat(smoothedKnee, hip) {
  const DEPTH_THRESHOLD_DEGREES = 50;
  return getHipKneeAngleDegrees(smoothedKnee, hip) < DEPTH_THRESHOLD_DEGREES;
}

export function getHipKneeAngleDegrees(knee, hip) {
  let hipKneeYDistance = knee.y - hip.position.y;
  let hipKneeDistance = distance2D(knee, hip.position);

  // theta is the angle formed by the horizontal plane and the
  // knee-hip connector
  let sinTheta = hipKneeYDistance / hipKneeDistance;
  return (Math.asin(sinTheta) * 180) / Math.PI;
}

function isBetween(x, lower, upper) {
  return x >= lower && x <= upper;
}

/**
 * Return a linearly weighted average of the last N values
 *
 * The elements at the end of the array receive more weight than those at the
 * beginning.
 *
 * @param {Array[Number]} xs
 * @param {Integer} n
 */
function applySmoothing(xs, n = 5) {
  if (n <= 1) {
    return xs;
  }
  // >= 2 stddev from the mean is probably a false detection
  let avg = mean(xs);
  let _stdDev = stdDev(xs);
  let [lo, hi] = [avg - 2 * _stdDev, avg + 2 * _stdDev];
  let nonOutliers = xs.filter((x) => isBetween(x, lo, hi));
  let lastN = nonOutliers.slice(Math.max(nonOutliers.length - n, 0));

  let weights = linearScale(0.0, 1.0)(lastN.map((_x, idx) => idx));
  let normalized = weights.map((w) => w / sum(weights));
  return sum(normalized.map((w, idx) => w * lastN[idx]));
}

export function smoothPosition(keypoints, n = 5) {
  let x = applySmoothing(
    keypoints.map((p) => p.position.x),
    n
  );
  let y = applySmoothing(
    keypoints.map((p) => p.position.y),
    n
  );
  return { x: x, y: y };
}
