import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useEventLogger } from "./Analytics";
import { SUPPORTED_LIFTS } from "./LiftSelector";
import withClickHandler from "./video_player/ClickHandler";

const VideoCanvas = forwardRef((props, ref) => {
  const { isPlaying, seekTargetMs, playbackRate = 1.0 } = props;
  const PORTRAIT_ASPECT_RATIO = 9 / 16;
  const initialHeight = props.initialHeight || window.innerHeight * 0.7;
  const initialWidth =
    props.initialWidth || initialHeight * PORTRAIT_ASPECT_RATIO;
  const [videoWidth, setVideoWidth] = useState(initialWidth);
  const [videoHeight, setVideoHeight] = useState(initialHeight);

  const [hasVideoLoaded, setHasVideoLoaded] = useState(false);
  const logEvent = useEventLogger();
  const isNewUpload = Boolean(props["isNewUpload"]);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const videoAndCanvasRef = useRef(null);
  const animationRequestRef = useRef(null);
  const uiStateRef = useRef({
    mousePosition: { x: -1, y: -1 },
    isClicked: false,
  });
  const tickFn = useRef(props.tickFn);

  useImperativeHandle(ref, () => ({
    getDrawingContext: () => canvasRef.current.getContext("2d"),
  }));

  useEffect(() => {
    const onResize = () => resizeVideo(videoRef.current);
    window.addEventListener("resize", onResize);
    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    videoRef.current.currentTime = seekTargetMs / 1000;
  }, [seekTargetMs]);

  useEffect(() => {
    console.assert(playbackRate > 0);
    videoRef.current.playbackRate = playbackRate;
  }, [playbackRate]);

  const getLiftType = () => {
    return uiStateRef.current.selectedLiftType || SUPPORTED_LIFTS.OTHER;
  };

  useEffect(() => {
    if (!hasVideoLoaded) {
      return;
    }

    const loop = async () => {
      clearCanvas();
      if (videoRef.current) {
        uiStateRef.current.t = videoRef.current.currentTime * 1000;
      }
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext("2d");
        if (tickFn.current) {
          tickFn.current(uiStateRef.current, ctx);
        }
      }
      animationRequestRef.current = window.requestAnimationFrame(loop);
    };
    animationRequestRef.current = window.requestAnimationFrame(loop);
    return () => {
      cancelAnimationFrame(animationRequestRef.current);
    };
  }, [hasVideoLoaded]);

  useEffect(() => {
    tickFn.current = props.tickFn;
    uiStateRef.current.selectedLiftType = props.selectedLiftType;
    uiStateRef.current.videoWidth = videoWidth;
    uiStateRef.current.videoHeight = videoHeight;
  }, [props.tickFn, props.selectedLiftType, videoWidth, videoHeight]);

  useEffect(() => {
    videoRef.current.src = props.currentVideo + "#t=0.5";
  }, [props.currentVideo]);

  useEffect(() => {
    videoRef.current.playbackRate = props.playbackRate || 1.0;
  }, [playbackRate]);

  const onSeeked = (e) => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, videoWidth, videoHeight);
    try {
      ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    } catch (error) {
      // sometimes the image isn't available yet
    }
    if (props.onSeeked) {
      props.onSeeked(videoRef.current.currentTime);
    }
  };

  const resizeVideo = (video) => {
    // some embeds, e.g., reddit, specify a max-height property on the iFrame via CSS
    const maxHeight = props.maxHeight || window.innerHeight;

    const parent = videoAndCanvasRef.current.parentElement;
    const { offsetWidth, offsetHeight } = parent;
    const computedStyle = window.getComputedStyle(parent);
    const paddingLeft = parseInt(computedStyle["padding-left"], 10) || 0;
    const paddingRight = parseInt(computedStyle["padding-right"], 10) || 0;
    const paddingTop = parseInt(computedStyle["padding-top"], 10) || 0;
    const paddingBottom = parseInt(computedStyle["padding-bottom"], 10) || 0;
    const visibleWidth = offsetWidth - (paddingLeft + paddingRight);
    const visibleHeight = Math.min(
      maxHeight,
      offsetHeight - (paddingTop + paddingBottom)
    );

    let scalingFactor = Math.min(
      1.0,
      visibleWidth / video.videoWidth,
      visibleHeight / video.videoHeight
    );

    setVideoWidth(video.videoWidth * scalingFactor);
    setVideoHeight(video.videoHeight * scalingFactor);
  };

  const clearCanvas = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      const width = ctx.canvas.width;
      const height = ctx.canvas.height;
      ctx.clearRect(0, 0, width, height);
    }
  };

  const ClickableCanvas = withClickHandler("canvas", uiStateRef);

  return (
    <div
      id="video-and-canvas"
      ref={videoAndCanvasRef}
      style={{ position: "relative" }}
    >
      <ClickableCanvas
        ref={canvasRef}
        style={{ cursor: props.cursorStyle || "auto" }}
        width={videoWidth}
        height={videoHeight}
      />
      <video
        ref={videoRef}
        loop
        muted
        autoPlay={isPlaying}
        playsInline
        width={videoWidth}
        height={videoHeight}
        onLoadedData={() => setHasVideoLoaded(true)}
        onLoadedMetadata={(e) => {
          resizeVideo(videoRef.current);
          if (props.onLoadedMetadata) {
            props.onLoadedMetadata(e);
          }
        }}
        onSeeked={(e) => onSeeked(e)}
        onSeeking={(e) => {
          if (props.onSeeking) {
            props.onSeeking(videoRef.current.currentTime);
          }
        }}
        onPlay={(e) => {
          logEvent("user_played_video", {
            is_new_upload: isNewUpload,
            video_id: props.videoId,
            lift_type: getLiftType(),
          });
        }}
        onPause={(e) => {
          if (props.onPause) {
            props.onPause(videoRef.current.currentTime);
          }
        }}
        onEnded={(e) => {
          if (props.onEnded) {
            props.onEnded();
          }
        }}
      />
    </div>
  );
});

export default VideoCanvas;
