import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App, { Config } from "./App";
import reportWebVitals from "./reportWebVitals";
import amplitude from "amplitude-js";
import Honeybadger from "@honeybadger-io/js";
import ErrorBoundary from "@honeybadger-io/react";
import "typeface-roboto";

if (Config.stage === "dev") {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  Honeybadger.configure({
    apiKey: Config.honeybadgerApiKey,
    environment: Config.stage,
  });

  ReactDOM.render(
    <ErrorBoundary honeybadger={Honeybadger}>
      <App />
    </ErrorBoundary>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
