import React, { useCallback, useEffect, useRef, useState } from "react";
import VideoCanvas from "./VideoCanvas";
import VideoPermalink from "./VideoPermalink";
import Grid from "@mui/material/Grid";
import {
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useEventLogger, usePageViewTracker } from "./Analytics";
import OnErrorEmailCollector from "./OnErrorEmailCollector";
import Tips from "./Tips";
import InfoPopover from "./InfoPopover";
import LiftSelector from "./LiftSelector";
import RepsItems from "./reps/RepsItems";
import { useAuthSession } from "./auth/FormguruAuth";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { drawDeadliftOverlays } from "./overlays/DeadliftOverlays";
import { drawSquatOverlays } from "./overlays/SquatOverlays";
import {
  drawBarPath,
  drawCurrentRepOverlay,
  drawSkeleton,
} from "./overlays/BaseOverlays";
import { SUPPORTED_LIFTS, BARBELL_LIFTS } from "./LiftSelector";
import { isFacingRight } from "./FormAnalyzer";
import CanvasPlaybackControls from "./CanvasPlaybackControls";
import {
  useVideoIdFromUrl,
  FetchStatus,
  isTerminalState,
  useVideoAndInferenceResults,
} from "./AnalysisLoader";
import { useFeatureFlags } from "./FeatureFlags";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}));

const getEstimatedDurationSeconds = (szBytes) => {
  const minEstimateSeconds = 90; // underpromise, over-deliver
  // Note: not scientifically chosen, just based off a handful of datapoints
  const ANALYSIS_BYTES_PER_SEC = 0.5e6;
  return Math.max(minEstimateSeconds, szBytes / ANALYSIS_BYTES_PER_SEC);
};

export default function WorkoutVideoContainer(props) {
  const [isUserSignedIn, setIsUserSignedIn] = useState(null);
  const [getCurrentUser, _] = useAuthSession(false);

  const history = useHistory();
  const logEvent = useEventLogger();
  usePageViewTracker("Video Viewer");

  const szBytes =
    new URLSearchParams(props.location.search).get("szBytes") || null;
  const etaSeconds =
    szBytes !== null ? getEstimatedDurationSeconds(szBytes) : null;
  const isNewUpload = Boolean(
    props.location.state && props.location.state["isNewUpload"]
  );

  const videoId = useVideoIdFromUrl();
  const [isPlaying, setIsPlaying] = useState(false);
  const [seekTargetMs, setSeekTargetMs] = useState(null);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [videoDurationMs, setVideoDurationMs] = useState(null);
  const isFeatureEnabled = useFeatureFlags();
  const isDebugKeypointsMode = isFeatureEnabled("debugKeypoints");

  const {
    reps,
    jTP: jointToPoints,
    liftType: detectedLiftType,
    jTPStatus: j2pStatus,
    videoSrcUri,
    videoStatus,
  } = useVideoAndInferenceResults(videoId, isNewUpload, etaSeconds);

  const classes = useStyles();
  const videoCanvasRef = useRef();

  const [displayedEta, setDisplayedEta] = useState(null);

  const [hidePopups, setHidePopups] = useState(false);
  const [selectedLiftType, setSelectedLiftType] = useState(null);

  const onLoadedMetadata = (e) => {
    setVideoDurationMs(1000 * e.target.duration);
  };

  const createTickFn = () => {
    const jTP = jointToPoints;
    const liftType = selectedLiftType;
    let isLifterFacingRight = false;
    if (jTP != null && Object.keys(jTP).length > 0) {
      isLifterFacingRight = isFacingRight(jTP);
    }

    return (uiState, ctx) => {
      const tMs = uiState.t;

      if (jTP != null && Object.keys(jTP).length > 0) {
        if (isDebugKeypointsMode) {
          const jointPairs = ["left", "right"].reduce((acc, direction) => {
            const pairsThisSide = Object.fromEntries(
              [
                ["Heel", "Ankle"],
                ["Toe", "Heel"],
                ["Ankle", "Knee"],
                ["Knee", "Hip"],
                ["Hip", "Shoulder"],
                ["Shoulder", "Elbow"],
                ["Elbow", "Wrist"],
              ].map(([a, b]) => [direction + a, direction + b])
            );
            return { ...acc, ...pairsThisSide };
          }, {});
          drawSkeleton(ctx, jTP, tMs, jointPairs);
        } else if (liftType === SUPPORTED_LIFTS.SQUAT) {
          drawSquatOverlays(
            ctx,
            jTP,
            reps,
            isLifterFacingRight,
            tMs,
            hidePopups
          );
        } else if (liftType === SUPPORTED_LIFTS.DEADLIFT) {
          drawDeadliftOverlays(ctx, jTP, isLifterFacingRight, tMs);
        } else if (!liftType) {
          const direction = isLifterFacingRight ? "right" : "left";
          const jointPairs = Object.fromEntries(
            [
              ["Ankle", "Knee"],
              ["Knee", "Hip"],
              ["Hip", "Shoulder"],
            ].map(([a, b]) => [direction + a, direction + b])
          );
          drawSkeleton(ctx, jTP, tMs, jointPairs);
        }
        if (reps) {
          drawCurrentRepOverlay(ctx, reps, tMs);
        }
        if (BARBELL_LIFTS.has(liftType) || liftType === SUPPORTED_LIFTS.OTHER) {
          drawBarPath(ctx, jTP, reps, tMs);
        }
      }

      const onUserSkippedSetup = () => {
        logEvent("user_clicked_skip_setup_button", {
          is_new_upload: isNewUpload,
        });
      };
      const onPlaybackStateChanged = ({ playbackRate, isPlaying }) => {
        setIsPlaying(isPlaying);
        setPlaybackRate(playbackRate);
      };
      CanvasPlaybackControls(
        {
          isPlaying,
          durationSec: videoDurationMs !== null ? videoDurationMs / 1000 : null,
          playbackRate,
        },
        videoCanvasRef.current.getDrawingContext(),
        reps,
        {
          onUserSkippedSetup,
          onPlaybackStateChanged,
          onSeeked: setSeekTargetMs,
        },
        uiState
      );
      if (uiState.isClicked) {
        setIsPlaying((isPlaying) => !isPlaying);
        uiState.isClicked = false;
      }
    };
  };

  const tickFn = useCallback(createTickFn(), [
    jointToPoints,
    reps,
    selectedLiftType,
    hidePopups,
    isDebugKeypointsMode,
    isPlaying,
    playbackRate,
    videoDurationMs,
  ]);

  useEffect(() => {
    setSelectedLiftType(detectedLiftType);
  }, [detectedLiftType]);

  useEffect(() => {
    getCurrentUser().then((u) => {
      setIsUserSignedIn(Boolean(u));
    });
  }, []);

  useEffect(() => {
    if (isNewUpload) {
      logEvent("video_analysis_started", {
        video_id: videoId,
        initial_eta_seconds: etaSeconds,
      });
    }
  }, []);

  useEffect(() => {
    if (szBytes !== null) {
      const formatDuration = (numSeconds) => {
        const MM = Math.floor(numSeconds / 60).toString();
        const SS = Math.floor(numSeconds % 60).toString();
        return `${MM.padStart(2, "0")}:${SS.padStart(2, "0")}`;
      };

      const eta = new Date();
      eta.setSeconds(eta.getSeconds() + etaSeconds);

      const etaTimer = setInterval(() => {
        const secondsLeft = (eta.getTime() - new Date().getTime()) / 1000;
        if (secondsLeft <= 0) {
          setDisplayedEta("Almost done...");
          clearInterval(etaTimer);
        } else {
          setDisplayedEta(`${formatDuration(secondsLeft)} remaining`);
        }
      }, 1000);

      return () => {
        clearInterval(etaTimer);
      };
    }
  }, []);

  const showError = (title, subtitle, bodyElement) => {
    return (
      <React.Fragment>
        <Grid item>
          <Typography variant="h1">{title}</Typography>
          <Typography variant="h4">{subtitle}</Typography>
          <Tips />
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>{bodyElement}</Paper>
        </Grid>
      </React.Fragment>
    );
  };

  const renderVideo = () => {
    const noVideoSelected = (
      <div>
        <h1>No video selected</h1>
        <Button
          color="secondary"
          fullWidth
          type="submit"
          variant="contained"
          onClick={(e) => history.push("/upload")}
        >
          Upload a video
        </Button>
      </div>
    );

    const analyzingVideo = (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">Analyzing your lift...</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">ETA: {displayedEta}</Typography>
          <LinearProgress color="secondary" />
        </Grid>
      </React.Fragment>
    );

    const poorDetectionResults = showError(
      "Uh-oh",
      "Formguru is struggling with this one",
      <React.Fragment>
        <Typography variant="h6">Tips for a good video:</Typography>
        <Typography variant="body2">
          <ul>
            <li>Film directly from the side</li>
            <li>
              Keep the plates in frame throughout your full range-of-motion
            </li>
            <li>
              Trim your video before uploading (don't include your setup and
              teardown)
            </li>
          </ul>
        </Typography>
        <Typography variant="paragraph">
          If you followed the tips above and are still having trouble, let us
          know below. We're looking closely at every failed video.
        </Typography>
        {OnErrorEmailCollector(videoId)}
      </React.Fragment>
    );

    const failedToLoad = showError(
      "Uh-oh",
      "We can't load this video (yet!), but we're reviewing every failed upload.",
      OnErrorEmailCollector(videoId)
    );

    const notFound = showError(
      "Uh-oh",
      "We couldn't find that video. But we're looking closely at every failed video.",
      OnErrorEmailCollector(videoId)
    );

    const getStatus = () => {
      // the video has to load before we can do anything
      if (videoStatus !== FetchStatus.SUCCESS) {
        return videoStatus;
      }
      const isInferenceDone = isTerminalState(j2pStatus);
      const gotPose =
        jointToPoints !== null &&
        ["leftKnee", "rightKnee"].some((x) => x in jointToPoints);
      const gotBarpath =
        jointToPoints !== null && "platesCenter" in jointToPoints;
      const atLeastOneInferenceSucceeded = gotBarpath || gotPose;
      if (isInferenceDone && atLeastOneInferenceSucceeded) {
        return FetchStatus.SUCCESS;
      } else if (isInferenceDone) {
        return FetchStatus.UNKNOWN_FAILURE;
      } else {
        return FetchStatus.ANALYZING;
      }
    };

    if (!videoId) {
      return noVideoSelected;
    } else {
      switch (getStatus()) {
        case FetchStatus.INITIALIZING:
          return analyzingVideo;
        case FetchStatus.ANALYZING:
          return analyzingVideo;
        case FetchStatus.NOT_FOUND:
          return notFound;
        case FetchStatus.SUCCESS:
          return (
            <div className="App">
              <VideoCanvas
                ref={videoCanvasRef}
                isPlaying={isPlaying}
                seekTargetMs={seekTargetMs}
                playbackRate={playbackRate}
                tickFn={tickFn}
                selectedLiftType={selectedLiftType}
                currentVideo={videoSrcUri}
                videoId={videoId}
                isNewUpload={isNewUpload}
                onLoadedMetadata={onLoadedMetadata}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hidePopups}
                    onChange={() => setHidePopups(!hidePopups)}
                    name="hide-popups"
                  />
                }
                label="Hide Popups"
              />
            </div>
          );
        case FetchStatus.POOR_DETECTION_RESULTS:
          return poorDetectionResults;
        case FetchStatus.UNKNOWN_FAILURE:
          return failedToLoad;
        default:
          console.error("Got unknown Status");
      }
    }
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const renderAnalysis = () => {
    const initializingAnalysis = (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">Breaking down your reps...</Typography>
        </Grid>
        <Grid item>
          <LinearProgress color="secondary" />
        </Grid>
      </React.Fragment>
    );

    const successfulAnalysis = (
      <div>
        <Typography variant="h4">Rep Breakdown</Typography>
        <LiftSelector
          detectedLiftType={detectedLiftType}
          onSelectedLiftChanged={onSelectedLiftChanged}
        />
        <InfoPopover textLabel="Analysis looks off?">
          <Tips />
        </InfoPopover>
        <RepsItems
          reps={reps}
          onClickRepStartTimestampMs={setSeekTargetMs}
          isLiftTypeOverriden={detectedLiftType !== selectedLiftType}
        />
      </div>
    );

    const failedAnalysis = (
      <React.Fragment>
        {showError(
          "Uh-oh",
          "We failed to analyze the reps on this video.",
          OnErrorEmailCollector(videoId)
        )}
      </React.Fragment>
    );

    switch (j2pStatus) {
      case FetchStatus.ANALYZING:
        return initializingAnalysis;
      case FetchStatus.SUCCESS:
        return reps ? successfulAnalysis : failedAnalysis;
      case FetchStatus.POOR_DETECTION_RESULTS:
      case FetchStatus.UNKNOWN_FAILURE:
        return failedAnalysis;
    }
  };

  const onSelectedLiftChanged = (liftType) => {
    setSelectedLiftType((prevLiftType) => {
      logEvent("user_changed_lift_type", {
        old_lift: prevLiftType,
        new_lift: liftType,
      });
      return liftType;
    });
  };

  return (
    <div>
      <Grid container justifyContent="center" alignContent="center" spacing={2}>
        <Grid item style={{ padding: "20px" }} xs={12} sm={12} md={6}>
          {renderVideo()}
        </Grid>
        <Grid container style={{ padding: "20px" }} xs={12} sm={12} md={6}>
          {renderAnalysis()}
          <Grid item justifyContent="center" xs={12}>
            <Box mt={3}>
              {!isUserSignedIn && (
                <Paper className={classes.paper}>
                  <Typography style={{ fontWeight: 600 }}>
                    Want to save uploads and track your progress?
                  </Typography>
                  <Typography style={{ fontSize: ".8rem" }}>
                    Join the Formguru Beta (free for a limited time)!
                  </Typography>
                  <Button
                    style={{ margin: "4px" }}
                    color="secondary"
                    variant="contained"
                    onClick={(_e) => {
                      logEvent("user_clicked_learn_more_about_beta");
                      history.push("/signup");
                    }}
                  >
                    Learn More
                  </Button>
                </Paper>
              )}
            </Box>
          </Grid>
          <Grid item justifyContent="center" xs={6} sm={6}>
            <Box ml={3} mr={3} mt={1} mb={1}>
              <VideoPermalink videoId={videoId} />
            </Box>
          </Grid>
          <Grid item justifyContent="center" xs={6} sm={6}>
            <Box ml={3} mr={3} mt={1} mb={1}>
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
                onClick={(e) => history.push("/upload")}
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>
            </Box>
          </Grid>
          <Grid item justifyContent="center" xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="outlined"
              onClick={(e) => history.push("/poll")}
            >
              Vote on Formguru's next feature
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
