import {
  drawAngle,
  drawLine,
  drawCircle,
  drawText,
  getAngle,
  interpolateColor,
} from "../Draw.js";
import { drawSkeleton, LIGHT_GREEN } from "./BaseOverlays";
import { drawPopups } from "./Popups.js";
import { clamp, distance2D } from "../Math.js";

export const drawSquatOverlays = (
  ctx,
  jTP,
  reps,
  isLifterFacingRight,
  tMs,
  hidePopups
) => {
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const direction = isLifterFacingRight ? "right" : "left";
  const jointPairs = Object.fromEntries(
    [
      ["Ankle", "Knee"],
      ["Knee", "Hip"],
      ["Hip", "Shoulder"],
    ].map(([a, b]) => [direction + a, direction + b])
  );
  drawSkeleton(ctx, jTP, tMs, jointPairs);

  const positionOf = (joint) => {
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const p = jTP.positionAt(`${direction}${capitalize(joint)}`, tMs);
    if (p) {
      return { x: p.x * width, y: p.y * height };
    }
    return null;
  };
  const ankle = positionOf("ankle");
  const knee = positionOf("knee");
  const hip = positionOf("hip");
  const shoulder = positionOf("shoulder");

  if (!(ankle && knee && hip)) {
    return;
  }

  if (!hidePopups) {
    drawPopups(
      ctx,
      reps,
      { ankle: ankle, knee: knee, hip: hip, shoulder: shoulder },
      tMs,
      width,
      height
    );
  }

  // TODO: we should probably extract this out into a top-level options
  // field so it's easy to adjust on the fly for each video
  const midFootOffset = isLifterFacingRight ? 15 : -15;
  const midFootX = ankle.x + midFootOffset;
  drawLine(
    ctx,
    { position: { x: midFootX, y: 0 } },
    { position: { x: midFootX, y: height } },
    {
      color: LIGHT_GREEN,
      alpha: 0.5,
      isDashed: true,
    }
  );

  const hipKneeYDistance = Math.abs(knee.y - hip.y);
  const hipKneeDistance = distance2D(knee, hip);

  // theta is the angle formed by the horizontal plane and the
  // knee-hip connector
  const sinTheta = hipKneeYDistance / hipKneeDistance;

  const drawProgress = (ctx, radius, options = {}) => {
    let { color = "white" } = options;

    const curRep = reps.find(
      (r) => tMs >= r.startTimestampMs && tMs <= r.endTimestampMs
    );

    if (!curRep) {
      return;
    }
    const toAbs = (p) => {
      if (p) {
        return { x: p.x * width, y: p.y * height };
      }
      return null;
    };
    const hipAtStart = toAbs(
      jTP.positionAt(`${direction}Hip`, curRep.startTimestampMs)
    );
    const hipAtEnd = toAbs(
      jTP.positionAt(`${direction}Hip`, curRep.midTimestampMs)
    );
    const kneeAtEnd = toAbs(
      jTP.positionAt(`${direction}Knee`, curRep.midTimestampMs)
    );
    const hipTarget = { x: hipAtEnd.x, y: kneeAtEnd.y };
    if (!(hipAtStart && hipAtEnd && kneeAtEnd)) {
      return;
    }

    const angleAtEnd = getAngle(hipAtEnd, kneeAtEnd, hipTarget);
    const isRepGood = Math.round(angleAtEnd) === 0 || hipAtEnd.y >= hipTarget.y;
    const fadeOutProgress = Math.max(
      0,
      (tMs - curRep.midTimestampMs) /
        (curRep.endTimestampMs - curRep.midTimestampMs)
    );

    const drawTriangle = (hip, knee, color) => {
      // draw triangle
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.moveTo(hip.x, hip.y);
      ctx.lineTo(knee.x, knee.y);
      ctx.lineTo(hip.x, knee.y);
      ctx.fill();

      drawLine(
        ctx,
        { position: hip },
        { position: { x: hip.x, y: knee.y } },
        { width: 2, color: "white", alpha: 1 - fadeOutProgress }
      );
      // draw horizontal anchor line
      drawLine(
        ctx,
        { position: { x: hip.x, y: knee.y } },
        { position: knee },
        { width: 2, color: "white", isDashed: true, alpha: 1 - fadeOutProgress }
      );

      drawAngle(
        ctx,
        { position: hip },
        { position: knee },
        { position: { x: hip.x, y: knee.y } },
        {
          radius: Math.abs(hip.x - knee.x) * 0.2,
          alpha: 0.5,
          includeTextLabel: false,
        }
      );
    };

    const getCirclePosition = () => {
      const _knee = tMs < curRep.midTimestampMs ? knee : kneeAtEnd;
      const offset = 1.1 * radius;
      const xOffsetDir = isLifterFacingRight ? 1 : -1;
      const labelX = _knee.x + offset * xOffsetDir;
      const labelY = _knee.y - offset;
      return { x: labelX, y: labelY };
    };

    const { x, y } = getCirclePosition();
    var progressFrac;
    if (tMs < curRep.midTimestampMs) {
      progressFrac = (hip.y - hipAtStart.y) / (hipTarget.y - hipAtStart.y);
      if (progressFrac < 0.05) {
        progressFrac = 0; // don't start showing the gauge until the rep has clearly started
      }
      const GRAY = "#d1d1d1";
      const GRAY_GREEN = "#9fcca1";
      const _color = interpolateColor(GRAY, GRAY_GREEN, progressFrac);
      drawCircle(ctx, x, y, radius, { color: _color, alpha: progressFrac });
    } else {
      progressFrac = (hipAtEnd.y - hipAtStart.y) / (hipTarget.y - hipAtStart.y);
      drawCircle(ctx, x, y, radius, {
        color: isRepGood ? "green" : "red",
        alpha: 1 - fadeOutProgress,
      });

      if (isRepGood) {
        // draw check mark
        drawLine(
          ctx,
          { position: { x: x - 0.7 * radius, y: y + 0.1 * radius } },
          { position: { x: x - 0.3 * radius, y: y + 0.75 * radius } },
          { color: "white", width: 5, alpha: 1 - fadeOutProgress }
        );
        drawLine(
          ctx,
          { position: { x: x - 0.3 * radius, y: y + 0.75 * radius } },
          { position: { x: x + 0.6 * radius, y: y - 0.6 * radius } },
          { color: "white", width: 5, alpha: 1 - fadeOutProgress }
        );
      } else {
        // inscribe the numeric value
        var prefix;
        if (angleAtEnd === 0) {
          prefix = "";
        } else {
          prefix = isRepGood ? "-" : "+";
        }

        ctx.font = "18px Roboto";
        ctx.fillStyle = "white";
        ctx.globalAlpha = 1 - fadeOutProgress;
        ctx.fillText(`${prefix}${angleAtEnd.toString()}°`, x, y);
      }
    }

    // draw the progress ring around the circle
    ctx.save();
    ctx.globalAlpha = 1 - fadeOutProgress;
    ctx.beginPath();
    const radiansOffset = 1.5 * Math.PI;
    ctx.arc(
      x,
      y,
      radius,
      radiansOffset,
      radiansOffset + 2 * Math.PI * progressFrac
    );
    ctx.strokeStyle = color;
    ctx.lineWidth = 5;
    ctx.stroke();

    ctx.globalAlpha = Math.min(0.3, 1 - fadeOutProgress);

    if (tMs < curRep.midTimestampMs || !isRepGood) {
      drawTriangle(
        tMs < curRep.midTimestampMs ? hip : hipAtEnd,
        tMs < curRep.midTimestampMs ? knee : kneeAtEnd,
        tMs < curRep.midTimestampMs ? "white" : isRepGood ? "green" : "red"
      );
    }
    ctx.restore();
  };

  drawProgress(ctx, 20);

  if (shoulder && hip && ankle && knee) {
    const alpha = Math.max(0.5, 1 - sinTheta < 0.2 ? 0 : 1 - sinTheta);
    drawBackAngle(ctx, shoulder, hip, knee, ankle, alpha, tMs);
  }
};

const drawBackAngle = (ctx, shoulder, hip, knee, ankle, alpha, tMs) => {
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const backAngleSlope = (shoulder.y - hip.y) / (shoulder.x - hip.x);

  // x-offset from the hip for the horizontal line at the knee
  const xOffsetKneeLine = (knee.y - hip.y) / backAngleSlope;
  // horizontal line centered at knee
  drawLine(
    ctx,
    { position: { x: knee.x, y: knee.y } },
    { position: { x: hip.x + xOffsetKneeLine, y: knee.y } },
    {
      isDashed: true,
      alpha: alpha,
      color: "white",
    }
  );

  // x-offset from the hip for the horizontal line at the ankle
  const xOffsetAnkleLine = (ankle.y - hip.y) / backAngleSlope;

  // back angle extended to horizontal line centered at ankle
  drawLine(
    ctx,
    { position: { x: hip.x + xOffsetAnkleLine, y: ankle.y } },
    { position: { x: hip.x, y: hip.y } },
    {
      isDashed: true,
      alpha: alpha,
      color: "white",
    }
  );
  // horizontal line centered at ankle
  drawLine(
    ctx,
    { position: { x: ankle.x, y: ankle.y } },
    { position: { x: hip.x + xOffsetAnkleLine, y: ankle.y } },
    {
      isDashed: true,
      alpha: alpha,
      color: "white",
    }
  );

  drawAngle(
    ctx,
    { position: hip },
    { position: { x: hip.x + xOffsetAnkleLine, y: ankle.y } },
    { position: ankle },
    {
      radius: Math.abs(ankle.x - (hip.x + xOffsetAnkleLine)) * 0.25,
      alpha: 0.5,
      includeTextLabel: true,
    }
  );

  const textLabelX = clamp(hip.x + 1.05 * xOffsetAnkleLine, 0, 0.9 * width);
  const textLabelY = clamp(ankle.y - 10, 0, 0.9 * height);

  drawText(ctx, "Back Angle", textLabelX, textLabelY, width, {
    alpha: startOfVideoAlpha(tMs),
  });
};

const startOfVideoAlpha = (tMs) => {
  //TODO: Make this more intelligent, either a fraction of the video or the start of the first rep
  const END_OF_START_SEC = 3;
  if (tMs < END_OF_START_SEC) {
    return 1;
  } else if (tMs < END_OF_START_SEC + 1) {
    return END_OF_START_SEC + 1 - tMs;
  } else {
    return 0;
  }
};
