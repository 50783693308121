import React from "react";
import Carousel from "react-material-ui-carousel";
import upload_to_formguru_img from "./upload.png";
import feedback_on_every_rep_img from "./feedback_on_every_rep.png";

export default function HomepageCarousel(props) {
  var items = [
    {
      imgSrc: upload_to_formguru_img,
      scalingFactor: props.scalingFactor,
    },
    {
      imgSrc: feedback_on_every_rep_img,
      scalingFactor: props.scalingFactor,
    },
  ];

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  var scalingFactor = props.item.scalingFactor ? props.item.scalingFactor : 1.0;
  return (
    <img
      src={props.item.imgSrc}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  );
}
