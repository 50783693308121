import React from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import axios from "axios";
import Honeybadger from "@honeybadger-io/js";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import { Config } from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function FeaturesPoll() {
  const classes = useStyles();
  const [selections, setSelections] = React.useState({
    benchPress: { label: "Bench press support", checked: false },
    cleanJerk: { label: "Clean jerk support", checked: false },
    bodyWeightSquat: { label: "Body weight squat support", checked: false },
    pushUps: { label: "Push up support", checked: false },
    tracker: { label: "Track form over time", checked: false },
    deadliftLockout: { label: "Deadlift lockout analysis", checked: false },
    deadliftKneePos: {
      label: "Deadlift knee/bar position analysis",
      checked: false,
    },
  });

  const [isSelectionsSubmitted, setIsSelectionsSubmitted] =
    React.useState(false);

  const formguruClient = axios.create({
    baseURL: Config.apiEndpoint,
  });

  const handleChange = (event) => {
    const key = event.target.name;
    setSelections({
      ...selections,
      [key]: { ...selections[key], checked: true },
    });
  };

  const submitSelections = async () => {
    const selected = Object.keys(selections)
      .map((key) => selections[key])
      .filter((selection) => selection.checked)
      .map((selection) => selection.label);

    if (selected) {
      try {
        await formguruClient.post("/features_poll", { selections: selected });
      } catch (error) {
        Honeybadger.notify(error);
        return;
      }
    }

    setIsSelectionsSubmitted(true);
  };

  const submitSelectionsButton = (
    <Button
      variant="contained"
      size="large"
      color="primary"
      onClick={submitSelections}
    >
      Submit
    </Button>
  );

  const successfullySubmittedText = (
    <Typography variant="h5" component="h5">
      Sent ✅
    </Typography>
  );

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const formControlLabels = () => {
    return Object.keys(selections).map((key) => (
      <FormControlLabel
        control={
          <Checkbox
            checked={selections[key].checked}
            onChange={handleChange}
            name={key}
          />
        }
        label={selections[key].label}
      />
    ));
  };

  return (
    <div>
      <Grid container justifyContent="center" alignContent="center" spacing={2}>
        >
        <CssBaseline />
        <Grid item align="center">
          <div className={classes.paper}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Box fontWeight="fontWeightBold" m={1}>
                  <Typography variant="h2">
                    What features would you like to see next?
                  </Typography>
                </Box>
              </ThemeProvider>
            </StyledEngineProvider>
          </div>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>{formControlLabels()}</FormGroup>
            <FormHelperText>Select up to 3 features</FormHelperText>
          </FormControl>
          <Grid item xs={false} sm={4} md={7} align="center">
            {isSelectionsSubmitted
              ? successfullySubmittedText
              : submitSelectionsButton}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
