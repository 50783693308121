import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import { Config } from "../App";

let _AMP = null;

const getAmplitude = () => {
  if (_AMP === null) {
    // See https://developers.amplitude.com/docs/web-attribution
    const amplitudeOptions = {
      includeReferrer: true,
      includeUtm: true,
      includeFbclid: true,
      includeGclid: true,
    };
    _AMP = amplitude.getInstance();
    _AMP.init(Config.amplitudeApiKey, null, amplitudeOptions);
  }
  return _AMP;
};

export const usePageViewTracker = (pageTitle) => {
  const location = useLocation();
  const gtag = window.gtag;

  const logPageView = () => {
    const currentPath = location.pathname + location.search;
    gtag("event", "page_view", {
      page_title: pageTitle,
      page_location: currentPath,
    });
    getAmplitude().logEvent("user_viewed_page", {
      page_title: pageTitle,
    });
  };

  useEffect(() => {
    logPageView();
  }, [location]);
};

export const useEventLogger = () => {
  const gtag = window.gtag;
  return (ev_name, ev_params) => {
    getAmplitude().logEvent(ev_name, ev_params);
    gtag("event", ev_name, ev_params);
  };
};

export const useUserPropertyLogger = () => {
  const increment = (k, n) => {
    const identify = new amplitude.Identify().add(k, n);
    getAmplitude().identify(identify);
  };
  const setProperty = (k, v) => {
    const identify = new amplitude.Identify().set(k, v);
    getAmplitude().identify(identify);
  };
  return {
    incrementUserProperty: increment,
    setUserProperty: setProperty,
  };
};
