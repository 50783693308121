export const createButton = ({ canvas, x, y, width, height, ...rest }) => {
  const btn = {
    x: x,
    y: y,
    width: width,
    height: height,
    ...rest,
  };

  const isMouseOver = (mouseX, mouseY) => {
    const w = canvas.width;
    const h = canvas.height;

    const buttonX = btn.x * w;
    const buttonY = btn.y * h;
    const buttonWidth = btn.width * w;
    const buttonHeight = btn.height * h;

    if (
      mouseX >= buttonX &&
      mouseX <= buttonX + buttonWidth &&
      mouseY >= buttonY &&
      mouseY <= buttonY + buttonHeight
    ) {
      return true;
    }
    return false;
  };

  btn.isMouseOver = isMouseOver;

  return btn;
};
