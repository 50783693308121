import { drawAngle, drawCircle, drawLine } from "../Draw";
import { drawSkeleton, LIGHT_GREEN } from "./BaseOverlays";

export const drawDeadliftOverlays = (ctx, jTP, isLifterFacingRight, t) => {
  const width = ctx.canvas.width;
  const height = ctx.canvas.height;
  const direction = isLifterFacingRight ? "right" : "left";
  const jointPairs = Object.fromEntries(
    [
      ["Ankle", "Knee"],
      ["Knee", "Hip"],
      ["Hip", "Shoulder"],
      ["Shoulder", "Elbow"],
      ["Elbow", "Wrist"],
    ].map(([a, b]) => [direction + a, direction + b])
  );
  drawSkeleton(ctx, jTP, t, jointPairs);

  const positionOf = (joint) => {
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const p = jTP.positionAt(`${direction}${capitalize(joint)}`, t);
    if (p) {
      return { x: p.x * width, y: p.y * height };
    }
    return null;
  };

  const ankle = positionOf("ankle");
  const knee = positionOf("knee");
  const hip = positionOf("hip");
  const shoulder = positionOf("shoulder");
  const elbow = positionOf("elbow");
  const wrist = positionOf("wrist");

  if (!(ankle && knee && hip && shoulder)) {
    return;
  }

  // TODO: we should probably extract this out into a top-level options
  // field so it's easy to adjust on the fly for each video
  const midFootOffset = isLifterFacingRight ? 15 : -15;
  const midFootX = ankle.x + midFootOffset;

  // vertical mid-foot line
  drawLine(
    ctx,
    { position: { x: midFootX, y: 0 } },
    { position: { x: midFootX, y: height } },
    {
      color: LIGHT_GREEN,
      alpha: 0.5,
      isDashed: true,
    }
  );

  // horizontal reference line
  drawLine(
    ctx,
    { position: { x: hip.x, y: hip.y } },
    { position: { x: midFootX, y: hip.y } },
    {
      color: "yellow",
      isDashed: true,
      alpha: 0.5,
    }
  );

  drawAngle(
    ctx,
    { position: shoulder },
    { position: hip },
    { position: { x: midFootX, y: hip.y } },
    {
      radius: Math.abs(hip.x - midFootX) / 2,
      color: "yellow",
      alpha: 0.5,
      includeTextLabel: true,
      backgroundColor: "blue",
    }
  );

  if (elbow !== null && wrist !== null) {
    drawLine(
      ctx,
      { position: { x: elbow.x, y: elbow.y } },
      { position: { x: wrist.x, y: wrist.y } },
      {
        color: "white",
        alpha: 0.5,
      }
    );
    drawCircle(ctx, elbow.x, elbow.y, 4, {
      color: "blue",
      alpha: 1.0,
    });
    drawCircle(ctx, wrist.x, wrist.y, 6, {
      color: LIGHT_GREEN,
      isFilled: false,
      alpha: 1.0,
    });
  }
};
