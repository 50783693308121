import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import WorkoutVideosFeed from "../WorkoutVideosFeed";
import { Config } from "../../../App";
import { usePageViewTracker } from "../../Analytics";
import { useAuth } from "../../auth/GuruAuth";
import FeedFailedToLoad from "../FeedFailedToLoad";
import WorkoutVideoUploader from "../../WorkoutVideoUploader";
import CenteredBox from "../../CenteredBox";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation } from "react-router-dom";
import { fmtDate } from "../FormatDate";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    margin: `${theme.spacing(3)} auto`,
    display: "block",
    width: "fit-content",
  },
  contentBox: {
    "& *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function AthletePortal() {
  const classes = useStyles();

  usePageViewTracker("Athlete Portal");
  const { state } = useLocation();
  const isNewUser = state && state.isNewUser;

  const { getToken } = useAuth();
  const [uploads, setUploads] = useState(null);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [isShowingUploadSucceeded, setIsShowingUploadSucceeded] =
    useState(false);
  const [uploadsCursor, setUploadsCursor] = useState(null);
  const [didFailToLoad, setDidFailToLoad] = useState(false);
  const { user } = useAuth();
  const currentUserId = user && user.sub;

  const apiClient = axios.create({
    baseURL: Config.apiEndpoint,
  });

  useEffect(() => {
    fetchMoreUploads();
  }, []);

  const fetchMoreUploads = async () => {
    const token = await getToken();
    const LIMIT = 8;
    const args = {
      headers: { Authorization: `Bearer ${token}` },
      params: { limit: LIMIT },
    };
    if (uploadsCursor) {
      args["params"]["next"] = uploadsCursor;
    }
    let response;
    try {
      response = await apiClient.get("/uploads", args);
    } catch (err) {
      setUploadsCursor(null);
      setDidFailToLoad(true);
      throw err;
    }

    const uploadsResponseData = await response.data;

    const nextPage = uploadsResponseData.uploads.map((x) => {
      const {
        video_id: videoId,
        uploaded_by: { user_id: uploadedByUserId, display_name: displayName },
        uploaded_at: uploadedAt,
        is_reviewed: isReviewed,
      } = x;
      const badgeIcon = isReviewed ? (
        <AssignmentTurnedInIcon style={{ color: "green" }} />
      ) : (
        <PendingActionsIcon style={{ color: "gray" }} />
      );
      const badge = (
        <Tooltip title={isReviewed ? "Reviewed" : "Pending Review"}>
          {badgeIcon}
        </Tooltip>
      );

      const uploadedByCurrentUser = currentUserId === uploadedByUserId;
      const subheader = uploadedByCurrentUser
        ? fmtDate(new Date(uploadedAt))
        : `${displayName} on ${fmtDate(new Date(uploadedAt))}`;
      const linkTarget = uploadedByCurrentUser
        ? `/video/${videoId}`
        : `coach-demo/${videoId}`;

      return {
        videoId,
        subheader,
        linkTarget,
        badge,
      };
    });

    setUploads((oldVal) => {
      const existing = oldVal ? [...oldVal] : [];
      existing.push(...nextPage);
      return existing;
    });
    const nextToken = await response.data["next"];
    setUploadsCursor(nextToken || null);
  };

  const onUploadFinished = () => {
    setIsShowingUploadSucceeded(true);
    setUploads(null);
    fetchMoreUploads();
  };

  const getUploads = () => {
    if (didFailToLoad) {
      return (
        <CenteredBox>
          <FeedFailedToLoad />
        </CenteredBox>
      );
    } else if (uploads === null) {
      return (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      );
    } else if (uploads && uploads.length === 0) {
      return (
        <CenteredBox className={classes.contentBox}>
          {isNewUser && (
            <>
              <Typography component="h2" variant="h2">
                🎉 Welcome to Guru! 🎉
              </Typography>
            </>
          )}
          {!isUploadInProgress && (
            <>
              <Typography component="h2" variant="h4">
                You haven't uploaded any videos yet
              </Typography>
              <Typography variant="subtitle1">
                Let's fix that, shall we?
              </Typography>
            </>
          )}
          <WorkoutVideoUploader
            buttonText="Upload"
            onUploadStarted={() => setIsUploadInProgress(true)}
            onUploadFinished={onUploadFinished}
            isUsingAuth0={true}
            source="getguru.fitness"
            className={classes.uploadButton}
          />
        </CenteredBox>
      );
    } else {
      const seen = {};
      const dedupedUploads = [];
      uploads.forEach((upload) => {
        const { videoId } = upload;
        if (!(videoId in seen)) {
          dedupedUploads.push(upload);
        }
        seen[videoId] = upload;
      });

      return (
        <>
          {isShowingUploadSucceeded && (
            <Alert
              onClose={() => {
                setIsShowingUploadSucceeded(false);
              }}
              severity="success"
            >
              <AlertTitle>Upload succeeded</AlertTitle>
              Your coach will review your upload soon.
            </Alert>
          )}
          <WorkoutVideoUploader
            buttonText="Upload New Video"
            onUploadStarted={() => setIsUploadInProgress(true)}
            onUploadFinished={onUploadFinished}
            isUsingAuth0={true}
            source="getguru.fitness"
            className={classes.uploadButton}
          />
          <WorkoutVideosFeed
            uploads={dedupedUploads}
            fetchMoreUploads={fetchMoreUploads}
            uploadsCursor={uploadsCursor}
          />
        </>
      );
    }
  };

  return <Container>{getUploads()}</Container>;
}
