export const fmtDate = (d) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const month = months[d.getMonth()];
  const day = days[d.getDay()];
  const dayOfMonth = d.getDate();
  return `${day}, ${month} ${dayOfMonth}`;
};
