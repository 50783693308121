import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import StickyFooter from "./StickyFooter";
import { useHistory } from "react-router-dom";
import { useEventLogger, usePageViewTracker } from "./Analytics";

const useStyles = makeStyles((theme) => ({
  mainText: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  slimCard: {
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

export default function SignUpPage() {
  const classes = useStyles();

  usePageViewTracker("Sign Up");
  const history = useHistory();
  const logEvent = useEventLogger();

  return (
    <React.Fragment>
      <Box padding={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1">
              Join the Beta!
            </Typography>
            <Typography component="div" className={classes.mainText}>
              <p>
                As a Formguru early adopter, you're eligible to register for a
                (free!) spot in our Beta program, no credit card required.{" "}
              </p>

              <p>
                Beta participants get exclusive access to all of Formguru's
                capabilities, including:
              </p>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <Typography variant="h5" component="h2">
                <TimelapseIcon /> &nbsp; Full workout history
              </Typography>
              <CardContent className={classes.slimCard}>
                <Typography>
                  All of your videos are saved in the cloud. Re-watch your old
                  workouts and track your progress over time.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <Typography variant="h5" component="h2">
                <NewReleasesIcon /> &nbsp; Early access
              </Typography>
              <CardContent>
                <Typography>
                  Beta participants get an early look at Formguru's newest
                  features (Coming soon: barpath velocity analysis!)
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <Typography variant="h5" component="h2">
                <LockOpenIcon /> &nbsp; Premium analysis
              </Typography>
              <CardContent>
                <Typography>
                  We plan to offer premium features available to paid
                  subscribers. As a Beta participant, you'll get access to all
                  premium features.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <StickyFooter>
        <Button
          color="primary"
          variant="contained"
          onClick={(_e) => {
            logEvent("user_clicked_enroll_in_beta");
            history.push("/login", { isSignUp: true });
          }}
        >
          Enroll in Beta
        </Button>
      </StickyFooter>
    </React.Fragment>
  );
}
