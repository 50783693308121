import React from "react";
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import createHistoryWithDeeplinks from "./History";
import "./App.css";
import ContactUs from "./components/ContactUs";
import WorkoutVideoUploader from "./components/WorkoutVideoUploader";
import FormguruAuth from "./components/auth/FormguruAuth";
import CssBaseline from "@mui/material/CssBaseline";
import Loading from "./components/Loading";
import FormguruAppBar from "./components/FormguruAppBar";
import WorkoutVideoContainer from "./components/WorkoutVideoContainer";
import EmbeddedVideoContainer from "./components/EmbeddedVideoContainer";
import FeaturesPoll from "./components/FeaturesPoll";
import { Amplify } from "aws-amplify";
import { isBrowser } from "react-device-detect";
import MobileHomepage from "./components/homepage/MobileHomepage";
import DesktopHomepage from "./components/homepage/DesktopHomepage";
import {
  FormguruPrivacyPolicy,
  GuruPrivacyPolicy,
} from "./components/PrivacyPolicy";
import ApiDocs from "./components/ApiDocs";
import AccountOverview from "./components/AccountOverview";
import LatestFeatures from "./components/LatestFeatures";
import SignUpPage from "./components/SignUpPage";
import {
  FormguruTermsAndConditions,
  GuruTermsAndConditions,
} from "./components/TermsAndConditions";
import PersonalDashboard from "./components/feed/PersonalDashboard";
import PublicFeed from "./components/feed/PublicFeed";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Auth0ProviderWithHistory,
  ProtectedRoute,
} from "./components/auth/GuruAuth";
import GuruAuthTest from "./components/homepage/GuruAuthTest";
import GuruAppBar from "./components/GuruAppBar";
import GuruGlobalStyles from "./components/GuruGlobalStyles";
import AthletePortal from "./components/feed/guru/AthletePortal";
import AthleteSignup from "./components/AthleteSignup";
import NotFound from "./components/NotFound";
import OpsPortalAppBar from "./components/ops_portal/OpsPortalAppBar";
import AnnotationTaskViewer from "./components/ops_portal/AnnotationTaskViewer";
import AnnotationTasks from "./components/ops_portal/AnnotationTasks";
import AnnotationBatches from "./components/ops_portal/AnnotationBatches";
import CadooDashboard from "./components/ops_portal/CadooDashboard";
import DatasetsBrowser, {
  DatasetTaskViewer,
} from "./components/ops_portal/DatasetsBrowser";
import ScrapingHub from "./components/ops_portal/Scraping";
import { VideoBrowser } from "./components/ops_portal/VideoBrowser";
import VideoAnnotator from "./components/ops_portal/VideoAnnotator";
import { Typography } from "@mui/material";
import CenteredBox from "./components/CenteredBox";
import { VideoPlayerDemo } from "./components/video_player/VideoPlayerDemo";

const STAGE = process.env.REACT_APP_STAGE;

const formguruTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      helper: {
        opacity: ".60",
        fontSize: ".8rem",
      },
    },
  })
);

const guruTheme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
    },
    typography: {
      helper: {
        opacity: ".60",
        fontSize: ".8rem",
      },
    },
  })
);

const OpsPortalApp = () => {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return <Loading message="Loading..." />;
  }

  if (error) {
    return (
      <CenteredBox>
        <Typography variant="h1">Access Denied</Typography>
        <Typography variant="body">Details: {error.message}</Typography>
      </CenteredBox>
    );
  }

  const ProtectedEntrypoint = withAuthenticationRequired(Route, {
    onRedirecting: () => <Loading message="Redirecting to login..." />,
  });

  return (
    <ProtectedEntrypoint path="/">
      <OpsPortalAppBar />
      <Switch>
        <Route exact path="/" component={VideoBrowser} />
        <Route exact path="/video/:id" component={VideoAnnotator} />
        <Route exact path="/tasks" component={AnnotationTasks} />
        <Route exact path="/tasks/:id" component={AnnotationTaskViewer} />
        <Route exact path="/batches" component={AnnotationBatches} />
        <Route exact path="/datasets" component={DatasetsBrowser} />
        <Route exact path="/datasets/:id" component={DatasetTaskViewer} />
        <Route exact path="/scraping" component={ScrapingHub} />
        <Route exact path="/dashboards/cadoo" component={CadooDashboard} />
        <Route exact path="/player" component={VideoPlayerDemo} />
        <Route component={NotFound} />
      </Switch>
    </ProtectedEntrypoint>
  );
};

const GuruApp = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Route path="/">
      <GuruAppBar />
      <Switch>
        <Route exact path="/invite" component={AthleteSignup} />
        <ProtectedRoute exact path="/" component={AthletePortal} />
        <ProtectedRoute exact path="/auth-test" component={GuruAuthTest} />
        <ProtectedRoute
          exact
          path="/upload"
          component={() => <WorkoutVideoUploader source="getguru.fitness" />}
        />

        <Route path="/contact" component={ContactUs} exact />
        <Route exact path="/terms" component={GuruTermsAndConditions} />
        <Route exact path="/privacy" component={GuruPrivacyPolicy} />
        <Route exact path="/docs/api/v1" component={ApiDocs} />
        <Route component={NotFound} />
      </Switch>
    </Route>
  );
};

export default function App() {
  // We have to create our own history object [1] so that we can reference it
  // from inside onRedirectCallback.
  // [1] https://github.com/auth0/auth0-react/blob/4aa8e474189c5b3433e151c309e8b3181cbbb4cc/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-app
  const history = createHistoryWithDeeplinks();

  if (process.env.REACT_APP_BUILD_TARGET === "guru") {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={guruTheme}>
          <CssBaseline />
          <GuruGlobalStyles />
          <Router history={history}>
            <Auth0ProviderWithHistory
              history={history}
              auth0Config={
                auth0Config[process.env.REACT_APP_BUILD_TARGET][STAGE || "dev"]
              }
            >
              <GuruApp />
            </Auth0ProviderWithHistory>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  } else if (process.env.REACT_APP_BUILD_TARGET === "ops_portal") {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={guruTheme}>
          <CssBaseline />
          <GuruGlobalStyles />
          <Router history={history}>
            <Auth0ProviderWithHistory
              history={history}
              auth0Config={auth0Config[process.env.REACT_APP_BUILD_TARGET]}
            >
              <OpsPortalApp />
            </Auth0ProviderWithHistory>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={formguruTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/oembed/video/:id"
              component={EmbeddedVideoContainer}
            />
            <Route exact path="/login" component={FormguruAuth} />
            <Route path="/">
              <FormguruAppBar />
              <Route
                exact
                path="/video/:id"
                component={WorkoutVideoContainer}
              />
              <Route exact path="/video" component={WorkoutVideoContainer} />
              <Route exact path="/poll" component={FeaturesPoll} />
              <Route
                path="/upload"
                component={() => (
                  <WorkoutVideoUploader source="formguru.fitness" />
                )}
                exact
              />
              <Route path="/contact" component={ContactUs} exact />
              <Route
                exact
                path="/"
                component={isBrowser ? DesktopHomepage : MobileHomepage}
              />
              <Route exact path="/signup" component={SignUpPage} />
              <Route exact path="/privacy" component={FormguruPrivacyPolicy} />
              <Route
                exact
                path="/terms"
                component={FormguruTermsAndConditions}
              />
              {/* TODO: redirect from / to /dashboard if user is logged in */}
              <Route exact path="/dashboard">
                <PersonalDashboard />
              </Route>
              <Route exact path="/feed">
                <PublicFeed />
              </Route>
              <Route exact path="/account" component={AccountOverview} />
              <Route exact path="/latestfeatures" component={LatestFeatures} />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const auth0Config = {
  guru: {
    dev: {
      domain: "dev-vi9e6909.us.auth0.com",
      clientId: "o3pMe3wBQMp7nyhmckJWiiFuSnj5Lsu4",
      audience: "http://localhost:5000",
    },
    production: {
      domain: "guru-prod.us.auth0.com",
      clientId: "njWZndfVqaNMrpjL62R4IYbvZ5Ts5frx",
      audience: "https://api.formguru.fitness/",
    },
  },
  ops_portal: {
    domain: "guru-internal.us.auth0.com",
    clientId: "x99D7W8Fc348ZIPspgrWV6iVGArOglwh",
    audience: "https://opsportal.formguru.fitness/",
  },
};

export const amplitudeKeys = {
  dev: "557476963d9ba99a7e55558d4339ea35",
  production: "298d81757a3d2a461e7ec9d0c6354919",
};

export const cognitoConfig = {
  dev: {
    region: "us-west-2",
    userPoolId: "us-west-2_aulTtIzW4",
    userPoolWebClientId: "52ud1ejjknpvdhpma7gk5nq6d8",
  },
  production: {
    region: "us-west-2",
    userPoolId: "us-west-2_3h9ZRbbF4",
    userPoolWebClientId: "nru8lrp9bssr19liaptv2j5qi",
  },
};

export const apiEndpoints = {
  dev: "http://127.0.0.1:5000",
  production: "https://api.getguru.fitness",
};

export const Config = {
  stage: STAGE || "dev",
  honeybadgerApiKey: "bb2cdd61",
  amplitudeApiKey: amplitudeKeys[STAGE] || amplitudeKeys["dev"],
  apiEndpoint: apiEndpoints[STAGE] || apiEndpoints["dev"],
  isUsingAuth0: process.env.REACT_APP_BUILD_TARGET in auth0Config,
  // annotationServerEndpoint: "http://localhost:5000",
  annotationServerEndpoint:
    "https://7xprkoyfe8.execute-api.us-west-2.amazonaws.com/Prod",
};

Amplify.configure(cognitoConfig[Config.stage]);
