const ReadableAnalysis = ({ analysisType, analysisScalar }) => {
  let readableScalar;
  switch (analysisType) {
    case "BAR_PATH_SINUOSITY_PERCENTAGE":
      const offsetScalar = analysisScalar - 50;
      readableScalar = `${
        offsetScalar < 0 ? 0 : Math.round(offsetScalar / 10)
      }/5`;
      if (analysisScalar > 85.0) {
        return {
          text: "✅ Bar Path Straightness",
          scalar: readableScalar,
          gradePercentage: analysisScalar,
        };
      } else {
        return {
          text: "❗ Bar Path Straightness",
          scalar: readableScalar,
          gradePercentage: analysisScalar,
        };
      }
    case "HIP_KNEE_ANGLE_DEGREES":
      readableScalar = `${analysisScalar > 0 ? "+" : ""}${analysisScalar}°`;
      if (analysisScalar < 2.5) {
        return {
          text: "✅ Hip/Knee Angle at max depth",
          scalar: readableScalar,
          gradePercentage: 100,
        };
      } else {
        return {
          text: "❗ Hip/Knee Angle at max depth",
          scalar: readableScalar,
          gradePercentage: 0,
        };
      }
    case "SHOULDER_HIP_KNEE_LOCK_OUT_ANGLE":
      const degreesFromVertical = 180 - analysisScalar;
      readableScalar = `+${degreesFromVertical > 0 ? degreesFromVertical : 0}°`;
      if (analysisScalar > 170) {
        return {
          text: `✅ Degrees from full hip lock out`,
          scalar: readableScalar,
          gradePercentage: 100,
        };
      } else {
        return {
          text: `❗ Degrees from full hip lock out`,
          scalar: readableScalar,
          gradePercentage: 0,
        };
      }

    case "IS_KNEE_OVER_BAR_AT_REP_START":
      if (analysisScalar === 1) {
        return {
          text: "✅ Shins are in correct starting position",
          gradePercentage: 100,
        };
      } else {
        return {
          text: "❗ Make sure the bar is touching your shins at the start of your rep",
          gradePercentage: 0,
        };
      }
    case "IS_BAR_OVER_SHOULDER_AT_LOCKOUT":
      if (analysisScalar === 1) {
        return {
          text: "✅ Shins are in correct starting position",
          gradePercentage: 100,
        };
      } else {
        return {
          text: "❗ Try to lock out with your bar right above your shoulder.",
          gradePercentage: 0,
        };
      }
    case "IS_ARM_FULLY_EXTENDED_AT_LOCKOUT":
      if (analysisScalar === 1) {
        return {
          text: "✅ Arm is fully extended at lockout.",
          gradePercentage: 100,
        };
      } else {
        return {
          text: "❗ Make sure to fully extend your arms at lockout.",
          gradePercentage: 0,
        };
      }
  }
};

export default ReadableAnalysis;
