import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { usePageViewTracker } from "./Analytics";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    height: "100%",
  },
  nameField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "30ch",
  },
  messageField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function ContactUs() {
  usePageViewTracker("Contact Us");

  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const messageRef = React.useRef();

  const [isEmailInvalid, setIsEmailInvalid] = React.useState(false);
  const [didSubmit, setDidSubmit] = React.useState(false);

  const classes = useStyles();
  const apiClient = axios.create({
    baseURL: "https://api.getguru.fitness",
  });

  const handleClick = async () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      await apiClient.post("/contact", formData, options);
    } catch (error) {
      // FIXME: Make this a real email alias
      alert(
        "Oof, failed to send your message. Please drop us an email at support@formguru.fit"
      );
      return;
    }

    setDidSubmit(true);
  };

  function isSaneLookingEmail(value) {
    const atLocation = value.lastIndexOf("@");
    const dotLocation = value.lastIndexOf(".");
    return (
      atLocation > 0 &&
      dotLocation > atLocation + 1 &&
      dotLocation < value.length - 1
    );
  }

  const validateEmail = (e) => {
    setIsEmailInvalid(!isSaneLookingEmail(e.target.value));
  };

  const isReadyToSubmit = () => {
    return (
      emailRef.current &&
      emailRef.current.value &&
      !isEmailInvalid &&
      messageRef.current &&
      messageRef.current.value.length > 0
    );
  };

  if (didSubmit) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Card className={classes.paper}>
          <CardContent>
            <Typography variant="h3" component="h3">
              Sent ✅
            </Typography>
            <Typography component="p">
              Thanks for your message. We'll get back to you soon.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  } else {
    return (
      <Container className={classes.root}>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12}>
            <TextField
              id="user-name"
              type="email"
              label="Your Name"
              inputRef={nameRef}
              helperText="(Optional)"
              className={classes.nameField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email-address"
              required
              onChange={validateEmail}
              inputRef={emailRef}
              helperText="(Required) We'll reply to this address"
              label="Email Address"
              error={isEmailInvalid}
              autoComplete="on"
              className={classes.nameField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="message-body"
              label="Message"
              inputRef={messageRef}
              multiline
              rows={4}
              fullWidth
              className={classes.messageField}
              defaultValue="Dear Formguru..."
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!isReadyToSubmit()}
              variant="contained"
              aria-label="submit-message"
              color="primary"
              onClick={handleClick}
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
