import { useState, useCallback, useEffect } from "react";

const OffscreenCanvas = ({ width, height, onCanvasReady, style }) => {
  const setCanvasRef = useCallback(
    (canvas) => {
      if (!canvas) {
        return;
      }

      let offscreenCanvas;
      try {
        offscreenCanvas = canvas.transferControlToOffscreen();
      } catch (e) {
        // we probably already did this
        return;
      }
      onCanvasReady(offscreenCanvas);
    },
    [onCanvasReady]
  );

  return (
    <canvas
      width={width}
      height={height}
      style={{
        ...(style || {}),
        position: "absolute",
        border: "1px solid black",
        zIndex: -1,
      }}
      ref={setCanvasRef}
    />
  );
};

export const VideoPlayer = ({
  src,
  onReady,
  onFrameRendered,
  isPlaying,
  seekTarget,
  width,
  height,
  style,
}) => {
  const [offscreenCanvas, setOffscreenCanvas] = useState(null);
  const [mediaWorker, setMediaWorker] = useState(null);

  const handleMessage = useCallback(
    ({ data: { command, args } }) => {
      switch (command) {
        case "initialize-done":
          if (onReady) {
            onReady({
              numFrames: args.numFrames,
              durationMs: args.durationMs,
              videoResolution: args.videoResolution,
            });
          }
          break;
        case "on-frame-rendered":
          if (onFrameRendered) {
            onFrameRendered({
              frameIndex: args.frameIndex,
              timestampMs: args.timestampMs,
            });
          }
          break;
        default:
          console.error(`Unknown command: ${command}`);
      }
    },
    [onReady, onFrameRendered]
  );

  useEffect(() => {
    if (!offscreenCanvas) {
      return;
    }
    const worker = new Worker("/worker.js");
    setMediaWorker(worker);
  }, [offscreenCanvas]);

  useEffect(() => {
    if (!offscreenCanvas || !mediaWorker) {
      return;
    }
    mediaWorker.addEventListener("message", handleMessage);
    return () => {
      mediaWorker.removeEventListener("message", handleMessage);
    };
  }, [offscreenCanvas, mediaWorker, handleMessage]);

  useEffect(() => {
    if (!mediaWorker) {
      return;
    }
    if (!seekTarget) {
      return;
    }
    const isSet = (obj) => obj !== undefined && obj !== null;
    const { frameIndex, timestampMs } = seekTarget;
    if (isSet(frameIndex)) {
      mediaWorker.postMessage({
        command: "seek-frame",
        args: { frameIndex },
      });
    } else if (isSet(timestampMs)) {
      mediaWorker.postMessage({
        command: "seek-timestamp",
        args: { timestampMs },
      });
    } else {
      throw new Error("Invalid seek target");
    }
  }, [mediaWorker, seekTarget]);

  useEffect(() => {
    if (!mediaWorker) {
      return;
    }
    mediaWorker.postMessage({ command: isPlaying ? "play" : "pause" });
  }, [mediaWorker, isPlaying]);

  useEffect(() => {
    if (!mediaWorker) {
      return;
    }
    mediaWorker.postMessage({
      command: "resize",
      args: { width, height },
    });
  }, [mediaWorker, width, height]);

  useEffect(() => {
    if (!mediaWorker || !offscreenCanvas) {
      return;
    }
    mediaWorker.postMessage(
      {
        command: "initialize",
        videoFile: src,
        canvas: offscreenCanvas,
      },
      { transfer: [offscreenCanvas] }
    );
  }, [src, mediaWorker, offscreenCanvas]);

  return (
    <>
      <OffscreenCanvas
        onCanvasReady={setOffscreenCanvas}
        width={width}
        height={height}
        style={style}
      />
    </>
  );
};
