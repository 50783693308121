import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Container, Link, Typography } from "@mui/material";
import axios from "axios";
import WorkoutVideosFeed from "./WorkoutVideosFeed";
import { fmtDate } from "../feed/FormatDate";
import { Config } from "../../App";
import { usePageViewTracker } from "../Analytics";
import { useAuthSession } from "../auth/FormguruAuth";
import FeedFailedToLoad from "./FeedFailedToLoad";
import ReadableAnalysis from "../reps/ReadableAnalysis";

const useStyles = makeStyles((theme) => ({
  container: {
    // margin: theme.spacing(2, 2),
  },
}));

const PersonalDashboard = () => {
  usePageViewTracker("dashboard");
  const classes = useStyles();
  const [_, getSession] = useAuthSession(true);
  const [uploads, setUploads] = useState(null);
  const [uploadsCursor, setUploadsCursor] = useState(null);
  const [didFailToLoad, setDidFailToLoad] = useState(false);

  const apiClient = axios.create({
    baseURL: Config.apiEndpoint,
  });

  useEffect(() => {
    fetchMoreUploads();
  }, []);

  const toLetterGrade = (percentage) => {
    if (percentage >= 90) {
      return "A";
    } else if (percentage >= 80) {
      return "B";
    } else if (percentage >= 70) {
      return "C";
    } else if (percentage >= 60) {
      return "D";
    } else {
      return "F";
    }
  };

  const toUpload = async ({ video_id: videoId, uploaded_at: uploadedAt }) => {
    //TODO: Add a readOnly query param so only cache is used
    const inferenceUri = `${Config.apiEndpoint}/videos/${videoId}/analysis`;
    const inferenceResponse = await fetch(inferenceUri);
    const { liftType, reps } = await inferenceResponse.json();

    let setGrade = null;

    if (reps) {
      const repScores = reps
        .flatMap((rep) => rep.analyses || [])
        .map(ReadableAnalysis)
        .filter((x) => x)
        .map(({ gradePercentage }) => gradePercentage);

      if (repScores.length > 0) {
        const meanGrade =
          repScores.reduce((a, b) => a + b, 0) / repScores.length;
        setGrade = toLetterGrade(meanGrade);
      }
    }

    return {
      videoId,
      liftType: liftType ? liftType.split("_").join(" ") : "Unknown",
      setGrade,
      numReps: reps ? reps.length : null,
      subheader: fmtDate(new Date(uploadedAt)),
      linkTarget: `/video/${videoId}`,
    };
  };

  const fetchMoreUploads = async () => {
    const session = await getSession();
    if (!session) {
      return;
    }
    const LIMIT = 8;
    const args = {
      headers: { Authorization: session.accessToken.jwtToken },
      params: { limit: LIMIT },
    };
    if (uploadsCursor) {
      args["params"]["next"] = uploadsCursor;
    }
    let response;
    try {
      response = await apiClient.get("/uploads", args);
    } catch (err) {
      setUploadsCursor(null);
      setDidFailToLoad(true);
      throw err;
    }

    const { data: { uploads, next: nextToken } = {} } = response;
    const TERMINAL_STATES = new Set(["SUCCESS", "FAILED"]);

    const nextPage = await Promise.all(
      uploads
        .filter((upload) => {
          return TERMINAL_STATES.has(upload.status.toUpperCase());
        })
        .map(toUpload)
    );

    setUploads((oldVal) => {
      const existing = oldVal ? [...oldVal] : [];
      existing.push(...nextPage);
      return existing;
    });
    setUploadsCursor(nextToken || null);
  };

  const getUploads = () => {
    if (uploads && uploads.length === 0) {
      return (
        <React.Fragment>
          <Typography component="h2" variant="h4">
            You haven't uploaded any videos yet
          </Typography>
          <Typography variant="subtitle1" style={{ padding: "4px" }}>
            Let's fix that, shall we?
          </Typography>
          <Button variant="contained" color="primary" href="/upload">
            Upload video
          </Button>
        </React.Fragment>
      );
    } else {
      return (
        <WorkoutVideosFeed
          uploads={uploads}
          fetchMoreUploads={fetchMoreUploads}
          uploadsCursor={uploadsCursor}
        />
      );
    }
  };

  return (
    <Container className={classes.container}>
      {didFailToLoad ? FeedFailedToLoad() : getUploads()}
    </Container>
  );
};

export default PersonalDashboard;
