import React, { useState, useEffect } from "react";
import {
  ListItemText,
  Select,
  Checkbox,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

export default function ActivityFilter({ activities, onFilterChange }) {
  const [selectedActivities, setSelectedActivities] = useState([]);

  useEffect(() => {
    onFilterChange([]);
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (!value) {
      setSelectedActivities([]);
    } else {
      // On autofill we get a the stringified value.
      const selected = (
        typeof value === "string" ? value.split(",") : value
      ).map((activity) => activity.trim());
      setSelectedActivities(selected);
      onFilterChange(selected);
    }
  };

  return (
    <FormControl sx={{ m: 1, width: 200 }}>
      <InputLabel id="activity-filter-label">Activity</InputLabel>
      <Select
        labelId="activity-filter-label"
        id="activity-filter"
        multiple
        value={selectedActivities}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {activities.map((activity) => (
          <MenuItem key={activity} value={activity}>
            <Checkbox checked={selectedActivities.indexOf(activity) > -1} />
            <ListItemText primary={activity} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
