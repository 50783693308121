import {
  Alert,
  Box,
  Button,
  FormControl,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import axios from "axios";
import Loading from "../Loading";
import { Config } from "../../App";
import { useAuth } from "../auth/GuruAuth";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TaskGrid } from "./AnnotationTasks";
import AnnotationTaskModal from "./AnnotationTaskModal";

const NewDatasetForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState(null);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { getToken } = useAuth();

  const handleSubmission = async () => {
    if (!selectedFile) {
      setError("Please select a file to upload.");
      return;
    }
    if (!description) {
      setError("Please enter a description.");
      return;
    }

    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("description", description);
    setIsUploading(true);
    var response;
    try {
      response = await client.post("datasets", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setIsUploading(false);
    }
  };

  const numBytesToHumanReadableStr = (n) => {
    const GB = 1024 * 1024 * 1024;
    const MB = 1024 * 1024;
    const KB = 1024;

    if (n >= GB) {
      return `${(n / GB).toFixed(2)} GB`;
    } else if (n >= MB) {
      return `${(n / MB).toFixed(2)} MB`;
    } else {
      return `${(n / KB).toFixed(2)} KB`;
    }
  };

  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h6">Upload a new dataset</Typography>
      <FormControl sx={{ width: "50%" }}>
        <TextField
          width="100%"
          placeholder="Description"
          id="description-input"
          onChange={(e) => setDescription(e.target.value)}
        />
        {Boolean(selectedFile) ? (
          <Typography>
            {selectedFile.name} ({numBytesToHumanReadableStr(selectedFile.size)}
            )
          </Typography>
        ) : (
          <input
            type="file"
            name="file"
            accept=".zip"
            onChange={(e) => {
              const f = e.target.files[0];
              if (f.type !== "application/zip") {
                setError("Only .zip files are supported");
              } else {
                setError(null);
                setSelectedFile(f);
              }
            }}
          />
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {isUploading ? (
          <Loading />
        ) : (
          <Button
            variant="contained"
            enabled={
              Boolean(selectedFile) && !Boolean(error) ? "true" : "false"
            }
            onClick={handleSubmission}
          >
            Upload
          </Button>
        )}
      </FormControl>
    </Box>
  );
};

export const DatasetTaskViewer = () => {
  const { id } = useParams();
  const [curTaskId, setCurTaskId] = useState(null);

  const Thumbnail = ({ src, id }) => {
    return (
      <Paper>
        <Link onClick={() => setCurTaskId(id)} href="#">
          <img src={src} alt={id} />
        </Link>
      </Paper>
    );
  };

  return (
    <>
      {curTaskId && (
        <AnnotationTaskModal
          taskId={curTaskId}
          onClose={() => setCurTaskId(null)}
        />
      )}
      <TaskGrid
        TaskComponent={Thumbnail}
        tasksUri={`datasets/${id}/tasks`}
        taskFilter={(_task) => true}
      />
    </>
  );
};

export default function DatasetsBrowser() {
  const [datasets, setDatasets] = useState([]);
  const [nextDatasetId, setNextDatasetId] = useState();
  const [error, setError] = useState();
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDatasets();
  }, []);

  const downloadDataset = async (datasetId) => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      headers: { Authorization: `Bearer ${token}` },
    };
    var downloadLink;
    try {
      response = await client.get(`/datasets/${datasetId}`, requestArgs);
      downloadLink = response.data["dataset_uri"];
    } catch (e) {
      setError(e.message);
      return;
    }
    // GET the data stream from the presigned S3 URL
    var response;
    try {
      response = await axios({
        url: downloadLink,
        method: "GET",
        responseType: "blob",
      });
    } catch (e) {
      setError(e.message);
      return;
    }
    // create a file download link and simulate a click
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `coco-dataset-${datasetId}.zip`);
    document.body.appendChild(link);
    link.click();
  };

  const regenerateDataset = async (datasetId) => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      headers: { Authorization: `Bearer ${token}` },
    };
    var response;
    try {
      response = await client.post(
        `/datasets/${datasetId}/regenerate`,
        null,
        requestArgs
      );
    } catch (e) {
      setError(e.message);
    }
    fetchDatasets();
  };

  const fetchDatasets = async () => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      params: { limit: 128 },
      headers: { Authorization: `Bearer ${token}` },
    };
    if (nextDatasetId) {
      requestArgs.params.next = nextDatasetId;
    }
    setIsLoading(true);
    var response;
    try {
      response = await client.get("datasets", requestArgs);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
    if (response) {
      setDatasets([...response.data.datasets]);
      setNextDatasetId(response.data.next || null);
    }
  };

  if (isLoading) {
    return <Loading message="Loading datasets..." />;
  } else if (error) {
    return (
      <div>
        <Typography color="error">
          Failed to load datasets. Error: {error}
        </Typography>
      </div>
    );
  } else {
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="datasets table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Format</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Download</TableCell>
                <TableCell>Regenerate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datasets.map((dataset) => (
                <TableRow key={dataset.id}>
                  <TableCell>
                    <a href={`/datasets/${dataset.id}`}>{dataset.id}</a>
                  </TableCell>
                  <TableCell>{dataset.format}</TableCell>
                  <TableCell>
                    {new Date(dataset.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell>{dataset.status}</TableCell>
                  <TableCell>{dataset.description}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => downloadDataset(dataset.id)}
                      disabled={dataset.status !== "completed"}
                    >
                      Download
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => regenerateDataset(dataset.id)}
                      disabled={
                        !dataset.is_dirty || dataset.status === "pending"
                      }
                    >
                      Regenerate
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <NewDatasetForm />
      </>
    );
  }
}
