import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import Loading from "../Loading";
import { Config } from "../../App";
import { useAuth } from "../auth/GuruAuth";
import { useEffect, useState } from "react";
import ActivityPicker from "./ActivityPicker";

import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// https://stackoverflow.com/a/35759874/895769
const isNumber = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const TimestampField = ({ onChange, value, lo, hi, ...props }) => {
  const [seconds, setSeconds] = useState(value);
  const [error, setError] = useState(null);

  useEffect(() => {
    setSeconds(value);
  }, [value]);

  useEffect(() => {
    if (seconds !== value) {
      onChange(seconds);
    }
  }, [seconds]);

  const formatTime = () => {
    if (seconds === null) {
      return "--:--";
    }
    const zeroPad = (num) => String(num).padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;
    return `${zeroPad(minutes)}:${zeroPad(secondsLeft)}`;
  };

  const parseTimeAsSeconds = (val) => {
    if (isNumber(val)) {
      return val;
    }
    const [minutes, _seconds] = val.split(":");
    if (isNumber(minutes) && isNumber(_seconds)) {
      return Number(minutes) * 60 + Number(_seconds);
    }
  };

  const handleChange = (e) => {
    const val = e.target.value;
    const num = parseTimeAsSeconds(val);
    if (val.length === 0) {
      setSeconds(null);
    } else if (num === null) {
      setError("Invalid time format");
    } else if (lo !== null && num < lo) {
      setError(`Value must be >= ${lo}`);
    } else if (hi !== null && num > hi) {
      setError(`Value must be <= ${hi}`);
    } else {
      setSeconds(num);
    }
  };

  return (
    <TextField
      {...props}
      errorText={error}
      value={formatTime(value)}
      onChange={handleChange}
    />
  );
};

const UploadQueue = ({ uploadQueue, uploadQueueCurIdx }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Row</TableCell>
            <TableCell>URI</TableCell>
            <TableCell>Activity</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uploadQueue.map((item, idx) => (
            <TableRow key={idx + 1}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell>{item.uri}</TableCell>
              <TableCell>{item.activity}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>
                {item.error ? (
                  <>
                    <ErrorIcon color="error" />
                    <Typography component="span">Failed</Typography>
                  </>
                ) : item.jobId ? (
                  <>
                    <CheckCircleIcon color="primary" />
                    <Typography component="span">Done</Typography>
                  </>
                ) : (
                  "Pending"
                )}
              </TableCell>
              <TableCell>{item.error || item.jobId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CsvImporter = () => {
  const [data, setData] = useState();
  const [uploadQueue, setUploadQueue] = useState([]);
  const [uploadQueueCurIdx, setUploadQueueCurIdx] = useState(-1);
  const [error, setError] = useState();
  const { isUploading, submit } = useCreateJob();

  const parseRows = (rows) => {
    const COUNTIX_HEADERS = [
      ["video_id", "externalId"],
      ["class", "activity"],
      ["kinetics_start", "startTime"],
      ["kinetics_end", "endTime"],
      ["repetition_start", null],
      ["repetition_end", null],
      ["count", "numReps"],
    ];
    const COUNTIX_FIELDS = COUNTIX_HEADERS.map((x) => x[0]);

    const KINETICS_HEADERS = [
      ["label", "activity"],
      ["youtube_id", "externalId"],
      ["time_start", "startTime"],
      ["time_end", "endTime"],
      ["split", null],
    ];
    const KINETICS_FIELDS = KINETICS_HEADERS.map((x) => x[0]);

    const arrEq = (a, b) => {
      return a.length === b.length && a.every((v, i) => v === b[i]);
    };

    var source;
    var fields;
    if (arrEq(rows[0], COUNTIX_FIELDS)) {
      fields = COUNTIX_HEADERS.map((x) => x[1]);
      source = "countix";
    } else if (arrEq(rows[0], KINETICS_FIELDS)) {
      fields = KINETICS_HEADERS.map((x) => x[1]);
      source = "kinetics700_2020";
    } else {
      setError(
        "Invalid .csv file - expected a header in either Countix or Kinetics format"
      );
      return;
    }

    const parsedRows = rows.splice(1).map((cols) => {
      return cols.map((val) => (isNumber(val) ? parseFloat(val) : val));
    });
    const badRows = parsedRows
      .map(
        (row, idx) => (row.length === fields.length ? null : idx + 2) // +2 to account for header row
      )
      .filter((x) => x);
    if (badRows.length > 0) {
      setError(
        "Invalid .csv file - the following rows didn't have the expected # of columns: " +
          badRows
      );
    } else {
      return parsedRows.map((row) => {
        const result = fields.reduce((acc, field, idx) => {
          if (field !== null) {
            acc[field] = row[idx];
          }
          return acc;
        }, {});
        const ACTIVITY_ALIASES = { "clean and jerk": "clean_and_jerk" };
        result.activity = ACTIVITY_ALIASES[result.activity] || result.activity;
        result.uri = `https://www.youtube.com/watch?v=${result.externalId}`;
        result.source = source;
        result.error = null;
        result.jobId = null;
        return result;
      });
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const rows = data
      .trim()
      .split("\n")
      .map((row) => row.trim().split(","));
    const queuedItems = parseRows(rows);
    if (queuedItems) {
      setUploadQueue((q) => [...q, ...queuedItems]);
      setUploadQueueCurIdx((i) => Math.max(0, i));
    }
  }, [data]);

  useEffect(() => {
    if (uploadQueueCurIdx === -1 || uploadQueueCurIdx >= uploadQueue.length) {
      return;
    }

    const upload = async () => {
      const item = uploadQueue[uploadQueueCurIdx];
      const { error, job } = await submit(item);
      if (error !== null) {
        const item = uploadQueue[uploadQueueCurIdx];
        item.error = error;
      } else {
        item.jobId = job.id;
      }
      setUploadQueueCurIdx((i) => (i += 1));
    };

    upload();
  }, [uploadQueue, uploadQueueCurIdx]);

  const onFileUploaded = (e) => {
    setError(null);
    e.preventDefault();
    if (e.dataTransfer.items.length !== 1) {
      setError("Please upload only one file at a time.");
      return;
    }
    const file = e.dataTransfer.items[0];
    if (file.kind !== "file" || file.type !== "text/csv") {
      setError("Only .csv file uploads are supported.");
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      setData(e.target.result);
    };
    reader.readAsText(file.getAsFile());
  };

  return (
    <>
      <Box
        id="drop-zone"
        onDrop={(e) => onFileUploaded(e)}
        onDragOver={(e) => e.preventDefault()}
        style={{
          border: "1px",
          borderStyle: "dashed",
          borderColor: "lightgray",
          padding: "10px",
          margin: "10px",
        }}
      >
        <Typography variant="body2">(Drop a .csv file here)</Typography>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
      <Box>
        <UploadQueue
          uploadQueue={uploadQueue}
          uploadQueueCurIdx={uploadQueueCurIdx}
        />
      </Box>
    </>
  );
};

const useCreateJob = () => {
  const { getToken } = useAuth();
  const [isUploading, setIsUploading] = useState(false);

  const submit = async ({
    uri,
    activity,
    startTime,
    endTime,
    source = null,
    numReps = null,
    skipReview = false,
  }) => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    setIsUploading(true);
    const payload = {
      external_uri: uri,
      activity,
      trim_start_sec: startTime,
      trim_end_sec: endTime,
      skip_review: skipReview,
    };
    if (source !== null) {
      payload.source = source;
    }
    if (numReps !== null) {
      payload.num_reps = numReps;
    }

    var response;
    try {
      response = await client.post("/scraping/jobs", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.error(e);
      const response = e.response;
      var message = "Unknown error";
      if (response) {
        message = (await response.data.message) || message;
      }
      return { job: null, error: message };
    } finally {
      setIsUploading(false);
    }
    const job = await response.data;
    return { job, error: null };
  };

  return { isUploading, submit };
};

const SubmitTaskForm = ({ onJobCreated }) => {
  const [uri, setUri] = useState(null);
  const [activity, setActivity] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [error, setError] = useState(null);
  const [newJobId, setNewJobId] = useState(null);
  const { isUploading, submit } = useCreateJob();

  const handleSubmission = async () => {
    if (!uri) {
      setError("Please enter the URI of a video.");
      return;
    }
    if (!activity) {
      setError("Please select the activity.");
      return;
    }
    setError(null);

    const { job, error } = await submit({
      uri,
      activity,
      startTime,
      endTime,
      skipReview: true,
    });
    if (error !== null) {
      setError(error);
    } else {
      setNewJobId(job.id);
      onJobCreated(job);
    }
  };

  return (
    <Grid container spacing={1} justifyContent="flex-start" xs={6}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              label="Video URL"
              placeholder="https://..."
              id="uri-input"
              value={uri}
              onChange={(e) => setUri(e.target.value)}
            />
          </Grid>
          <Grid item>
            <ActivityPicker onChange={setActivity} sx={{ minWidth: "200px" }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <TimestampField
              label="Trim start"
              placeholder="00:00"
              id="start-input"
              value={startTime}
              onChange={(tSec) => setStartTime(tSec)}
            />
          </Grid>
          <Grid item>
            <TimestampField
              label="Trim end"
              placeholder=""
              id="end-input"
              value={endTime}
              onChange={(tSec) => setEndTime(tSec)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {isUploading ? (
          <Loading />
        ) : (
          <Button
            variant="contained"
            onClick={handleSubmission}
            sx={{ width: "fit-content" }}
          >
            Scrape
          </Button>
        )}
      </Grid>
      {error && <Alert severity="error">{error}</Alert>}
      {newJobId && <Alert severity="success">Created job {newJobId}</Alert>}
    </Grid>
  );
};

const OriginalVideo = ({
  id,
  external_uri: uri,
  external_id: externalId,
  thumbnail_uri: thumbnailUri,
  trim_start_sec: startTime,
}) => {
  if (uri.includes("youtube")) {
    var ytUrl = `https://www.youtube.com/embed/${externalId}`;
    if (startTime) {
      ytUrl += `?start=${startTime}`;
    }
    return (
      <iframe
        id={`player-${id}`}
        type="text/html"
        width="640"
        height="360"
        src={ytUrl}
        frameborder="0"
      />
    );
  } else if (uri.includes("vimeo")) {
    var vimeoUrl = `https://player.vimeo.com/video/${externalId}`;
    if (startTime) {
      ytUrl += `#t=${startTime}s`;
    }
    return (
      <iframe
        id={`player-${id}`}
        type="text/html"
        width="100%"
        height="360"
        src={vimeoUrl}
        frameborder="0"
      />
    );
  } else {
    return (
      <Typography>
        <Link href={uri} target="_blank">
          {thumbnailUri ? <img src={thumbnailUri} /> : "View Original"}
        </Link>
      </Typography>
    );
  }
};

const VideoDetails = ({ job, onDelete, onFinalize, onEdit, onRetry }) => {
  const {
    id,
    trim_start_sec: startTime,
    trim_end_sec: endTime,
    original_title: title,
    external_id: externalId,
    created_at: createdAt,
    video_id: videoId,
    failure_reason: failureReason,
  } = job;

  const [editedStartTime, setEditedStartTime] = useState(startTime);
  const [editedEndTime, setEditedEndTime] = useState(endTime);
  const isDirty = editedStartTime !== startTime || editedEndTime !== endTime;

  const getStartTime = () => editedStartTime;
  const getEndTime = () => editedEndTime;

  return (
    <Box sx={{ margin: 1 }}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom component="div">
            {title || externalId}
          </Typography>
          <Typography component="p" variant="body2">
            Created at: {new Date(createdAt).toLocaleString()}
          </Typography>
          <OriginalVideo {...job} />
          {videoId && (
            <Typography>
              <Link href={`/video/${videoId}`} target="_blank">
                View on Guru
              </Link>
            </Typography>
          )}
          {failureReason && (
            <>
              <Alert severity="error">
                {failureReason}
                <Button onClick={() => onRetry(id)}>Retry</Button>
              </Alert>
            </>
          )}
          <Stack spacing={1}>
            <TimestampField
              label="Trim start"
              value={editedStartTime}
              onChange={(tSec) => setEditedStartTime(tSec)}
              sx={{ maxWidth: "100px" }}
            />
            <TimestampField
              label="Trim end"
              value={getEndTime()}
              onChange={(tSec) => setEditedEndTime(tSec)}
              sx={{ maxWidth: "100px" }}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onDelete(id)}
            >
              Delete
            </Button>
            {isDirty && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setEditedStartTime(startTime);
                  setEditedEndTime(endTime);
                }}
              >
                Reset Trim
              </Button>
            )}
            {isDirty && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  const updated = {
                    id: job.id,
                    activity: job.activity,
                    num_reps: job.num_reps,
                    trim_start_sec: getStartTime(),
                    trim_end_sec: getEndTime(),
                  };
                  onEdit(updated);
                }}
              >
                Update
              </Button>
            )}
            {job.status === "pending" && (
              <Button
                variant="contained"
                color="primary"
                enabled={!isDirty}
                onClick={() => onFinalize(id)}
              >
                Finalize
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

const Row = ({ job, onDelete, onFinalize, onEdit, onRetry }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {job.id}
        </TableCell>
        <TableCell>{job.status}</TableCell>
        <TableCell>{job.activity}</TableCell>
        <TableCell>{job.original_title || "(N/A)"}</TableCell>
        <TableCell>
          <Link href={job.external_uri} target="_blank">
            {job.ingestion_source}
          </Link>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <VideoDetails
              job={job}
              onDelete={onDelete}
              onFinalize={onFinalize}
              onEdit={onEdit}
              onRetry={onRetry}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const JobTable = ({ jobs, onDelete, onFinalize, onEdit, onRetry }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Activity</TableCell>
            <TableCell>Video Title</TableCell>
            <TableCell>Source</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job) => (
            <Row
              key={job.id}
              job={job}
              onDelete={onDelete}
              onFinalize={onFinalize}
              onEdit={onEdit}
              onRetry={onRetry}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function ScrapingHub() {
  const [jobs, setJobs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getToken } = useAuth();

  const onRetry = async (jobId) => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    await client.post(`/scraping/jobs/${jobId}/redrive`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const onFinalize = async (jobId) => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    var response;
    try {
      response = await client.post(`/scraping/jobs/${jobId}/finalize`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.error(e);
      const response = e.response;
      var message = "Unknown error";
      if (response) {
        message = (await response.data.message) || message;
      }
      setError(message);
      return;
    }
    const updatedJob = await response.data;
    setJobs((jobs) => jobs.map((job) => (job.id === jobId ? updatedJob : job)));
  };

  const onDelete = async (jobId) => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    setIsLoading(true);
    try {
      await client.delete(`/scraping/jobs/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.error(e);
      const response = e.response;
      var message = "Unknown error";
      if (response) {
        message = (await response.data.message) || message;
      }
      setError(message);
      return;
    } finally {
      setIsLoading(false);
    }
    setJobs(jobs.filter((job) => job.id !== jobId));
  };

  const onEdit = async (job) => {
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    var response;
    const jobId = job.id;
    try {
      response = await client.post(`/scraping/jobs/${jobId}`, job, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.error(e);
      const response = e.response;
      var message = "Unknown error";
      if (response) {
        message = (await response.data.message) || message;
      }
      setError(message);
      return;
    }
    const updatedJob = await response.data;
    setJobs((jobs) => jobs.map((job) => (job.id === jobId ? updatedJob : job)));
  };

  const fetchJobs = async () => {
    setIsLoading(true);
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    var response;
    try {
      response = await client.get("/scraping/jobs", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const { jobs } = await response.data;
      setJobs(jobs);
    } catch (e) {
      console.error(e);
      if (e.response) {
        const { message } = await e.response.data;
        setError(message);
      } else {
        setError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const renderJobs = () => {
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    } else if (isLoading) {
      return <Loading />;
    } else if (jobs) {
      return (
        <Box>
          <JobTable
            jobs={jobs}
            onDelete={onDelete}
            onFinalize={onFinalize}
            onEdit={onEdit}
            onRetry={onRetry}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Scrape new URL
        </AccordionSummary>
        <AccordionDetails>
          <SubmitTaskForm
            onJobCreated={(newJob) => setJobs((_jobs) => [newJob, ..._jobs])}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Import from .csv
        </AccordionSummary>
        <AccordionDetails>
          <CsvImporter />
        </AccordionDetails>
      </Accordion>
      <Box m={2}>
        <Typography variant="h5">Jobs</Typography>
        {renderJobs()}
      </Box>
    </>
  );
}
