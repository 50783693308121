import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { JointToPoints } from "./JointToPoints";
import { useEventLogger } from "./Analytics";
import { Config } from "../App";
import { median } from "./Math";
import { useAuth } from "./auth/GuruAuth";

export const FetchStatus = {
  INITIALIZING: "INITIALIZING",
  ANALYZING: "ANALYZING",
  SUCCESS: "SUCCESS",
  UNKNOWN_FAILURE: "UNKNOWN_FAILURE",
  POOR_DETECTION_RESULTS: "POOR_DETECTION_RESULTS",
  NOT_FOUND: "NOT_FOUND",
};

export const isTerminalState = (status) => {
  const TERMINAL_STATES = new Set([
    FetchStatus.SUCCESS,
    FetchStatus.UNKNOWN_FAILURE,
    FetchStatus.POOR_DETECTION_RESULTS,
    FetchStatus.NOT_FOUND,
  ]);
  return TERMINAL_STATES.has(status);
};

export const useVideoIdFromUrl = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  // e.g., if URL is "/coach-demo/123?foo=bar&buzz=bam" then return "123"
  const queryParamsIndex = id.indexOf("?");
  return queryParamsIndex === -1 ? id : id.substring(0, queryParamsIndex);
};

const waitForResult = async (uri, token, maxPollSeconds, callbacks) => {
  const { onResult, onTimeout, onError } = callbacks;
  const intervalSeconds = 5;
  const maxAttempts = maxPollSeconds / intervalSeconds;

  let numAttempts = 0;
  const headers =
    token !== null
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};
  const request = new Request(uri, { headers: new Headers(headers) });
  const poll = async () => {
    let response = null;
    try {
      response = await fetch(request);
    } catch {}

    if (!response || !response.ok) {
      onError(response);
      return;
    }

    const body = await response.json();
    if (body["status"] === "Pending") {
      numAttempts++;
      if (numAttempts < maxAttempts) {
        setTimeout(poll, intervalSeconds * 1000);
      } else {
        onTimeout();
      }
    } else {
      onResult(body);
    }
  };
  poll();
};

const useInferenceResults = (videoId) => {
  const API_ENDPOINT = Config.apiEndpoint;
  const [results, setResults] = useState({
    jTP: null,
    reps: null,
    liftType: null,
    uploadedAt: null,
    overlays: {},
    waitTimeMs: null,
    fetchStatus: FetchStatus.INITIALIZING,
  });
  const { isAuthEnabled, getToken } = useAuth();

  const pinJointToMedian = (joint, jTP, reps) => {
    var start, end;
    if (reps.length > 0) {
      const { startTimestampMs: _start } = reps[0];
      const { endTimestampMs: _end } = reps[reps.length - 1];
      [start, end] = [_start, _end];
    } else {
      [start, end] = [0, Infinity];
    }
    const isDuringLift = ({ timestamp }) =>
      timestamp * 1000 >= start && timestamp * 1000 <= end;
    const points = jTP[joint].filter(isDuringLift);
    const medianX = median(points.map(({ position: { x, y } }) => x));
    const medianY = median(points.map(({ position: { x, y } }) => y));
    const DISTANCE_THRESHOLD = 0.15;
    const isCloseToMedian = ({ position: { x, y } }) =>
      Math.abs(x - medianX) < DISTANCE_THRESHOLD &&
      Math.abs(y - medianY) < DISTANCE_THRESHOLD;
    const snapToMedian = (point) => {
      if (isDuringLift(point) && isCloseToMedian(point)) {
        return { ...point, position: { x: medianX, y: medianY } };
      }
      return point;
    };
    jTP[joint] = jTP[joint].map(snapToMedian);
    return jTP;
  };

  const parseJ2PFromResponse = (j2p) => {
    const joints = ["platesCenter"].concat(
      ["Hip", "Knee", "Ankle", "Shoulder", "Elbow", "Wrist", "Heel", "Toe"]
        .map((Joint) => [`left${Joint}`, `right${Joint}`])
        .flat()
    );
    const filtered = joints.reduce((obj, joint) => {
      obj[joint] = j2p[joint] || [];
      return obj;
    }, {});
    return JointToPoints(filtered);
  };

  useEffect(async () => {
    const token = isAuthEnabled ? await getToken() : null;
    waitForResult(
      `${API_ENDPOINT}/inference/j2p/${videoId}?include=analysis`,
      token,
      90,
      {
        onResult: async (body) => {
          if (body["status"] === "Failed") {
            const fetchStatus =
              body["reason"] === "INSUFFICIENT_KEYPOINTS"
                ? FetchStatus.POOR_DETECTION_RESULTS
                : FetchStatus.UNKNOWN_FAILURE;
            console.error(`Unknown status: ${body["status"]}`);
            setResults((results) => {
              let result = {
                ...results,
                fetchStatus,
              };
              if ("jointToPoints" in body) {
                result["jTP"] = parseJ2PFromResponse(body["jointToPoints"]);
              }
              return result;
            });
          } else if (body["status"] === "Complete") {
            const {
              analysis: { reps, liftType },
            } = body;
            const ACTIVITIES_WITH_STATIONARY_FEET = new Set([
              "SQUAT",
              "DEADLIFT",
              "BENCH",
            ]);
            let jTP = parseJ2PFromResponse(body["jointToPoints"]);
            if (ACTIVITIES_WITH_STATIONARY_FEET.has(liftType)) {
              jTP = pinJointToMedian("leftAnkle", jTP, reps);
              jTP = pinJointToMedian("rightAnkle", jTP, reps);
            }
            let uploadedAt = new Date(body["created"]);
            setResults({
              jTP,
              reps,
              liftType,
              uploadedAt,
              fetchStatus: FetchStatus.SUCCESS,
            });
          } else {
            console.error(`Unknown status: ${body["status"]}`);
            setResults((results) => ({
              ...results,
              fetchStatus: FetchStatus.UNKNOWN_FAILURE,
            }));
          }
        },
        onError: (response) => {
          setResults((results) => ({
            ...results,
            fetchStatus:
              response && response.status === 404
                ? FetchStatus.NOT_FOUND
                : FetchStatus.UNKNOWN_FAILURE,
          }));
        },
        onTimeout: () => {
          setResults((results) => ({
            ...results,
            fetchStatus: FetchStatus.UNKNOWN_FAILURE,
          }));
        },
      }
    );
  }, [videoId]);

  return results;
};

export const useVideoSrc = (videoId) => {
  const API_ENDPOINT = Config.apiEndpoint;
  const [result, setResult] = useState({
    fetchStatus: FetchStatus.INITIALIZING,
    videoSrcUri: null,
    overlays: {},
  });
  const { isAuthEnabled, getToken } = useAuth();

  useEffect(async () => {
    const token = isAuthEnabled ? await getToken() : null;
    waitForResult(`${API_ENDPOINT}/video/${videoId}`, token, 90, {
      onResult: async (body) => {
        setResult({
          fetchStatus: FetchStatus.SUCCESS,
          overlays: body["overlays"],
          videoSrcUri: body["uri"],
        });
      },
      onError: (response) => {
        const status =
          response && response.status === 404
            ? FetchStatus.NOT_FOUND
            : FetchStatus.UNKNOWN_FAILURE;
        setResult({
          fetchStatus: status,
          videoSrcUri: null,
        });
      },
      onTimeout: () => {
        setResult({
          fetchStatus: FetchStatus.UNKNOWN_FAILURE,
          videoSrcUri: null,
        });
      },
    });
  }, [videoId]);

  return result;
};

export const useVideoAndInferenceResults = (
  videoId,
  isNewUpload,
  etaSeconds = null
) => {
  const logEvent = useEventLogger();
  const [startTime, setStartTime] = useState(new Date());
  const { fetchStatus: jTPStatus, ...inferenceResults } =
    useInferenceResults(videoId);
  const {
    fetchStatus: videoStatus,
    videoSrcUri,
    overlays,
  } = useVideoSrc(videoId);

  const logCompletion = (status, analysisType) => {
    const elapsedSeconds = (new Date().getTime() - startTime.getTime()) / 1000;
    logEvent("video_analysis_finished", {
      video_id: videoId,
      initial_eta_seconds: etaSeconds,
      actual_wait_seconds: elapsedSeconds,
      analysis_type: analysisType,
      new_upload: isNewUpload,
      result: status,
    });
  };

  useEffect(() => {
    if (isTerminalState(jTPStatus)) {
      logCompletion(jTPStatus, "j2p");
    }
  }, [jTPStatus]);

  useEffect(() => {
    if (isTerminalState(videoStatus)) {
      logCompletion(videoStatus, "video");
    }
  }, [videoStatus]);

  useEffect(() => {
    const areAllResultsReady = [jTPStatus, videoStatus].every(isTerminalState);
    if (areAllResultsReady) {
      const aggregateStatus =
        videoStatus === FetchStatus.SUCCESS ? jTPStatus : videoStatus;
      logCompletion(aggregateStatus, "analysis");
    }
  }, [jTPStatus, videoStatus]);

  return {
    videoSrcUri,
    videoStatus,
    jTPStatus,
    overlays,
    ...inferenceResults,
  };
};
