import { useState, useCallback } from "react";
import { isTerminalState, useVideoSrc } from "../AnalysisLoader";
import Loading from "../Loading";
import { Button, Slider, Stack, Typography } from "@mui/material";
import { VideoPlayerCanvas } from "./VideoPlayerCanvas";

export const VideoPlayerDemo = ({ videoId }) => {
  const _videoId = videoId || "c96fa975-fd9e-4912-8f60-1db5a6d829d1";
  const { fetchStatus, videoSrcUri } = useVideoSrc(_videoId);
  const [isReady, setIsReady] = useState(false);
  const [numFrames, setNumFrames] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(null);
  const [seekTargetFrameIndex, setSeekTargetFrameIndex] = useState(null);

  const onReady = ({ numFrames }) => {
    setIsReady(true);
    setNumFrames(numFrames);
  };

  const onFrameRendered = ({ frameIndex }) => setCurrentFrame(frameIndex);

  const seekByOffset = useCallback(
    (offset) => {
      setSeekTargetFrameIndex(currentFrame + offset);
    },
    [currentFrame]
  );

  const seekPercentage = useCallback(
    (pct) => {
      const targetFrame = Math.round((pct / 100.0) * numFrames);
      setIsPlaying(false);
      setSeekTargetFrameIndex(Math.min(targetFrame, numFrames));
    },
    [numFrames]
  );

  const togglePlayback = () => {
    setIsPlaying((isPlaying) => {
      return !isPlaying;
    });
  };

  if (!isTerminalState(fetchStatus)) {
    return <Loading message="Loading videos..." />;
  }

  return (
    <>
      <VideoPlayerCanvas
        src={videoSrcUri}
        isPlaying={isPlaying}
        seekTargetFrameIndex={seekTargetFrameIndex}
        onReady={onReady}
        onFrameRendered={onFrameRendered}
      />
      <Stack>
        <Typography>
          Current Frame: {currentFrame !== null ? currentFrame : "-"}
        </Typography>
        <Stack direction="row">
          <Button disabled={!isReady} onClick={togglePlayback}>
            {isPlaying ? "Pause" : "Play"}
          </Button>
          <Button disabled={isPlaying} onClick={() => seekByOffset(-1)}>
            Previous Frame
          </Button>
          <Button disabled={isPlaying} onClick={() => seekByOffset(+1)}>
            Next Frame
          </Button>
        </Stack>
        <Slider
          min={0}
          max={100}
          value={currentFrame === null ? 0 : (100 * currentFrame) / numFrames}
          onChange={(e, val) => {
            seekPercentage(val);
          }}
        />
      </Stack>
    </>
  );
};
