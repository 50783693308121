import React, { useState, useEffect } from "react";
import { Config } from "../../App";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DataGrid } from "@mui/x-data-grid";

import {
  Box,
  Card,
  Link,
  Alert,
  CardContent,
  TextField,
  Stack,
  Typography,
} from "@mui/material";

import Loading from "../Loading";

import axios from "axios";
import { useAuth } from "../auth/GuruAuth";
import { loadingOverlay } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "96px", // leave enough room for sticky header
    padding: theme.spacing(1),
  },
  commandBar: {
    position: "fixed",
    top: 60,
    width: "100%",
    maxHeight: "96px",
    padding: theme.spacing(1),
    // align right
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default function CadooDashboard() {
  const classes = useStyles();
  const { getToken } = useAuth();

  const today = new Date();
  let aWeekAgo = new Date();
  aWeekAgo.setDate(today.getDate() - 7);

  const [startDate, setStartDate] = useState(aWeekAgo);
  const [endDate, setEndDate] = useState(today);
  const [videos, setVideos] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchVideos = async (startDate, endDate) => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      params: { start: startDate.toISOString(), end: endDate.toISOString() },
      headers: { Authorization: `Bearer ${token}` },
    };
    var response;
    try {
      response = await apiClient.get("/dashboard/cadoo", requestArgs);
      setVideos(response.data["videos"]);
    } catch (e) {
      setError(`Failed to fetch uploads: ${e.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos(startDate, endDate);
  }, [startDate, endDate]);

  const StatCard = ({ title, value }) => {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5">{title}</Typography>
          <Box display="flex">
            <Box m="auto">
              <Typography variant="h3">{value}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const Content = () => {
    if (error) {
      return <Alert severity="error">{error}</Alert>;
    } else if (isLoading) {
      return <Loading />;
    }

    const hasPrediction = ({ predicted_activities }) =>
      (predicted_activities || []).length > 0;
    const hasGroundTruth = ({ true_activities }) =>
      (true_activities || []).length > 0;
    const getDelta = ({ predicted_activities, true_activities }) => {
      const predictedNumReps = predicted_activities
        .map(({ num_reps }) => num_reps || 0)
        .reduce((a, b) => a + b, 0);
      const trueNumReps = true_activities
        .map(({ num_reps }) => num_reps || 0)
        .reduce((a, b) => a + b, 0);
      return predictedNumReps - trueNumReps;
    };
    const labeledVideos = videos.filter(
      (v) => hasGroundTruth(v) && hasPrediction(v)
    );
    const formatFractionAsPercentage = (frac) => {
      const percent = frac * 100;
      return percent.toFixed(2);
    };

    const strictAccuracy =
      labeledVideos.length === 0
        ? "N/A"
        : formatFractionAsPercentage(
            labeledVideos.map(getDelta).filter((v) => v === 0).length /
              labeledVideos.length
          );
    const oboAccuracy =
      labeledVideos.length === 0
        ? "N/A"
        : formatFractionAsPercentage(
            labeledVideos.map(getDelta).filter((v) => Math.abs(v) <= 1).length /
              labeledVideos.length
          );

    const toRow = ({
      video_id: videoId,
      uploaded_at: uploadedAt,
      true_activities,
      predicted_activities,
    }) => {
      // TODO: this table format won't make sense once we start supporting videos with >1 activity
      const uniq = new Set(true_activities.map(({ activity }) => activity));
      if (uniq.size > 1) {
        setError("TODO: expected Cadoo upload to have a single activity");
      }
      const trueActivity =
        true_activities.length === 0 ? null : true_activities[0].name;
      const targetActivity = trueActivity || "push_up";

      var trueNumReps = null;
      if (true_activities.length > 0) {
        trueNumReps = true_activities.reduce(
          (total, { name, num_reps: n }) =>
            name == targetActivity ? total + (n || 0) : total,
          0
        );
      }
      var predictedActivity = null;
      var predictedNumReps = null;
      if (predicted_activities.length > 0) {
        predictedNumReps = predicted_activities.reduce(
          (total, { name, num_reps: n }) =>
            name == targetActivity ? total + (n || 0) : total,
          0
        );
        predictedActivity = predicted_activities[0].name;
      }
      var delta = null;
      if (
        trueNumReps !== null &&
        trueNumReps >= 0 &&
        predictedNumReps !== null &&
        predictedNumReps >= 0
      ) {
        delta = predictedNumReps - trueNumReps;
      }

      return {
        videoId,
        uploadedAt,
        trueNumReps,
        predictedNumReps,
        delta,
        trueActivity: trueActivity || "--",
        predictedActivity,
      };
    };

    const columns = [
      {
        field: "videoId",
        headerName: "Video ID",
        width: 300,
        renderCell: (params) => {
          return (
            <Link target="_blank" href={`/video/${params.row.videoId}`}>
              {params.row.videoId}
            </Link>
          );
        },
      },
      {
        field: "uploadedAt",
        headerName: "Uploaded At",
        width: 200,
        valueGetter: (params) => {
          return new Date(params.row.uploadedAt).toLocaleString();
        },
      },
      { field: "trueNumReps", headerName: "True # Reps", width: 100 },
      { field: "predictedNumReps", headerName: "Predicted # Reps", width: 150 },
      {
        field: "delta",
        headerName: "Δ",
        width: 100,
        renderCell: ({ row: { delta } }) => {
          if (typeof delta !== "number") {
            return delta;
          }
          return delta == 0 ? (
            <Typography color="green">{delta}</Typography>
          ) : (
            <Typography color="red">
              {delta > 0 && "+"}
              {delta}
            </Typography>
          );
        },
      },
      { field: "trueActivity", headerName: "True Activity", width: 100 },
      {
        field: "predictedActivity",
        headerName: "Predicted Activity",
        width: 150,
      },
    ];

    const rows = videos.map((v, i) => ({ ...toRow(v), id: i }));

    return (
      <Box>
        <Stack
          direction="horizontal"
          justifyContent="space-evenly"
          style={{ margin: 8 }}
        >
          <StatCard title="# Uploads" value={videos.length} />
          <StatCard title="# Labeled" value={labeledVideos.length} />
          <StatCard title="±0 Accuracy" value={`${strictAccuracy}%`} />
          <StatCard title="±1 Accuracy" value={`${oboAccuracy}%`} />
        </Stack>
        <div style={{ height: "550px" }}>
          <DataGrid rows={rows} columns={columns} />
        </div>
      </Box>
    );
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="horizontal" sx={{ padding: 2 }}>
          <DesktopDatePicker
            label="Start date"
            inputFormat="yyyy-MM-dd"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <DesktopDatePicker
            label="End date"
            inputFormat="yyyy-MM-dd"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
      <Content />
    </>
  );
}
