import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import TheatersIcon from "@mui/icons-material/Theaters";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useHistory, useLocation } from "react-router-dom";
import { signOut, useAuthSession } from "./auth/FormguruAuth.js";
import { StyledMenu } from "./StyledMenu";
import { StyledMenuItem } from "./StyledMenuItem";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  control: {
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
}));

export default function FormguruAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const [getCurrentUser, _] = useAuthSession(false);
  const [isSignedIn, setIsSignedIn] = useState(null);

  useEffect(() => {
    (async () => {
      const user = await getCurrentUser();
      setIsSignedIn(Boolean(user));
    })();
  }, [location.pathname]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickHome = () => {
    history.push("/");
    setAnchorEl(null);
  };

  const onClickUploadItem = () => {
    history.push("/upload");
    setAnchorEl(null);
  };

  const onClickMyVideos = () => {
    history.push("/dashboard");
    setAnchorEl(null);
  };

  const onClickContactUs = () => {
    history.push("/contact");
    setAnchorEl(null);
  };

  const handleAccountMenuClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleAccountMenuClose = (event) => {
    setAccountAnchorEl(null);
  };

  const onClickSignOut = () => {
    signOut().then(() => {
      history.push("/");
      setAccountAnchorEl(null);
      setIsSignedIn(false);
    });
  };

  const onClickAccountSettings = () => {
    history.push("/account");
    setAccountAnchorEl(null);
  };

  const accountMenuId = "account-menu";
  const accountMenu = (
    <Menu
      anchorEl={accountAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={accountMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isAccountMenuOpen}
      onClose={handleAccountMenuClose}
    >
      <StyledMenuItem onClick={onClickSignOut}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </StyledMenuItem>
      <StyledMenuItem onClick={onClickAccountSettings}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="My Account" />
      </StyledMenuItem>
    </Menu>
  );

  const getAccountMenuSection = () => {
    if (isSignedIn) {
      return (
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={accountMenuId}
          aria-haspopup="true"
          onClick={handleAccountMenuClick}
          color="inherit"
          size="large"
        >
          <AccountCircle />
        </IconButton>
      );
    } else {
      return (
        <React.Fragment>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/login")}
          >
            Log in
          </Button>
        </React.Fragment>
      );
    }
  };

  return (
    <div>
      <AppBar position="relative" color="secondary" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={handleMenuClick}
            aria-label="menu"
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h3" color="inherit" noWrap>
            <Link href="/" color="inherit" underline="none">
              Formguru
            </Link>
          </Typography>

          <div className={classes.grow} />
          <div>{getAccountMenuSection()}</div>
        </Toolbar>
      </AppBar>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <StyledMenuItem onClick={onClickHome}>
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onClickUploadItem}>
          <ListItemIcon>
            <CloudUploadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Upload lift video" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onClickMyVideos}>
          <ListItemIcon>
            <TheatersIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="My Videos" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onClickContactUs}>
          <ListItemIcon>
            <MailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </StyledMenuItem>
      </StyledMenu>
      {accountMenu}
    </div>
  );
}
