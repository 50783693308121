import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { signOut, useAuthSession } from "./auth/FormguruAuth";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Config } from "../App";
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function AccountOverview() {
  const [getCurrentUser, getCurrentSession] = useAuthSession(true);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [username, setUsername] = React.useState(null);
  const [isDeactivated, setIsDeactivated] = React.useState(false);
  const history = useHistory();

  const apiClient = axios.create({
    baseURL: Config.apiEndpoint,
  });

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDeactivate = async () => {
    const session = await getCurrentSession();
    const headers = {
      headers: { Authorization: session.accessToken.jwtToken },
    };
    try {
      await apiClient.post("/deactivate", null, headers);
    } catch (err) {
      console.error(err);
      throw err;
    }
    // TODO: show an "Oops" screen on failure
    setIsDeactivated(true);
    signOut();
    setIsDialogOpen(false);
  };

  const handleClickSignOut = () => {
    signOut().then(() => {
      history.push("/");
    });
  };

  useEffect(() => {
    if (isDeactivated) {
      setTimeout(() => {
        history.push("/");
      }, 5000);
    } else {
      (async () => {
        const user = await getCurrentUser();
        if (!user) {
          history.push("/login");
        } else {
          setUsername(user.username);
        }
        // TODO: show an "Oops" screen on failure
      })();
    }
  }, [getCurrentUser]);

  const getDeactivationSuccessScreen = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Card>
          <CardHeader title="Done ✔" />
          <CardContent>
            <Typography>
              We've removed your account. Redirecting to the home page...
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  };

  if (isDeactivated) {
    return getDeactivationSuccessScreen();
  } else {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              avatar={<AccountCircle fontSize="large" />}
              title={
                <Typography component="h2" variant="h4">
                  {username}
                </Typography>
              }
            />
            <CardContent>
              <Link href="/dashboard">
                <Typography>🎥 My Videos</Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} spacing={4}>
          <Card>
            <CardHeader title="Account Actions" />
            <CardContent>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickSignOut}
                >
                  👋 Sign Out
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClickOpen}
                >
                  ⚠️ Deactivate Account
                </Button>
                <Dialog
                  open={isDialogOpen}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Really deactivate your Formguru account?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Your workout history and lift videos will be lost if you
                      proceed.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDeactivate} color="primary">
                      Yes, Proceed
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
