import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ActivityPicker = ({ onChange, ...props }) => {
  // TODO: fetch from backend
  const ACTIVITIES = [
    "bench",
    "bodyweight_squat",
    "burpee",
    "chin_up",
    "clean_and_jerk",
    "knee_to_chest",
    "deadlift",
    "front_kick",
    "lunge",
    "punch",
    "push_up",
    "shoulder_flexion",
    "sit_up",
    "snatch",
    "sprint",
    "squat",
    "downward_dog",
  ];

  const [activity, setActivity] = useState();

  const handleChange = ({ target: { value } }) => {
    setActivity(value);
    onChange(value);
  };

  return (
    <FormControl {...props}>
      <InputLabel id="activity-select-label">Activity</InputLabel>
      <Select
        labelId="activity-select-label"
        id="activity-select"
        value={activity || ""}
        label="Activity"
        onChange={handleChange}
      >
        {ACTIVITIES.map((activity) => {
          return <MenuItem value={activity}>{activity}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default ActivityPicker;
