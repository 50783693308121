import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";

export default function Tips() {
  return (
    <Box mt={2}>
      <Typography variant="h5" style={{ padding: 10 }}>
        Tips for best results:
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 300, padding: 10 }}>
        <ul>
          <li disableTypography>
            Film <b>directly</b> from the side
          </li>
          <li disableTypography>
            Keep the barbell <b>fully in-frame</b> throughout the video
          </li>
          <li disableTypography>
            <b>Trim your video.</b> The video should start just before you begin
            the set
          </li>
          <li>Formguru works best on squats and deadlifts</li>
          <li>
            Still looks wrong? <Link to="/contact">Let us know.</Link>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
