import { GURU_KEYPOINTS } from "./Constants";
import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  SwipeableDrawer,
  Typography,
  Avatar,
} from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const DrawerButton = ({ onClick, isOpen }) => {
  return (
    <Avatar>
      {isOpen ? (
        <ChevronRightRoundedIcon onClick={onClick} />
      ) : (
        <ChevronLeftRoundedIcon onClick={onClick} />
      )}
    </Avatar>
  );
};

export const KeypointsSelector = ({ keypoints, onToggle, containerId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isPresent = (label) => {
    return label in keypoints;
  };

  const KeypointBox = (label, labelPlacement) => (
    <FormControlLabel
      label={<Typography variant="body2">{label}</Typography>}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          key={label}
          checked={isPresent(label)}
          onChange={() => onToggle(label)}
        />
      }
    />
  );

  const leftSide = GURU_KEYPOINTS.filter((label) => label.startsWith("left"));
  const rightSide = GURU_KEYPOINTS.filter((label) => label.startsWith("right"));
  const others = GURU_KEYPOINTS.filter(
    (label) => !label.startsWith("left") && !label.startsWith("right")
  );

  const toggle = () => {
    setIsOpen((open) => !open);
  };

  return (
    <Stack
      direction="row"
      sx={{ height: "100%" }}
      alignItems="center"
      justifyContent="flex-end"
    >
      <DrawerButton onClick={toggle} isOpen={isOpen} />
      <SwipeableDrawer
        anchor={"right"}
        open={isOpen}
        onOpen={toggle}
        onClose={toggle}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        ModalProps={{
          container: document.getElementById(containerId),
          style: { position: "absolute" },
        }}
        sx={{ height: "100%" }}
      >
        <Stack direction="row" alignItems="center" sx={{ height: "100%" }}>
          <DrawerButton onClick={toggle} isOpen={isOpen} />
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={1.5}
          >
            <Grid item xs={6}>
              <Stack>
                {leftSide.map((label) => KeypointBox(label, "start"))}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                {rightSide.map((label) => KeypointBox(label, "end"))}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {others.map((label) => KeypointBox(label, "start"))}
            </Grid>
          </Grid>
        </Stack>
      </SwipeableDrawer>
    </Stack>
  );
};
