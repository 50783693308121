import { useLocation } from "react-router-dom";

export function useFeatureFlags() {
  const queryParams = new URLSearchParams(useLocation().search);

  const isFeatureEnabled = (featureName) => {
    const val = queryParams.get(featureName);
    return val && ["true", "True", "1", 1, "yes"].includes(val);
  };

  return isFeatureEnabled;
}
