import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { usePageViewTracker } from "../Analytics";
import HomepageCarousel from "./HomepageCarousel";
import WorkoutVideoUploader from "../WorkoutVideoUploader";
import Copyright from "./Copyright";
import CookieBanner from "./CookieBanner";
import SeeDemoButton from "./SeeDemoButton";
import LatestFeaturesLink from "./LatestFeaturesLink";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  headline: {
    margin: theme.spacing(8, 20, 0, 10),
    display: "flex",
  },
  callToAction: {
    margin: theme.spacing(10),
  },
  avatar: {
    // Must be a better way to snap this to the bottom of the screen
    margin: theme.spacing(15),
  },
  carousel: {
    // Must be a better way to extend this to the bottom of the screen
    margin: theme.spacing(12, 4),
    height: "100vh",
    display: "flex",
  },
}));

export default function DesktopHomepage() {
  const classes = useStyles();
  usePageViewTracker("Home");

  return (
    <Grid
      container
      component="main"
      className={classes.root}
      direction="column"
    >
      <CssBaseline />
      <Grid container xs={7}>
        <Grid item xs={12}>
          <div className={classes.headline}>
            <Typography variant="h1">Stop lifting with bad form</Typography>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.callToAction}>
          <Typography variant="h4" style={{ fontWeight: 300 }}>
            🏋️ Take a video of your lift
          </Typography>
          <br />
          <Typography variant="h4" style={{ fontWeight: 300 }}>
            📱 Upload it to Formguru
          </Typography>
          <br />
          <Typography variant="h4" style={{ fontWeight: 300 }}>
            🎥 Get instant video analysis
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box ml={10}>
            <WorkoutVideoUploader source="formguru.fitness" />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box ml={3}>
            <SeeDemoButton />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={10} mt={2}>
            <LatestFeaturesLink />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div align="center" className={classes.avatar}>
            <FitnessCenterIcon style={{ fontSize: 100 }} />
            <Copyright />
          </div>
        </Grid>
      </Grid>
      <Grid container xs={5} direction="column">
        <Box component={Paper} elevation={6} maxHeight maxWidth>
          <div align="center" className={classes.carousel}>
            <HomepageCarousel scalingFactor={1.6} />
          </div>
        </Box>
        <CookieBanner />
      </Grid>
    </Grid>
  );
}
