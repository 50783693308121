import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Divider } from "@mui/material";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";
import { useEventLogger } from "./Analytics";

//TODO: Refactor this into an array of elements
export const LatestFeatures = () => {
  const history = useHistory();
  const logEvent = useEventLogger();

  return (
    <div>
      <Box mt={2} align="left" m={2}>
        <Typography variant="h2">
          <b>New Formguru Features</b>
        </Typography>
      </Box>
      <Divider />
      <Box mt={2} align="left" m={2}>
        <Typography variant="caption1" align="left">
          June 2nd, 2021
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          <b>Upload History</b> Create a Formguru Beta account and get access to
          your video history.
          <Link
            variant="body1"
            component="button"
            onClick={(_e) => {
              logEvent("user_clicked_learn_more_about_beta");
              history.push("/signup");
            }}
          >
            Try it out here.
          </Link>
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          <b>Popup Feedback</b> Formguru now overlays live feedback right on top
          of your video, starting with squat depth.
        </Typography>
      </Box>
      <Divider />
      <Box mt={2} align="left" m={2}>
        <Typography variant="caption1" align="left">
          May 10th, 2021
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          <b>Bench Press Support</b> Formguru now gives you feedback on your
          bench press. Try it out by uploading a bench press video.
        </Typography>
      </Box>
      <Divider />
      <Box mt={2} align="left" m={2}>
        <Typography variant="caption1" align="left">
          April 28th, 2021
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          <b>New squat visualization</b> Formguru now displays your back angle.
        </Typography>
      </Box>
      <Divider />
      <Box mt={2} align="left" m={2}>
        <Typography variant="caption1" align="left">
          March 31st, 2021
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          <b>New deadlift feedback.</b> Formguru now tells you if your shins are
          in the right spot at the start of a rep.
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          <b>Jump to each rep in your video.</b> Just click the play button next
          to the rep number.
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 400 }}>
          <b>New deadlift visualization.</b> On deadlifts uploads, Formguru now
          highlights the angles between your knees, hips, and spine.
        </Typography>
      </Box>
      <Divider />
    </div>
  );
};

export default LatestFeatures;
