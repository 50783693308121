import { Typography } from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";
import ReadableAnalysis from "./ReadableAnalysis";

const RepsItems = ({
  reps,
  onClickRepStartTimestampMs,
  isLiftTypeOverriden,
}) => {
  const isAnalysisApplicable = (info) => {
    if (isLiftTypeOverriden) {
      // bar-path is the only universally applicable analysis
      // if we guessed wrong, don't show anything else
      return info.analysisType === "BAR_PATH_SINUOSITY_PERCENTAGE";
    }
    return true;
  };

  const toFormattedAnalysis = (info) => {
    let readableAnalysis = ReadableAnalysis(info);
    if (readableAnalysis) {
      let { text, scalar } = readableAnalysis;

      return (
        <Typography variant="body1">
          {text}
          {scalar ? ": " : null}
          <b>{scalar}</b>
        </Typography>
      );
    } else {
      return <span />;
    }
  };

  return reps.map((rep) => (
    <div>
      <br />
      <Typography variant="h5" component="h5">
        <b>Rep {reps.indexOf(rep) + 1} </b>
        <Link
          variant="h6"
          component="button"
          onClick={() => onClickRepStartTimestampMs(rep.startTimestampMs)}
        >
          [{new Date(rep.startTimestampMs).toISOString().substr(15, 4)}]
        </Link>
      </Typography>
      {rep.analyses
        .filter(isAnalysisApplicable)
        .map((info) => toFormattedAnalysis(info))}
    </div>
  ));
};

export default RepsItems;
