import { drawLine, drawText } from "../Draw";

export const drawPopups = (
  ctx,
  reps,
  joints,
  currentTimestampMs,
  maxWidth,
  maxHeight
) => {
  const isWithinVisibilityWindow = ({ startMs, endMs }) => {
    return currentTimestampMs >= startMs && currentTimestampMs <= endMs;
  };

  if (reps) {
    reps
      .map((rep) => toPopups(rep, joints))
      .flat()
      .filter((popup) => isWithinVisibilityWindow(popup))
      .forEach((popup) =>
        drawPopup(ctx, maxWidth, maxHeight, currentTimestampMs, popup)
      );
  }
};

const toPopups = (
  { analyses, startTimestampMs, midTimestampMs, endTimestampMs },
  joints
) => {
  const { ankle, knee, hip, shoulder } = joints;
  const repInterval = endTimestampMs - startTimestampMs;
  return analyses
    .map(({ analysisType, analysisScalar }) => {
      switch (analysisType) {
        case "HIP_KNEE_ANGLE_DEGREES":
          if (analysisScalar > 2.5) {
            return {
              text: "Try getting your hip lower on this rep",
              joint: hip,
              startMs: startTimestampMs + repInterval / 8,
              endMs: endTimestampMs - repInterval / 8,
            };
          } else {
            return null;
          }
        default:
          return null;
      }
    })
    .filter((popup) => popup);
};

const drawPopup = (
  ctx,
  maxWidth,
  maxHeight,
  currentTimestampMs,
  { text, joint, startMs, endMs }
) => {
  const midMs = (startMs + endMs) / 2;
  const popupInterval = endMs - startMs;

  let popupAlpha;
  if (currentTimestampMs <= midMs) {
    popupAlpha = ((currentTimestampMs - startMs) / (popupInterval / 2)) * 2;
  } else {
    popupAlpha = ((endMs - currentTimestampMs) / (popupInterval / 2)) * 2;
  }

  const popupLocation = { x: maxWidth * 0.6, y: maxHeight * 0.5 };
  const textBox = drawText(
    ctx,
    text,
    popupLocation.x,
    popupLocation.y,
    maxWidth,
    { alpha: popupAlpha }
  );

  const lineEndpointA = {
    position: { x: popupLocation.x, y: popupLocation.y + textBox.height / 2 },
  };
  const lineEndpointB = { position: joint };
  drawLine(ctx, lineEndpointA, lineEndpointB, { alpha: popupAlpha });
};
