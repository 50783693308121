import CenteredBox from "./CenteredBox";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Loading = ({ message = null }) => (
  <CenteredBox>
    <Stack
      direction="column"
      justifyContent="center"
      spacing={2}
      alignItems="center"
    >
      {message && (
        <Typography variant="h2" component="span">
          {message}
        </Typography>
      )}
      <CircularProgress />
    </Stack>
  </CenteredBox>
);

export default Loading;
