import { forwardRef, useCallback } from "react";

export default function withClickHandler(WrappedComponent, uiStateRef) {
  return forwardRef((props, forwardedRef) => {
    const useMouseCallback = (handler) =>
      useCallback(
        (e) => {
          const { x, y } = getMousePosition(e);
          uiStateRef.current.mousePosition = { x, y };
          if (handler) {
            handler({ x, y });
          }
        },
        [uiStateRef]
      );

    const getMousePosition = (e) => {
      const cRect = e.target.getBoundingClientRect();
      const x = Math.round(e.clientX - cRect.left);
      const y = Math.round(e.clientY - cRect.top);
      return { x, y };
    };

    const onMouseMove = useMouseCallback();
    const onClick = useMouseCallback(() => {
      uiStateRef.current.isClicked = true;
    });
    const onMouseDown = useMouseCallback(() => {
      uiStateRef.current.isMouseDown = true;
    });
    const onMouseUp = useMouseCallback(() => {
      uiStateRef.current.isMouseDown = false;
    });

    const addClickHandlers = (domNode) => {
      domNode.addEventListener("mousemove", onMouseMove);
      domNode.addEventListener("mousedown", onMouseDown);
      domNode.addEventListener("mouseup", onMouseUp);
      domNode.addEventListener("click", onClick);
    };

    const callbackRef = (domNode) => {
      forwardedRef.current = domNode;
      if (domNode !== null) {
        addClickHandlers(domNode);
      }
    };

    return <WrappedComponent ref={callbackRef} {...props} />;
  });
}
