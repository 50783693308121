import { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import axios from "axios";
import Loading from "../Loading";
import { Config } from "../../App";
import { useAuth } from "../auth/GuruAuth";

export default function AnnotationBatches() {
  const [batches, setBatches] = useState([]);
  const [nextBatchId, setNextBatchId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [batchToFinalize, setBatchToFinalize] = useState();
  const { getToken } = useAuth();

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async () => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      params: { limit: 128 },
      headers: { Authorization: `Bearer ${token}` },
    };
    if (nextBatchId) {
      requestArgs.params.next = nextBatchId;
    }
    setIsLoading(true);
    var response;
    try {
      response = await apiClient.get("batch", requestArgs);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
    setBatches([...response.data.batches]);
    setNextBatchId(response.data.next || null);
  };

  const finalizeBatch = async (batchId) => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const requestArgs = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      await apiClient.post(`batch/${batchId}/finalize`, null, requestArgs);
    } catch (e) {
      setError(e.message);
      return;
    } finally {
      setBatchToFinalize(null);
    }
    setNextBatchId(null);
    fetchBatches();
  };

  const getEstimatedCostString = (batch) => {
    const usdPerTask = 0.3;
    const totalUsd = batch.tasks.length * usdPerTask;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(totalUsd);
  };

  if (isLoading) {
    return <Loading message="Loading batches..." />;
  } else if (error) {
    return (
      <div>
        <Typography color="error">
          Failed to load batches. Error: {error}
        </Typography>
      </div>
    );
  } else {
    return (
      <>
        <Dialog open={Boolean(batchToFinalize)}>
          <DialogTitle>Finalize Batch?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to finalize batch{" "}
              {batchToFinalize && batchToFinalize.id}? (This can't be undone)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => setBatchToFinalize(null)}>
              Cancel
            </Button>
            <Button onClick={() => finalizeBatch(batchToFinalize.id)}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <TableContainer component={Paper}>
          <Table aria-label="batches table">
            <TableHead>
              <TableRow>
                <TableCell>Batch ID</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>External ID</TableCell>
                <TableCell># Tasks</TableCell>
                <TableCell>Estimated Cost ($)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batches.map((batch) => (
                <TableRow key={batch.id}>
                  <TableCell>{batch.id}</TableCell>
                  <TableCell>{batch.provider}</TableCell>
                  <TableCell>{batch.external_id}</TableCell>
                  <TableCell>{batch.tasks.length}</TableCell>
                  <TableCell>{getEstimatedCostString(batch)}</TableCell>
                  <TableCell>{batch.status}</TableCell>
                  <TableCell>
                    {batch.status === "pending" ? (
                      <Button
                        onClick={() => setBatchToFinalize(batch)}
                        variant="outlined"
                      >
                        Finalize
                      </Button>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
