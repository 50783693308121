import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import {
  AmplifyAuthenticator,
  AmplifyConfirmSignUp,
  AmplifySignUp,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import React, { useState } from "react";
import { Link, Typography } from "@mui/material";
import axios from "axios";
import { Config } from "../../App";
import { useEventLogger, usePageViewTracker } from "../Analytics";

export default function FormguruAuth(props) {
  usePageViewTracker("Login");

  const logEvent = useEventLogger();
  const isSignUp = Boolean(
    props.location.state && props.location.state["isSignUp"]
  );
  const history = useHistory();
  const client = axios.create({
    baseURL: Config.apiEndpoint,
  });
  const [authData, setAuthData] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(null);

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      logEvent("user_auth_state_changed", {
        auth_state: nextAuthState,
      });
      if (nextAuthState === AuthState.SignedIn) {
        if (isSigningUp) {
          Auth.currentAuthenticatedUser().then((user) => {
            client.post(
              "/user",
              {
                username: user.username,
                email: user.attributes.email,
              },
              {
                headers: {
                  Authorization: user.signInUserSession.accessToken.jwtToken,
                  "Content-Type": "application/json",
                },
              }
            );
            logEvent("user_signed_up", {
              username: user.username,
            });
          });
        }
        history.push("/dashboard");
      } else if (nextAuthState === AuthState.ConfirmSignUp) {
        setIsSigningUp(true);
      }
      setAuthData(authData);
    });
  }, [isSigningUp, authData]);

  // Annoyingly, passwords aren't autofilled or saved in the browser's password manager
  // Tracking in https://github.com/aws-amplify/amplify-js/issues/5782#issuecomment-828763711
  return (
    <AmplifyAuthenticator
      initialAuthState={isSignUp ? AuthState.SignUp : AuthState.SignIn}
    >
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          {
            type: "username",
            label: "Username",
            required: true,
          },
          {
            type: "email",
            label: "Email Address",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            required: true,
          },
        ]}
      >
        <Typography slot="header-subtitle">
          Please read our{" "}
          <Link target="_blank" rel="noopener noreferrer" href="/terms">
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link target="_blank" rel="noopener noreferrer" href="/privacy">
            Privacy Policy
          </Link>{" "}
          before creating a Formguru account.
        </Typography>
      </AmplifySignUp>
      <AmplifyConfirmSignUp
        headerText="Check your email for a confirmation code"
        user={authData}
        slot="confirm-sign-up"
      />
    </AmplifyAuthenticator>
  );
}

export const useAuthSession = (isAuthEnforced) => {
  const history = useHistory();
  const getCurrentUser = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch {}
    if (!user && isAuthEnforced) {
      history.push("/login");
    }
    return user;
  };

  const getSession = async () => {
    const user = await getCurrentUser();
    return user ? user.signInUserSession : null;
  };

  return [getCurrentUser, getSession];
};

export const signOut = async () => {
  return await Auth.signOut();
};
