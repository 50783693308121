import Link from "@mui/material/Link";
import React from "react";
import { useHistory } from "react-router-dom";

const LatestFeaturesLink = () => {
  const history = useHistory();

  return (
    <Link
      variant="body2"
      component="button"
      onClick={() => history.push("/latestfeatures")}
    >
      NEW: Save your uploads and track progress.
    </Link>
  );
};

export default LatestFeaturesLink;
