import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { useAuth } from "./auth/GuruAuth";
import { StyledMenuItem } from "./StyledMenuItem";
import { ReactComponent as GuruLogo } from "./vitruvian-man.svg";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.primary.light,
    position: "relative",
  },
  contrast: {
    color: theme.palette.primary.contrastText,
  },
  logo: {
    fill: theme.palette.primary.contrastText,
    width: 48,
    height: 48,
  },
  grow: {
    flexGrow: 1,
  },
}));

export default function GuruAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const { isAuthenticated, logout, login } = useAuth();

  const handleAccountMenuClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleAccountMenuClose = (event) => {
    setAccountAnchorEl(null);
  };

  const onClickSignOut = () => {
    logout().then(() => {
      history.push("/");
      setAccountAnchorEl(null);
    });
  };

  const accountMenuId = "account-menu";
  const accountMenu = (
    <Menu
      anchorEl={accountAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={accountMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isAccountMenuOpen}
      onClose={handleAccountMenuClose}
    >
      <StyledMenuItem onClick={onClickSignOut}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </StyledMenuItem>
    </Menu>
  );

  const getAccountMenuSection = () => {
    if (isAuthenticated) {
      return (
        <>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={accountMenuId}
            aria-haspopup="true"
            onClick={handleAccountMenuClick}
            className={classes.contrast}
            size="large"
          >
            <AccountCircle />
          </IconButton>
        </>
      );
    } else {
      return (
        <Button
          variant="contained"
          className={classes.contrast}
          onClick={login}
        >
          Log In
        </Button>
      );
    }
  };

  return (
    <>
      <AppBar className={classes.appbar}>
        <Toolbar>
          <Link href="/">
            <GuruLogo className={classes.logo} />
          </Link>
          <div className={classes.grow} />
          <div>{getAccountMenuSection()}</div>
        </Toolbar>
      </AppBar>
      {accountMenu}
    </>
  );
}
