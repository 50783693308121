import { Box } from "@mui/material";

export default function CenteredBox({ children, ...props }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      {...props}
    >
      <div>
        {" "}
        {/* Wrapper to shield contents from the "flex" property */}
        {children}
      </div>
    </Box>
  );
}
