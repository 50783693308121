import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { Config } from "../../../App";
import { useAuth } from "../../auth/GuruAuth";

const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;
const notEmpty = (obj) => !isEmpty(obj);

export const useEditableKeypoints = ({ savedKeypoints, savedBboxes }) => {
  const [keypoints, setKeypoints] = useState({});
  const [bboxes, setBboxes] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsEditable(isEmpty(savedKeypoints));
    setKeypoints(savedKeypoints);
    setBboxes(savedBboxes);
    setIsDirty(false);
  }, [savedKeypoints, savedBboxes]);

  const updateKeypoints = (updateFn) => {
    setKeypoints((oldKeypoints) => {
      return updateFn(oldKeypoints);
    });
    setIsDirty(true);
  };

  const updateBboxes = (updateFn) => {
    setBboxes((oldBboxes) => {
      return updateFn(oldBboxes);
    });
  };

  return {
    isEditable,
    isDirty,
    bboxes,
    updateBboxes,
    keypoints,
    updateKeypoints,
  };
};

export const useTaskResults = (taskId) => {
  const { getToken } = useAuth();

  const [savedKeypoints, setSavedKeypoints] = useState({});
  const [savedBboxes, setSavedBboxes] = useState({});

  const [taskResults, setTaskResults] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [externalUri, setExternalUri] = useState(null);
  const [naturalImgDim, setNaturalImgDim] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [didSave, setDidSave] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsSaving(false);
    setDidSave(false);
    setError(null);
    setExternalUri(null);
    setImgSrc(null);
    setSavedBboxes({});
    setSavedKeypoints({});
    setIsLoading(true);
    fetchExistingKeypoints(taskId);
  }, [taskId]);

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.addEventListener("load", () => {
      const { naturalWidth, naturalHeight } = img;
      setNaturalImgDim({ width: naturalWidth, height: naturalHeight });
    });
    img.src = imgSrc;
  }, [imgSrc]);

  useEffect(() => {
    if (naturalImgDim && notEmpty(taskResults)) {
      const { width: naturalWidth, height: naturalHeight } = naturalImgDim;
      const { keypoints: _keypoints, bboxes: _bboxes } = taskResults;
      Object.entries(_keypoints).forEach(([label, { x, y }]) => {
        _keypoints[label] = {
          x: x / naturalWidth,
          y: y / naturalHeight,
        };
      });
      setSavedKeypoints(_keypoints);

      Object.entries(_bboxes).forEach(
        ([id, { top, left, width, height, ...bbox }]) => {
          _bboxes[id] = {
            ...bbox,
            top: top / naturalHeight,
            left: left / naturalWidth,
            width: width / naturalWidth,
            height: height / naturalHeight,
          };
        }
      );
      setSavedBboxes(_bboxes);
    }
  }, [naturalImgDim, taskResults]);

  const save = useCallback(
    async (bboxes, keypoints) => {
      setIsSaving(true);
      const client = axios.create({
        baseURL: Config.annotationServerEndpoint,
      });
      const token = await getToken();
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const boxes = Object.entries(bboxes).map(([id, bbox]) => ({
        id,
        type: "box",
        ...bbox,
        top: bbox.top * naturalImgDim.height,
        left: bbox.left * naturalImgDim.width,
        width: bbox.width * naturalImgDim.width,
        height: bbox.height * naturalImgDim.height,
      }));
      const points = Object.entries(keypoints).map(([label, { x, y }]) => ({
        type: "point",
        label,
        x: x * naturalImgDim.width,
        y: y * naturalImgDim.height,
      }));

      try {
        await client.post(
          `/task/${taskId}/results`,
          { annotations: [...boxes, ...points] },
          options
        );
      } catch (e) {
        setError(e.message);
        throw e;
      } finally {
        setIsSaving(false);
      }
      setDidSave(true);
      setSavedBboxes(boxes);
      setSavedKeypoints(points);
    },
    [taskId, naturalImgDim]
  );

  const fetchExistingKeypoints = async (id) => {
    var response;
    const client = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      response = await client.get(`/task/${id}`, options);
    } catch (e) {
      setError(e.message);
      throw e;
    }

    setImgSrc(response.data["asset_uri"]);
    const taskResults = response.data["results"] || [];
    const scaleTask = taskResults.find((r) => r["provider"] === "scale.com");
    if (scaleTask) {
      setExternalUri(scaleTask["external_link"]);
    }
    if (taskResults.length > 0) {
      setTaskResults(_parseTaskResults(taskResults));
    }
    setIsLoading(false);
  };

  const _parseTaskResults = (taskResults) => {
    const _bboxes = {};
    const _keypoints = {};
    taskResults.forEach((result) => {
      const annotations = result["raw_results"]["annotations"];
      annotations
        .filter((x) => x["type"] === "point")
        .forEach(({ label, x, y }) => {
          _keypoints[label] = { x, y };
        });

      annotations
        .filter((x) => x["type"] === "box")
        .forEach(({ id, label, top, left, width, height }) => {
          _bboxes[id] = {
            top,
            left,
            width,
            height,
            label,
          };
        });
    });
    return { keypoints: _keypoints, bboxes: _bboxes };
  };

  return {
    imgSrc,
    externalUri,
    savedKeypoints,
    savedBboxes,
    isLoading,
    isSaving,
    didSave,
    error,
    save,
  };
};
