import { useState, useEffect, useCallback } from "react";
import {
  Add,
  ContentCopy,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import withSnackbarNotification from "./SnackbarNotification";
import {
  IconButton,
  Paper,
  Link,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const SprintSegmentationInput = ({
  videoId,
  reps: initialStrides,
  seekTo,
  seekByOffset,
  currentFrame,
}) => {
  const [currentEvent, setCurrentEvent] = useState(null);
  const [strides, setStrides] = useState([]);
  const [seekTarget, setSeekTarget] = useState(null);

  const { stride: currentStride, eventType: currentEventType } =
    currentEvent || {};

  useEffect(() => {
    if (seekTarget === null) {
      return;
    }
    const { timestampMs } = seekTarget;
    seekTo(timestampMs);
  }, [seekTarget]);

  useEffect(() => {
    if (initialStrides && initialStrides.length > 0) {
      setStrides(
        initialStrides.map(
          ({ startTimestampMs, endTimestampMs, analyses }) => ({
            toeOffMs: startTimestampMs,
            touchDownMs: endTimestampMs,
            isLeft: Boolean(
              analyses.find(
                ({ analysisScalar, analysisType }) =>
                  analysisType.toLowerCase() === "is_left_leg" && analysisScalar
              )
            ),
          })
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!seekTarget || !currentFrame) {
      return;
    }
    const tolerance = 5;
    const delta = Math.abs(seekTarget.timestampMs - currentFrame.timestampMs);
    if (delta <= tolerance) {
      console.debug(
        `Done seeking to ${seekTarget.timestampMs}, current is ${currentFrame.timestampMs}`
      );
      setSeekTarget(null);
    }
  }, [seekTarget, currentFrame]);

  useEffect(() => {
    if (seekTarget !== null) {
      return;
    }
    if (!currentFrame || !currentEvent) {
      return;
    }

    const { timestampMs } = currentFrame;
    const { stride, eventType } = currentEvent;
    setStrides((strides) => {
      const result = [...strides];
      result[stride][eventType] = timestampMs;
      const oldIndex = stride;
      const oldObj = result[oldIndex];
      result.sort((a, b) => a.toeOffMs - b.toeOffMs);
      const newIndex = result.indexOf(oldObj);
      console.assert(newIndex > -1);
      if (oldIndex !== newIndex) {
        setCurrentEvent({ ...currentEvent, stride: newIndex });
      }
      return result;
    });
  }, [seekTarget, currentEvent, currentFrame]);

  const fmtTime = (ms) => ms.toFixed(0);

  const adjustableTimestamp = (i, eventType, ms) => {
    if (
      currentEvent !== null &&
      currentEventType == eventType &&
      currentStride === i
    ) {
      return (
        <Stack direction="row">
          <IconButton fontSize="small" onClick={() => seekByOffset(-1)}>
            <KeyboardArrowLeft />
          </IconButton>
          {timestampLink(i, eventType, ms)}
          <IconButton fontSize="small" onClick={() => seekByOffset(+1)}>
            <KeyboardArrowRight />
          </IconButton>
        </Stack>
      );
    }
    return timestampLink(i, eventType, ms);
  };

  const tableRow = ({ toeOffMs, touchDownMs, isLeft }, i) => {
    return (
      <TableRow
        key={i}
        sx={{
          td: { padding: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          {i + 1}
        </TableCell>
        <TableCell align="center">
          <Typography color={isLeft ? "blue" : "red"}>
            {isLeft ? "L" : "R"}
          </Typography>
        </TableCell>
        <TableCell align="center">
          {adjustableTimestamp(i, "toeOffMs", toeOffMs)}
        </TableCell>
        <TableCell align="center">
          {adjustableTimestamp(i, "touchDownMs", touchDownMs)}
        </TableCell>
      </TableRow>
    );
  };

  const timestampLink = (i, eventType, ms) => {
    if (
      currentEvent !== null &&
      i === currentStride &&
      eventType === currentEventType
    ) {
      return <Typography>{fmtTime(ms)}</Typography>;
    }
    return (
      <Typography>
        <Link
          onClick={() => {
            setCurrentEvent({
              stride: i,
              eventType,
              timestampMs: ms,
              isSeekInProgress: true,
            });
            setSeekTarget({ timestampMs: ms });
          }}
        >
          {fmtTime(ms)}
        </Link>
      </Typography>
    );
  };

  const copyJson = useCallback(() => {
    const data = {
      [videoId]: {
        strides: strides.map(({ toeOffMs, touchDownMs, isLeft }) => [
          isLeft ? "left" : "right",
          toeOffMs,
          touchDownMs,
        ]),
      },
    };
    navigator.clipboard.writeText(JSON.stringify(data));
  }, [videoId, strides]);

  const addNewStride = useCallback(() => {
    setStrides((oldStrides) => {
      const strides = oldStrides || [];
      const { timestampMs } = currentFrame;
      let insertionIndex = strides.findIndex(
        ({ toeOffMs }) => toeOffMs > timestampMs
      );
      if (insertionIndex === -1) {
        insertionIndex = strides.length;
      }
      const prevStride =
        strides.length > 0 ? strides[insertionIndex - 1] : null;
      return [
        ...strides.slice(0, insertionIndex),
        {
          toeOffMs: timestampMs,
          touchDownMs: timestampMs + 300,
          isLeft: prevStride && !prevStride.isLeft,
        },
        ...strides.slice(insertionIndex),
      ];
    });
  }, [currentFrame]);

  const ButtonWithPopup = withSnackbarNotification(IconButton);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="strides">
          <TableHead>
            <TableRow>
              <TableCell>Stride #</TableCell>
              <TableCell>Leg</TableCell>
              <TableCell
                align="center"
                sx={{ paddingLeft: 5, paddingRight: 5 }}
              >
                Toe-off
              </TableCell>
              <TableCell
                align="center"
                sx={{ paddingLeft: 3, paddingRight: 3 }}
              >
                Touch-down
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {strides.map(tableRow)}
            <TableRow>
              <TableCell colSpan={4}>
                <IconButton onClick={addNewStride}>
                  <Add /> <Typography>Add stride</Typography>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonWithPopup onClick={copyJson} message={"Copied to clipboard"}>
        <ContentCopy size="small" />
        <Typography>Copy</Typography>
      </ButtonWithPopup>
    </>
  );
};

export default SprintSegmentationInput;
