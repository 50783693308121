import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Link } from "react-router-dom";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import makeStyles from "@mui/styles/makeStyles";
import { Config } from "../../App";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: red[500],
  },
  media: {
    maxHeight: "224px",
    width: "auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const WorkoutCard = ({
  videoId,
  subheader,
  linkTarget,
  numReps = null,
  setGrade = null,
  liftType = "Lift",
  badge = <FitnessCenterIcon />,
}) => {
  const classes = useStyles();

  const thumbnailUri = `${Config.apiEndpoint}/thumbnail/${videoId}.jpg`;

  const LabeledValue = ({ label, value }) => (
    <div>
      <Typography
        variant="h6"
        color="textSecondary"
        component="p"
        align="center"
      >
        <b>{label}</b>
      </Typography>
      <Typography variant="h6" color="textPrimary" component="p" align="center">
        {value}
      </Typography>
    </div>
  );

  const metadata = {
    Reps: numReps,
    Grade: setGrade,
  };
  const hasMetadata = Object.values(metadata).some((x) => Boolean(x));

  return (
    <Box m={3} width="fit-content">
      <CardActionArea component={Link} to={linkTarget}>
        <Card>
          <CardHeader
            avatar={badge}
            title={
              <>
                <Typography variant="h5" color="textPrimary" component="p">
                  {liftType.charAt(0).toUpperCase() +
                    liftType.slice(1).toLowerCase()}{" "}
                  Workout
                </Typography>
              </>
            }
            subheader={subheader}
          />
          <CardMedia
            className={classes.media}
            image={thumbnailUri}
            title="Thumbnail"
            component="img"
          />
          {hasMetadata && (
            <CardContent>
              <Stack direction="row" justifyContent="space-evenly">
                {Object.entries(metadata).map(([key, value]) => (
                  <LabeledValue
                    key={key}
                    label={key}
                    value={value ? value : "N/A"}
                  />
                ))}
              </Stack>
            </CardContent>
          )}
        </Card>
      </CardActionArea>
    </Box>
  );
};

const WorkoutVideosFeed = ({ uploads, fetchMoreUploads, uploadsCursor }) => {
  useEffect(() => {
    // Force the feed to refresh even if there aren't enough items to trigger y-overflow on the page
    // This is really only useful for testing pagination with just a few items. In practice the pagination
    // limit will be set high enough that a single page of items will overflow the vertical space on screen
    if (uploads && uploads.length < 4) {
      window.dispatchEvent(new CustomEvent("scroll"));
    }
  }, [uploads]);

  return (
    <React.Fragment>
      <Typography variant="h4" component="h1">
        Recent workouts
      </Typography>
      <InfiniteScroll
        dataLength={uploads ? uploads.length : 0}
        next={fetchMoreUploads}
        hasMore={uploads === null || Boolean(uploadsCursor)}
        loader={<LinearProgress />}
        endMessage={
          <Typography variant="helper">No more videos to load</Typography>
        }
      >
        {(uploads || []).map((upload) => (
          <WorkoutCard key={`card-${upload.videoId}`} {...upload} />
        ))}
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default WorkoutVideosFeed;
