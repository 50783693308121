import { Link, Typography } from "@mui/material";
import CenteredBox from "./CenteredBox";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    h1: {
      paddingTop: theme.spacing(2),
    },
    "& *": {
      paddingTop: theme.spacing(1),
    },
  },
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <CenteredBox className={classes.root}>
      <Typography variant="h1">404</Typography>
      <Typography component="h2" variant="h4">
        Sorry, we couldn't find that page.
      </Typography>
      <Typography variant="body2">
        Problems? <Link href="/contact">Let us know</Link>
      </Typography>
    </CenteredBox>
  );
}
