import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router";
import { Config } from "../../App";
import { useAuth } from "../auth/GuruAuth";
import Loading from "../Loading";
import ActivityPicker from "./ActivityPicker";
import CadooLogo from "./logos/cadoo.png";
import FormguruLogo from "./logos/formguru.png";
import VimeoLogo from "./logos/vimeo.png";
import VideoAnnotator from "./VideoAnnotator";

import ReviewCarousel from "./ReviewCarousel";

export const VideoBrowser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [isReviewing, setIsReviewing] = useState(false);
  const [nextPageToken, setNextPageToken] = useState();
  const [selectedActivity, setSelectedActivity] = useState();
  const [videos, setVideos] = useState([]);

  const { getToken } = useAuth();
  const history = useHistory();

  const fetchMoreVideos = useCallback(
    async (startFromBeginning = false) => {
      const token = await getToken();
      setAccessToken(token);
      const queryParams = {
        limit: 64,
      };
      if (!startFromBeginning && nextPageToken) {
        queryParams["next"] = nextPageToken;
      }
      if (selectedActivity) {
        queryParams["activity"] = selectedActivity;
      }

      const apiClient = axios.create({
        baseURL: Config.annotationServerEndpoint,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams,
      });
      const response = await apiClient.get("/videos");
      setNextPageToken(response.data["next"]);
      setVideos((existingVideos) => {
        return [...existingVideos, ...response.data["videos"]];
      });
      setIsLoading(false);
    },
    [nextPageToken, selectedActivity]
  );

  useEffect(() => {
    setVideos([]);
    setNextPageToken(null);
    fetchMoreVideos(true);
  }, [selectedActivity]);

  if (isLoading) {
    return <Loading message="Loading videos..." />;
  }

  const sourceToIconUri = {
    cadoo: CadooLogo,
    "formguru.fitness": FormguruLogo,
    "vimeo.com": VimeoLogo,
  };

  const Thumbnail = ({ id, thumbnailUri, hasBeenAnnotated, source }) => {
    return (
      <Paper
        onClick={(event) => {
          if (event.ctrlKey || event.metaKey) {
            window.open(`/video/${id}`, "_blank", "noopener,noreferrer");
          } else {
            history.push(`/video/${id}`);
          }
        }}
        sx={{ position: "relative" }}
      >
        <img src={`${thumbnailUri}?Authorization=${accessToken}`} alt={id} />
        <div style={{ position: "absolute", top: 8, right: 8 }}>
          {hasBeenAnnotated ? (
            <CheckCircleIcon style={{ color: "#32CD32" }} />
          ) : (
            <PendingActionsIcon style={{ color: "#FFFF99" }} />
          )}
        </div>
        {sourceToIconUri[source] ? (
          <div
            style={{
              borderRadius: 48,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              height: 48,
              width: 48,
              position: "absolute",
              bottom: 10,
              left: 4,
            }}
          >
            <img
              src={sourceToIconUri[source]}
              alt={source}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                verticalAlign: "middle",
              }}
            />
          </div>
        ) : (
          <Typography
            sx={{ fontSize: 12, position: "absolute", bottom: 8, left: 2 }}
          >
            {source}
          </Typography>
        )}
      </Paper>
    );
  };

  const beginReview = () => {
    setIsReviewing(true);
  };

  return (
    <>
      <Stack direction="horizontal">
        <ActivityPicker onChange={setSelectedActivity} sx={{ width: 200 }} />
        <Button onClick={beginReview}>Begin Review</Button>
      </Stack>
      {isReviewing && (
        <ReviewCarousel
          isActive={isReviewing}
          onClose={() => setIsReviewing(false)}
          items={videos.filter(({ num_reps }) => !Boolean(num_reps))}
          render={VideoAnnotator}
        />
      )}
      <InfiniteScroll
        dataLength={videos.length}
        next={fetchMoreVideos}
        hasMore={Boolean(nextPageToken)}
        loader={
          <div>
            <Typography variant="h6">Loading...</Typography>
            <LinearProgress />
          </div>
        }
        endMessage={
          <Typography variant="helper">No more tasks to load</Typography>
        }
      >
        <Grid container spacing={1}>
          {videos.map(({ id, source, num_reps: numReps }) => {
            return (
              <Grid item key={`task-${id}`}>
                <Thumbnail
                  id={id}
                  thumbnailUri={`https://api.formguru.fitness/thumbnail/${id}.jpg`}
                  source={source}
                  hasBeenAnnotated={Boolean(numReps)}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </>
  );
};
