import React, { useCallback, useRef, useState } from "react";
import VideoCanvas from "./VideoCanvas";
import Grid from "@mui/material/Grid";
import { LinearProgress, Paper, Typography } from "@mui/material";
import OnErrorEmailCollector from "./OnErrorEmailCollector";
import makeStyles from "@mui/styles/makeStyles";
import { usePageViewTracker } from "./Analytics";
import { Config } from "../App";
import { drawCornerWatermark } from "./Draw.js";
import { drawDeadliftOverlays } from "./overlays/DeadliftOverlays";
import { drawSquatOverlays } from "./overlays/SquatOverlays";
import { drawBarPath, drawCurrentRepOverlay } from "./overlays/BaseOverlays";
import { SUPPORTED_LIFTS } from "./LiftSelector";
import { isFacingRight } from "./FormAnalyzer";
import CanvasPlaybackControls from "./CanvasPlaybackControls";
import {
  useVideoAndInferenceResults,
  useVideoIdFromUrl,
  FetchStatus,
} from "./AnalysisLoader";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function EmbeddedVideoContainer(props) {
  usePageViewTracker("Embedded Video Viewer");

  const REDDIT_IFRAME_MAX_HEIGHT = 700;

  const classes = useStyles();

  const videoCanvasRef = useRef();
  const videoId = useVideoIdFromUrl();
  const [isPlaying, setIsPlaying] = useState(true);
  const [seekTargetMs, setSeekTargetMs] = useState(null);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [videoDurationMs, setVideoDurationMs] = useState(null);

  const isNewUpload = false;
  const { jTP, liftType, reps, videoSrcUri, videoStatus } =
    useVideoAndInferenceResults(videoId, isNewUpload);

  const onLoadedMetadata = (e) => {
    setVideoDurationMs(1000 * e.target.duration);
  };

  const createTickFn = () => {
    let isLifterFacingRight = false;
    if (jTP != null && Object.keys(jTP).length > 0) {
      isLifterFacingRight = isFacingRight(jTP);
    }

    return (uiState, ctx) => {
      const tMs = uiState.t;
      if (jTP != null && Object.keys(jTP).length > 0) {
        if (liftType === SUPPORTED_LIFTS.SQUAT) {
          const hidePopups = false;
          drawSquatOverlays(
            ctx,
            jTP,
            reps,
            isLifterFacingRight,
            tMs,
            hidePopups
          );
        } else if (liftType === SUPPORTED_LIFTS.DEADLIFT) {
          drawDeadliftOverlays(ctx, jTP, isLifterFacingRight, tMs);
        }
        if (reps) {
          drawCurrentRepOverlay(ctx, reps, tMs);
        }
        drawBarPath(ctx, jTP, reps, tMs);
      }

      const onPlaybackStateChanged = ({ playbackRate, isPlaying }) => {
        setIsPlaying(isPlaying);
        setPlaybackRate(playbackRate);
      };
      CanvasPlaybackControls(
        {
          isPlaying,
          durationSec: videoDurationMs !== null ? videoDurationMs / 1000 : null,
          playbackRate,
        },
        videoCanvasRef.current.getDrawingContext(),
        reps,
        {
          onUserSkippedSetup: () => {},
          onPlaybackStateChanged,
          onSeeked: setSeekTargetMs,
        },
        uiState
      );
      if (uiState.isClicked) {
        setIsPlaying((isPlaying) => !isPlaying);
        uiState.isClicked = false;
      }

      const watermarkText = "Created by Formguru";
      const watermarkFontSize = 14;
      drawCornerWatermark(ctx, watermarkText, {
        fontSize: watermarkFontSize,
        background: "#f50057",
        alpha: 0.5,
      });
    };
  };

  const tickFn = useCallback(createTickFn(), [
    jTP,
    reps,
    liftType,
    isPlaying,
    playbackRate,
    videoDurationMs,
  ]);

  const showError = (title, subtitle, bodyElement) => {
    return (
      <React.Fragment>
        <Grid item>
          <Typography variant="h1">{title}</Typography>
          <Typography variant="h4">{subtitle}</Typography>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>{bodyElement}</Paper>
        </Grid>
      </React.Fragment>
    );
  };

  const renderVideo = () => {
    const loadingVideo = (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">Loading video...</Typography>
        </Grid>
        <Grid item>
          <LinearProgress color="secondary" />
        </Grid>
      </React.Fragment>
    );

    const failedToLoad = showError(
      "Uh-oh",
      "We can't load this video (yet!), but we're reviewing every failed upload.",
      OnErrorEmailCollector(videoId)
    );

    switch (videoStatus) {
      case FetchStatus.INITIALIZING:
        return loadingVideo;
      case FetchStatus.SUCCESS:
        return (
          <div className="App">
            <VideoCanvas
              ref={videoCanvasRef}
              isPlaying={isPlaying}
              seekTargetMs={seekTargetMs}
              playbackRate={playbackRate}
              tickFn={tickFn}
              selectedLiftType={liftType}
              currentVideo={videoSrcUri}
              videoId={videoId}
              isNewUpload={false}
              maxHeight={REDDIT_IFRAME_MAX_HEIGHT}
              onLoadedMetadata={onLoadedMetadata}
            />
          </div>
        );
      case FetchStatus.UNKNOWN_FAILURE:
        return failedToLoad;
      default:
        console.error("Got unknown Status");
    }
  };

  const renderLinkToVideo = () => {
    return (
      <React.Fragment>
        View{" "}
        <a href={`https://formguru.fitness/video/${videoId}`}>full analysis</a>{" "}
        on Formguru
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderVideo()}
      {renderLinkToVideo()}
    </React.Fragment>
  );
}
