import { useCallback, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function withSnackbarNotification(WrappedComponent) {
  return ({ onClick, message, children }) => {
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };

    const handleClick = useCallback(
      (e) => {
        if (onClick) {
          onClick(e);
        }
        setOpen(true);
      },
      [onClick]
    );

    const action = (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );

    return (
      <>
        <WrappedComponent onClick={handleClick}>{children}</WrappedComponent>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          message={message}
          action={action}
        />
      </>
    );
  };
}
