import { ContentCopy } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Config } from "../../App";
import {
  FetchStatus,
  isTerminalState,
  useVideoAndInferenceResults,
  useVideoIdFromUrl,
} from "../AnalysisLoader";
import { useAuth } from "../auth/GuruAuth";
import SprintSegmentationInput from "./SprintSegmentationInput";
import CanvasPlaybackControls from "../CanvasPlaybackControls";
import { JointToPoints } from "../JointToPoints";
import { BARBELL_LIFTS, SUPPORTED_LIFTS } from "../LiftSelector";
import Loading from "../Loading";
import { drawLine, drawCircle } from "../Draw";
import { drawBarPath, drawCurrentRepOverlay } from "../overlays/BaseOverlays";
import { VideoPlayerCanvas } from "../video_player/VideoPlayerCanvas";
import { useClickAndDragHandler } from "./keypoint_task/ClickHandler";
import ActivityPicker from "./ActivityPicker";
import TagsPicker from "./TagsPicker";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  slider: {
    padding: theme.spacing(2),
    alignContent: "center",
  },
}));

const useDrawLoop = ({ isStarted, canvasDomEl, uiStateRef, tickFn }) => {
  const animationRequestRef = useRef(null);
  const tickFnRef = useRef();

  useEffect(() => {
    tickFnRef.current = tickFn;
  }, [tickFn]);

  useEffect(() => {
    if (!isStarted) {
      return;
    }
    var prevTime = performance.now();

    const loop = async () => {
      if (canvasDomEl) {
        const ctx = canvasDomEl.getContext("2d");
        const tick = tickFnRef.current;
        const now = performance.now();
        const elapsedMs = now - prevTime;
        const fps = Math.round(1000 / elapsedMs);
        if (fps < 30) {
          console.warn(`Rendering is slow, FPS=${fps}`);
        }
        prevTime = now;
        if (tick) {
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          tick(uiStateRef.current, ctx);
        }
      }
      animationRequestRef.current = window.requestAnimationFrame(loop);
    };
    animationRequestRef.current = window.requestAnimationFrame(loop);
    return () => {
      cancelAnimationFrame(animationRequestRef.current);
    };
  }, [tickFnRef, canvasDomEl, isStarted]);
};

export default function VideoAnnotator({ id }) {
  const videoIdFromUrl = useVideoIdFromUrl();
  const videoId = id || videoIdFromUrl;
  const {
    videoStatus,
    jTPStatus,
    videoSrcUri,
    jTP,
    reps,
    liftType,
    uploadedAt,
    overlays,
  } = useVideoAndInferenceResults(videoId);

  const [isPlaying, setIsPlaying] = useState(false);
  const [seekTarget, setSeekTarget] = useState(null);
  const [currentFrame, setCurrentFrame] = useState({
    frameIndex: 0,
    timestampMs: 0,
  });

  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [videoDurationMs, setVideoDurationMs] = useState(null);
  const [videoFps, setVideoFps] = useState(null);

  const uiStateRef = useRef({
    mousePosition: { x: -1, y: -1 },
    isClicked: false,
  });
  const [timeRangeSec, setTimeRangeSec] = useState([0, videoDurationMs / 1000]);
  const [jointsToShow, setJointsToShow] = useState({
    leftSide: true,
    rightSide: true,
  });
  const [customJTP, setCustomJTP] = useState(null);
  const [hasVideoLoaded, setHasVideoLoaded] = useState(false);

  const inferenceFps = useMemo(() => {
    if (jTP) {
      return jTP.estimateFps();
    }
    return null;
  }, [jTP]);

  const jointPairs = useMemo(() => {
    const sides = [
      ...(jointsToShow.leftSide ? ["left"] : []),
      ...(jointsToShow.rightSide ? ["right"] : []),
    ];
    return sides.reduce((acc, direction) => {
      const pairsThisSide = Object.fromEntries(
        [
          ["Toe", "Ankle"],
          ["Heel", "Ankle"],
          ["Toe", "Heel"],
          ["Ankle", "Knee"],
          ["Knee", "Hip"],
          ["Hip", "Shoulder"],
          ["Shoulder", "Elbow"],
          ["Elbow", "Wrist"],
        ].map(([a, b]) => [direction + a, direction + b])
      );
      return { ...acc, ...pairsThisSide };
    }, {});
  }, [jointsToShow]);

  const useMouseCallback = (handler) =>
    useCallback(
      (domEl, { x, y }) => {
        uiStateRef.current.mousePosition = { x, y };
        if (handler) {
          handler({ x, y, uiState: uiStateRef.current });
        }
      },
      [uiStateRef]
    );
  const onMouseMove = useMouseCallback();
  const onClick = useMouseCallback(({ uiState }) => {
    uiState.isClicked = true;
  });
  const onMouseDown = useMouseCallback(({ uiState }) => {
    uiState.isMouseDown = true;
  });
  const onMouseUp = useMouseCallback(({ uiState }) => {
    uiState.isMouseDown = false;
  });

  const [canvasDomEl, setCanvasDomEl] = useClickAndDragHandler({
    onClick,
    onMouseUp,
    onMouseMove,
    onMouseDown,
  });

  useEffect(() => {
    if (videoDurationMs) {
      setTimeRangeSec([0, videoDurationMs / 1000]);
    }
  }, [videoDurationMs]);

  const onLoadedMetadata = (e) => {
    setVideoDurationMs(1000 * e.target.duration);
  };

  const seekToTime = (tMs) => {
    setSeekTarget({ timestampMs: tMs });
  };

  const seekToFrame = (frameIndex) => {
    setSeekTarget({ frameIndex });
  };

  const drawSkeleton = (ctx, _jTP, timestampMs, frameIndex, jointPairs) => {
    if (!_jTP) {
      return;
    }

    const jointToCoord = {};
    // draw connectors
    Object.entries(jointPairs).forEach(([a, b]) => {
      const joint1 = _jTP.positionAt(a, timestampMs, frameIndex);
      const joint2 = _jTP.positionAt(b, timestampMs, frameIndex);
      if (joint1) {
        jointToCoord[a] = joint1;
      }
      if (joint2) {
        jointToCoord[b] = joint2;
      }
      if (joint1 && joint2) {
        drawLine(
          ctx,
          {
            position: {
              x: joint1.x * ctx.canvas.width,
              y: joint1.y * ctx.canvas.height,
            },
          },
          {
            position: {
              x: joint2.x * ctx.canvas.width,
              y: joint2.y * ctx.canvas.height,
            },
          },
          {
            color: a.startsWith("left") ? "blue" : "red",
            alpha: 0.25,
            width: 2,
          }
        );
      }
    });

    // draw keypoints
    Object.entries(jointToCoord).forEach(([jointName, { x, y }]) => {
      drawCircle(ctx, ctx.canvas.width * x, ctx.canvas.height * y, 3, {
        color: jointName.startsWith("left") ? "blue" : "red",
      });
    });
  };

  const createTickFn = () => {
    const _jTP = customJTP || jTP;
    return (uiState, ctx) => {
      if (!canvasDomEl) {
        return;
      }

      const wasClickPendingAtStart = uiState.isClicked;
      const { t: timestampMs, frameIndex } = uiState;
      if (_jTP != null && Object.keys(_jTP).length > 0) {
        drawSkeleton(ctx, _jTP, timestampMs, frameIndex, jointPairs);
        if (reps) {
          drawCurrentRepOverlay(ctx, reps, timestampMs);
        }
        if (BARBELL_LIFTS.has(liftType) || liftType === SUPPORTED_LIFTS.OTHER) {
          drawBarPath(ctx, _jTP, reps, timestampMs);
        }
      }
      const onPlaybackStateChanged = ({ playbackRate, isPlaying }) => {
        setIsPlaying(isPlaying);
        setPlaybackRate(playbackRate);
      };

      CanvasPlaybackControls(
        {
          isPlaying,
          durationSec: videoDurationMs !== null ? videoDurationMs / 1000 : null,
          playbackRate,
        },
        ctx,
        reps,
        {
          onUserSkippedSetup: () => {},
          onPlaybackStateChanged,
          onSeeked: seekToTime,
        },
        uiState
      );

      // the click could have arrived in the middle of this loop, so we wait to
      // discard it as unintercepted until it's been unhandled for a full cycle
      if (wasClickPendingAtStart) {
        uiState.isClicked = false;
      }
    };
  };

  const hidePopups = true;
  const tickFn = useCallback(createTickFn(), [
    uiStateRef,
    canvasDomEl,
    jTP,
    customJTP,
    reps,
    liftType,
    hidePopups,
    jointPairs,
    isPlaying,
    playbackRate,
    videoDurationMs,
  ]);
  useDrawLoop({ isStarted: hasVideoLoaded, tickFn, canvasDomEl, uiStateRef });

  const getCurrentFrameIdx = useCallback(
    () => uiStateRef.current.frameIndex,
    [uiStateRef]
  );

  const onFrameRendered = useCallback(
    ({ frameIndex, timestampMs }) => {
      uiStateRef.current.t = timestampMs;
      uiStateRef.current.frameIndex = frameIndex;
      // don't do this while the video is playing to cut down on re-renders
      if (!isPlaying) {
        setCurrentFrame({ frameIndex, timestampMs });
      }
    },
    [isPlaying, uiStateRef]
  );

  const onReady = useCallback(({ durationMs, numFrames }) => {
    setVideoDurationMs(durationMs);
    setHasVideoLoaded(true);
    setVideoFps(numFrames / (durationMs / 1000));
  }, []);

  const seekByOffset = useCallback(
    (offset) => {
      const currentFrame = uiStateRef.current.frameIndex;
      seekToFrame(currentFrame + offset);
    },
    [uiStateRef]
  );

  const getContent = () => {
    if (!isTerminalState(jTPStatus) || !isTerminalState(videoStatus)) {
      return <Loading message="Loading videos..." />;
    } else if (jTP) {
      return (
        <>
          <FormGroup>
            <Stack direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    label="Left side"
                    checked={jointsToShow.leftSide}
                    onChange={() =>
                      setJointsToShow({
                        ...jointsToShow,
                        leftSide: !jointsToShow.leftSide,
                      })
                    }
                  />
                }
                label="Left side"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    label="Right side"
                    checked={jointsToShow.rightSide}
                    onChange={() =>
                      setJointsToShow({
                        ...jointsToShow,
                        rightSide: !jointsToShow.rightSide,
                      })
                    }
                  />
                }
                label="Right side"
              />
              <CreateTaskFromCurrentFrameButton
                videoId={videoId}
                getCurrentFrameIdx={getCurrentFrameIdx}
              />
              <J2PSideLoader onUploadedJ2p={(j2p) => setCustomJTP(j2p)} />
            </Stack>
          </FormGroup>
          <VideoPlayerCanvas
            ref={setCanvasDomEl}
            isPlaying={isPlaying}
            seekTarget={seekTarget}
            playbackRate={playbackRate}
            src={videoSrcUri}
            onReady={onReady}
            onFrameRendered={onFrameRendered}
          />
          <Stack direction="row">
            <Button disabled={isPlaying} onClick={() => seekByOffset(-1)}>
              Previous Frame
            </Button>
            <Button disabled={isPlaying} onClick={() => seekByOffset(+1)}>
              Next Frame
            </Button>
          </Stack>
          <TimeRangeSelector
            hi={videoDurationMs / 1000}
            onChange={onSliderValueChange}
          />
          <Box mt={5}>
            <strong>Rep Info</strong>
            <pre>{JSON.stringify(reps, null, 2)}</pre>
          </Box>
        </>
      );
    } else if (videoStatus === FetchStatus.SUCCESS) {
      return (
        <>
          <video
            src={videoSrcUri}
            style={{ maxHeight: "70vh", maxWidth: "80vw" }}
            onLoadedMetadata={onLoadedMetadata}
            controls
          />
          <Alert severity="error">Failed to fetch analysis: {jTPStatus}</Alert>
        </>
      );
    } else {
      return (
        <Alert severity="error">Failed to fetch video: {videoStatus}</Alert>
      );
    }
  };

  const onSliderValueChange = useCallback(
    ({ oldVal, newVal }) => {
      const [oldLo, oldHi] = oldVal;
      const [newLo, newHi] = newVal;

      if (newLo !== oldLo) {
        seekToTime(Math.round(newLo * 1000));
      } else if (newHi !== oldHi) {
        seekToTime(Math.round(newHi * 1000));
      }
      setTimeRangeSec([newLo, newHi]);
    },
    [seekToTime]
  );

  return (
    <>
      <Grid container spacing={4} padding={2} sx={{ height: "100%" }}>
        <Grid item xs={8} sx={{ height: "100%" }}>
          {getContent()}
        </Grid>
        <Grid item sx={{ height: "100%", overflow: "scroll" }}>
          <ActivityLabelForm
            videoId={videoId}
            videoFps={videoFps}
            inferenceFps={inferenceFps}
            uploadedAt={uploadedAt}
            reps={reps}
            overlays={overlays}
            startMs={timeRangeSec[0] * 1000}
            endMs={timeRangeSec[1] * 1000}
            seekTo={seekToTime}
            seekByOffset={seekByOffset}
            currentFrame={currentFrame}
          />
        </Grid>
      </Grid>
    </>
  );
}

const CreateTaskFromCurrentFrameButton = ({ videoId, getCurrentFrameIdx }) => {
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [taskId, setTaskId] = useState();
  const [error, setError] = useState();

  const createKeypointTask = useCallback(async () => {
    const frameIdx = getCurrentFrameIdx();
    const token = await getToken();
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const response = await apiClient.post(
      `/task`,
      {
        video_slice: `${videoId}[${frameIdx}]`,
        selection_strategy: "manual",
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return await response.data["id"];
  }, [videoId, getCurrentFrameIdx]);

  const handleClick = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const newTaskId = await createKeypointTask();
      setTaskId(newTaskId);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <>
      <Button onClick={handleClick}>Request Keypoints</Button>
      {error && <Alert severity="error">{error}</Alert>}
      {taskId && <Alert severity="success">Created task {taskId}</Alert>}
    </>
  );
};

const TimeRangeSelector = ({ lo = 0, hi, onChange }) => {
  const [value, setValue] = useState([0, 100]);

  useEffect(() => {
    setValue([0, 100]);
  }, [lo, hi]);

  const sliderValToOutputRange = useCallback(
    (sliderVal) => {
      const range = hi - lo;
      return lo + (sliderVal / 100.0) * range;
    },
    [lo, hi]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const [newLo, newHi] = newValue.map(sliderValToOutputRange);
    const [oldLo, oldHi] = value.map(sliderValToOutputRange);
    onChange({ oldVal: [oldLo, oldHi], newVal: [newLo, newHi] });
  };

  const formatLabel = (value) => {
    const tSec = sliderValToOutputRange(value);
    return tSec.toFixed(2);
  };

  return (
    <Slider
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      valueLabelFormat={formatLabel}
    />
  );
};

const NumRepsInput = ({ onChange, ...props }) => {
  const [isError, setIsError] = useState(false);

  const handleChange = ({ target: { value } }) => {
    if (value < 0 || value > 500) {
      setIsError(true);
    } else {
      onChange(value);
    }
  };

  return (
    <TextField
      error={isError}
      id="num-reps"
      label="# reps"
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={handleChange}
      {...props}
    />
  );
};

const KeypointTasksList = ({ tasks }) => {
  return (
    <>
      <Typography variant="h5">Keypoint Tasks</Typography>
      {tasks.flatMap(({ id, annotation_type: annotationType }) => {
        return (
          annotationType === "twod_pose_12_keypoints" && (
            <Link href={`/tasks/${id}`}>Task {id}</Link>
          )
        );
      })}
    </>
  );
};

const ActivityLabel = ({
  activity,
  startMs,
  endMs,
  videoId,
  numReps,
  onDelete,
}) => {
  return (
    <>
      <Card>
        <CardContent>
          <Typography sx={{ fontSize: "1.5rem" }} color="text.secondary">
            {activity}
          </Typography>
          <Typography fontWeight="bold" component="span">
            From:{" "}
          </Typography>
          <Typography component="span">
            {(startMs / 1000).toFixed(1)}s -{" "}
            {endMs ? `${(endMs / 1000).toFixed(1)}s` : ""}
          </Typography>
          <br />
          <Typography fontWeight="bold" component="span">
            # Reps:{" "}
          </Typography>
          <Typography component="span">{numReps || "N/A"}</Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={() => {
              onDelete(videoId, startMs, endMs);
            }}
          >
            Delete
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

const ActivityLabelForm = ({
  videoId,
  videoFps,
  inferenceFps,
  uploadedAt,
  reps,
  overlays,
  startMs,
  endMs,
  getCurrentFrameIdx,
  seekTo,
  seekByOffset,
  currentFrame,
  ...props
}) => {
  const classes = useStyles();

  const [activity, setActivity] = useState();
  const [numReps, setNumReps] = useState();
  const [isError, setIsError] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [videoSlices, setVideoSlices] = useState();

  const [existingTags, setExistingTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [tasks, setTasks] = useState([]);
  const { getToken } = useAuth();

  useEffect(() => {
    (async () => {
      const apiClient = axios.create({
        baseURL: Config.annotationServerEndpoint,
      });
      const token = await getToken();
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await apiClient.get(`/video/${videoId}`, options);
      setExistingTags(response.data["tags"]);
      setTags(response.data["tags"]);
      setTasks(response.data["tasks"]);
      const slices = response.data["activities"];
      setVideoSlices(
        slices.map((slice) => {
          const {
            activity,
            num_reps: numReps,
            start_time_ms: startMs,
            end_time_ms: endMs,
          } = slice;
          return { videoId, numReps, startMs, endMs, activity };
        })
      );
    })();
  }, [videoId]);

  useEffect(() => {
    setIsSaveSuccess(false);
  }, [videoId, startMs, endMs, activity, numReps]);

  const deleteSlice = async (videoId, startMs, endMs) => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const options = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_time_ms: startMs,
        end_time_ms: endMs,
      },
    };
    const response = await apiClient.delete(`/video/${videoId}`, options);
    if (response.status !== 200) {
      setIsError(true);
      console.error(response);
    } else {
      setIsError(false);
      setVideoSlices((oldSlices) => {
        return oldSlices.filter(
          ({ videoId: _videoId, startMs: _startMs, endMs: _endMs }) => {
            const isMatch =
              _videoId === videoId && _startMs === startMs && _endMs === endMs;
            return !isMatch;
          }
        );
      });
    }
  };

  const onSubmit = useCallback(async () => {
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
    });
    const token = await getToken();
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    var response;
    const areSlicesChanged = Boolean(activity) && Boolean(numReps);
    if (areSlicesChanged) {
      const payload = {
        start_time_ms: Math.round(startMs),
        end_time_ms: Math.round(endMs),
        activity,
        num_reps: numReps,
      };
      try {
        response = await apiClient.post(`/video/${videoId}`, payload, options);
      } catch (e) {
        setIsError(true);
        setIsSaveSuccess(false);
        console.error(response);
        throw e;
      }
      setVideoSlices((oldSlices) => {
        return [{ videoId, numReps, startMs, endMs, activity }, ...oldSlices];
      });
    }

    const areTagsChanged = new Set(tags) !== new Set(existingTags);
    if (areTagsChanged) {
      try {
        response = await apiClient.post(
          `/video/${videoId}/tags`,
          {
            tags: tags,
          },
          options
        );
      } catch (e) {
        setIsError(true);
        setIsSaveSuccess(false);
        console.error(response);
        throw e;
      }
      setExistingTags(tags);
    }
    setIsError(false);
    setIsSaveSuccess(true);
  }, [videoId, startMs, endMs, activity, numReps, tags, existingTags]);

  return (
    <>
      <Box className={classes.slider} {...props}>
        <Stack spacing={2}>
          <div>
            <div style={{ "margin-bottom": "20px" }}>
              <Typography
                style={{
                  cursor: "copy",
                  display: "flex",
                  "align-items": "center",
                }}
                variant="body2"
                onClick={() => navigator.clipboard.writeText(videoId)}
              >
                <strong>ID:</strong>&nbsp;
                <ContentCopy size="small" /> {videoId}
              </Typography>
              <Typography variant="body2">
                <strong>Video frame rate:</strong>&nbsp;
                {videoFps ? `${Math.round(videoFps)} fps` : "-"}
              </Typography>
              <Typography variant="body2">
                <strong>Inference frame rate:</strong>&nbsp;
                {videoFps ? `~${Math.round(inferenceFps)} fps` : "-"}
              </Typography>
              {uploadedAt && (
                <Typography variant="body2">
                  <strong>Uploaded:</strong>&nbsp;{uploadedAt.toISOString()}
                </Typography>
              )}
              <Typography component="span" fontWeight="bold" variant="body2">
                From:{" "}
              </Typography>
              <Typography component="span" variant="body2">
                {(startMs / 1000).toFixed(1)}s -{" "}
                {endMs ? `${(endMs / 1000).toFixed(1)}s` : ""}
              </Typography>
              {reps && (
                <Typography variant="body2">
                  <strong>Reps:</strong> {reps.length}
                </Typography>
              )}
              {overlays &&
                Object.keys(overlays).map(function (overlayType) {
                  return (
                    <Typography variant="body2">
                      <a href={overlays[overlayType]["uri"]}>
                        Overlay - {overlayType}
                      </a>
                    </Typography>
                  );
                })}
            </div>
            <TagsPicker existingTags={existingTags} onChange={setTags} />
          </div>
          <ActivityPicker onChange={setActivity} style={{ "max-width": 200 }} />
          {activity && activity.toLowerCase() === "sprint" ? (
            <SprintSegmentationInput
              videoId={videoId}
              reps={reps}
              seekTo={seekTo}
              seekByOffset={seekByOffset}
              currentFrame={currentFrame}
            />
          ) : (
            <NumRepsInput onChange={setNumReps} style={{ "max-width": 200 }} />
          )}
          <Button
            variant="outlined"
            onClick={onSubmit}
            style={{ width: "fit-content" }}
          >
            Save
          </Button>
          {isError && (
            <Alert severity="error">
              Save failed - check console for details.
            </Alert>
          )}
          {isSaveSuccess && <Alert severity="success">Saved!</Alert>}
        </Stack>
      </Box>

      <Box>
        <Stack spacing={2}>
          {(videoSlices || [])
            .sort((a, b) => a.startMs - b.startMs)
            .map(({ activity, startMs, endMs, videoId, numReps }) => {
              return (
                <>
                  {tasks && tasks.length > 0 && (
                    <KeypointTasksList tasks={tasks} />
                  )}
                  <ActivityLabel
                    activity={activity}
                    startMs={startMs}
                    endMs={endMs}
                    videoId={videoId}
                    numReps={numReps}
                    onDelete={deleteSlice}
                  />
                </>
              );
            })}
        </Stack>
      </Box>
    </>
  );
};

const J2PSideLoader = ({ onUploadedJ2p }) => {
  const inputRef = useRef(null);

  const changeHandler = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const j2pDump = JSON.parse(reader.result);
      const expectedKeys = [
        "resolutionHeight",
        "resolutionWidth",
        "jointToPoints",
      ];
      if (!expectedKeys.every((key) => key in j2pDump)) {
        alert("Invalid J2P file - expected keys: " + expectedKeys);
        return;
      }
      const result = JointToPoints(j2pDump["jointToPoints"]);
      onUploadedJ2p(result);
    });
    reader.readAsText(selectedFile);
  };

  const startUpload = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Button onClick={startUpload}>Side-load J2P</Button>
      <input
        ref={inputRef}
        style={{ visibility: "hidden" }}
        type="file"
        name="file"
        onChange={changeHandler}
      />
    </>
  );
};
