import React from "react";
import Grid from "@mui/material/Grid";
import { Button, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function OnErrorEmailCollector(videoId) {
  const classes = useStyles();
  const emailRef = React.useRef();
  //TODO: Email validation from ContactUs.js isn't working with auto-complete
  const isEmailInvalid = true;
  const [didSubmit, setDidSubmit] = React.useState(false);

  const apiClient = axios.create({
    baseURL: "https://api.getguru.fitness",
  });

  const handleClick = async () => {
    const email = emailRef.current.value;
    const formData = new FormData();
    formData.append("name", "Not provided.");
    formData.append("email", email);
    formData.append("message", `videoId: ${videoId}`);

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      await apiClient.post("/contact", formData, options);
    } catch (error) {
      alert(
        "Oof, failed to send your message. Please drop us an email at support@formguru.fit"
      );
      return;
    }
    setDidSubmit(true);
  };

  const collectEmail = (
    <div>
      <Grid item xs={12}>
        <TextField
          id="email-address"
          required
          inputRef={emailRef}
          helperText="No spam -- we'll only email you when we fix your video. It'll be soon."
          label="Email Address"
          error={isEmailInvalid}
          autoComplete="on"
          className={classes.nameField}
        />
      </Grid>
      <br />
      <Grid item>
        <Button
          variant="contained"
          aria-label="submit-message"
          color="primary"
          onClick={handleClick}
          endIcon={<SendIcon />}
        >
          Send
        </Button>
      </Grid>
    </div>
  );

  const emailSubmitted = (
    <div>
      <Grid item>
        <Typography variant="h3" component="h3">
          Sent ✅
        </Typography>
        <Typography component="p">We'll get back to you ASAP.</Typography>
      </Grid>
    </div>
  );

  return (
    <React.Fragment>
      <Grid item>
        <Typography variant="h5">
          Still broken? We can let you know when we've fixed your video:
        </Typography>
        {didSubmit ? emailSubmitted : collectEmail}
      </Grid>
    </React.Fragment>
  );
}
