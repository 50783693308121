import makeStyles from "@mui/styles/makeStyles";
import { Box, Modal } from "@mui/material";
import AnnotationTaskViewer from "./AnnotationTaskViewer";

const useStyles = makeStyles((theme) => ({
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    width: "80vw",
    height: "80vh",
  },
}));

const AnnotationTaskModal = ({ taskId, onClose }) => {
  const classes = useStyles();

  return (
    <Modal open={true} onClose={onClose}>
      <Box className={classes.modalBox}>
        <AnnotationTaskViewer taskId={taskId} />
      </Box>
    </Modal>
  );
};

export default AnnotationTaskModal;
