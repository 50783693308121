import CookieConsent from "react-cookie-consent";
import { Typography } from "@mui/material";

export default function CookieBanner() {
  const PURPLE = "#3f51b5"; // TODO: can we get this programatically?
  const WHITE = "#ffffff";
  return (
    <CookieConsent
      location="bottom"
      style={{ background: "rgb(53, 53, 53, .90)" }} // gray, translucent
      buttonStyle={{
        marginLeft: "10px",
        marginTop: "0px",
        marginBottom: "10px",
        backgroundColor: PURPLE,
        color: WHITE,
      }}
    >
      <Typography>
        We use cookies to monitor performance and improve this site.
      </Typography>
    </CookieConsent>
  );
}
