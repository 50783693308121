import { useState, useEffect } from "react";
import { Box, Grid, Button, Modal, Stack, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ReviewCarousel({
  isActive,
  onClose,
  items,
  render: ItemComponent,
}) {
  const [idx, setIdx] = useState(0);

  const handleKeydown = (e) => {
    if (e.code == "Space") {
      onNext();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  const onNext = () => {
    setIdx((idx) => idx + 1);
  };

  const onPrev = () => {
    setIdx((idx) => idx - 1);
  };

  return (
    <>
      <Modal open={isActive} onClose={onClose}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={style}
        >
          <Grid item xs={12} height="70vh">
            {idx < items.length ? (
              <ItemComponent {...items[idx]} />
            ) : (
              <Box>
                <Typography component="h2">All done!</Typography>
              </Box>
            )}
          </Grid>
          <Grid item>
            <Button disabled={idx <= 0} onClick={onPrev}>
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button disabled={idx >= items.length} onClick={onNext}>
              Next
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
