import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { Config } from "../../App";
import { useAuth } from "../auth/GuruAuth";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (tag, tags, theme) => {
  return {
    fontWeight:
      tags.indexOf(tag) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

export default function TagsPicker({ existingTags, onChange }) {
  const theme = useTheme();
  const [tags, setTags] = useState([]);
  const { getToken } = useAuth();
  const [validTags, setValidTags] = useState([]);

  const fetchTagOptions = async () => {
    const token = await getToken();
    const apiClient = axios.create({
      baseURL: Config.annotationServerEndpoint,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const response = await apiClient.get("/tags");
    setValidTags(response.data["tags"]);
  };

  useEffect(() => {
    fetchTagOptions();
  }, []);

  useEffect(() => {
    setTags(existingTags);
  }, [existingTags]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const newTags = typeof value === "string" ? value.split(",") : value;
    setTags(newTags);
    onChange(newTags);
  };

  return (
    <div>
      <FormControl sx={{ "padding-bottom": 8, width: 200 }}>
        <InputLabel id="tags-input-label">Tags</InputLabel>
        <Select
          labelId="tags-input-label"
          id="select-tags"
          multiple
          value={tags}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Tags" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem disabled value="">
            <em>Tags</em>
          </MenuItem>
          {validTags.map((tag) => (
            <MenuItem key={tag} value={tag} style={getStyles(tag, tags, theme)}>
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
