import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const SUPPORTED_LIFTS = {
  BENCH: "BENCH",
  BURPEE: "BURPEE",
  CHIN_UP: "CHIN_UP",
  DEADLIFT: "DEADLIFT",
  LUNGE: "LUNGE",
  OTHER: "OTHER",
  PUSH_UP: "PUSH-UP",
  SIT_UP: "SIT_UP",
  SNATCH: "SNATCH",
  SQUAT: "SQUAT",
  DOWNWARD_DOG: "DOWNWARD_DOG",
};

export const BARBELL_LIFTS = new Set([
  SUPPORTED_LIFTS.SQUAT,
  SUPPORTED_LIFTS.DEADLIFT,
  SUPPORTED_LIFTS.BENCH,
]);

export const getDisplayName = (lift) => {
  const name = SUPPORTED_LIFTS[lift].toLowerCase();
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export default function LiftSelector(props) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 180,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  const [selectedLift, setSelectedLift] = useState(null);

  const hasBeenOverridden = () => {
    return (
      props.detectedLiftType &&
      selectedLift &&
      selectedLift !== props.detectedLiftType
    );
  };

  const handleChange = (event) => {
    setSelectedLift(event.target.value);
    if ("onSelectedLiftChanged" in props) {
      props.onSelectedLiftChanged(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-lift-label">
          <Typography>Lift Type</Typography>
        </InputLabel>
        <Select
          label="Lift Type"
          labelId="select-lift-label"
          id="select-lift"
          value={selectedLift || props.detectedLiftType || "OTHER"}
          onChange={handleChange}
        >
          {Object.keys(SUPPORTED_LIFTS).map((k) => (
            <MenuItem key={k} value={k}>
              {getDisplayName(k)}
            </MenuItem>
          ))}
        </Select>
        {hasBeenOverridden() || (
          <FormHelperText>(auto-detected)</FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
}
